export const slices = [
    {
        dimension: 1 as any,
        results: {
            filter: {
                x: '90.5',
                y: '0',
                width: '165.02',
                height: '183.99',
            },
            clip: {
                d: 'M64.51,147.99,129.02,14.03A155.324,155.324,0,0,0,0,14.03Z',
                translate: '0.5',
                translate2: '108.5 16',
            },
            shape: {
                d: 'M155,310a156.143,156.143,0,0,1-31.238-3.149,154.151,154.151,0,0,1-55.424-23.322,155.452,155.452,0,0,1-56.157-68.2,154.218,154.218,0,0,1-9.032-29.1,156.509,156.509,0,0,1,0-62.476A154.151,154.151,0,0,1,26.472,68.338a155.453,155.453,0,0,1,68.2-56.157,154.22,154.22,0,0,1,29.1-9.032,156.509,156.509,0,0,1,62.476,0,154.151,154.151,0,0,1,55.424,23.323,155.452,155.452,0,0,1,56.157,68.2,154.218,154.218,0,0,1,9.032,29.1,156.512,156.512,0,0,1,0,62.476,154.15,154.15,0,0,1-23.322,55.424,155.451,155.451,0,0,1-68.2,56.157,154.224,154.224,0,0,1-29.1,9.032A156.146,156.146,0,0,1,155,310ZM155,8a148.086,148.086,0,0,0-29.626,2.986A146.193,146.193,0,0,0,72.811,33.105,147.431,147.431,0,0,0,19.552,97.781a146.263,146.263,0,0,0-8.565,27.593,148.434,148.434,0,0,0,0,59.251,146.2,146.2,0,0,0,22.119,52.564,147.43,147.43,0,0,0,64.676,53.259,146.262,146.262,0,0,0,27.593,8.566,148.434,148.434,0,0,0,59.251,0A146.2,146.2,0,0,0,237.189,276.9a147.43,147.43,0,0,0,53.259-64.676,146.262,146.262,0,0,0,8.566-27.594,148.434,148.434,0,0,0,0-59.251A146.195,146.195,0,0,0,276.9,72.811a147.43,147.43,0,0,0-64.676-53.259,146.263,146.263,0,0,0-27.594-8.565A148.088,148.088,0,0,0,155,8Z',
                translate: '-90',
                translate2: '90',
            },
            text: {
                translate: '94',
                translate2: '23 19',
                translate3: '35.5 12',
                translate4: '36.5 40',
                x: '-35.656',
                x2: '-13.634',
            },
        },
        standard: {
            shape: {
                path: 'M56.483,131.839,0,14.564a185.8,185.8,0,0,1,144.147-.031l-56.471,117.3a17.31,17.31,0,0,1-31.193,0Z',
                translate: '888.104 446.102',
            },
            text: {
                translate: '888 404',
            },
        },
    },
    {
        dimension: 2 as any,
        results: {
            filter: {
                x: '160.45',
                y: '16.65',
                width: '180.99',
                height: '169.96',
            },
            clip: {
                d: 'M0,133.96l144.99-33.09A155.35,155.35,0,0,0,64.52,0Z',
                translate: '0.45 0.65',
                translate2: '178.45 32.65',
            },
            shape: {
                d: 'M155,310a156.143,156.143,0,0,1-31.238-3.149,154.151,154.151,0,0,1-55.424-23.322,155.452,155.452,0,0,1-56.157-68.2,154.218,154.218,0,0,1-9.032-29.1,156.509,156.509,0,0,1,0-62.476A154.151,154.151,0,0,1,26.472,68.338a155.453,155.453,0,0,1,68.2-56.157,154.22,154.22,0,0,1,29.1-9.032,156.509,156.509,0,0,1,62.476,0,154.151,154.151,0,0,1,55.424,23.323,155.452,155.452,0,0,1,56.157,68.2,154.218,154.218,0,0,1,9.032,29.1,156.512,156.512,0,0,1,0,62.476,154.15,154.15,0,0,1-23.322,55.424,155.451,155.451,0,0,1-68.2,56.157,154.224,154.224,0,0,1-29.1,9.032A156.146,156.146,0,0,1,155,310ZM155,8a148.086,148.086,0,0,0-29.626,2.986A146.193,146.193,0,0,0,72.811,33.105,147.431,147.431,0,0,0,19.552,97.781a146.263,146.263,0,0,0-8.565,27.593,148.434,148.434,0,0,0,0,59.251,146.2,146.2,0,0,0,22.119,52.564,147.43,147.43,0,0,0,64.676,53.259,146.262,146.262,0,0,0,27.593,8.566,148.434,148.434,0,0,0,59.251,0A146.2,146.2,0,0,0,237.189,276.9a147.43,147.43,0,0,0,53.259-64.676,146.262,146.262,0,0,0,8.566-27.594,148.434,148.434,0,0,0,0-59.251A146.195,146.195,0,0,0,276.9,72.811a147.43,147.43,0,0,0-64.676-53.259,146.263,146.263,0,0,0-27.594-8.565A148.088,148.088,0,0,0,155,8Z',
                translate: '-160 -16',
                translate2: '160 16',
            },
            text: {
                translate: '167 19',
                translate2: '60 42',
                translate3: '15 12',
                translate4: '15 39',
                x: '-13.882',
                x2: '-14.714',
            },
        },
        standard: {
            shape: {
                path: 'M1.752,116.987,58.072,0a185.684,185.684,0,0,1,90.953,112.194L21.2,141.378a17.833,17.833,0,0,1-3.979.455A17.337,17.337,0,0,1,1.752,116.987Z',
                translate: '988.591 468.464',
            },
            text: {
                translate: '1124 490',
            },
        },
    },
    {
        dimension: 3 as any,
        results: {
            filter: {
                x: '161.79',
                y: '123.41',
                width: '184.22',
                height: '161.82',
            },
            clip: {
                d: 'M0,33.09l116.28,92.73a154.281,154.281,0,0,0,31.94-94.23A155.067,155.067,0,0,0,144.99,0Z',
                translate: '0.79 0.41',
                translate2: '179.79 139.41',
            },
            shape: {
                d: 'M155,310a156.143,156.143,0,0,1-31.238-3.149,154.151,154.151,0,0,1-55.424-23.322,155.452,155.452,0,0,1-56.157-68.2,154.218,154.218,0,0,1-9.032-29.1,156.509,156.509,0,0,1,0-62.476A154.151,154.151,0,0,1,26.472,68.338a155.453,155.453,0,0,1,68.2-56.157,154.22,154.22,0,0,1,29.1-9.032,156.509,156.509,0,0,1,62.476,0,154.151,154.151,0,0,1,55.424,23.323,155.452,155.452,0,0,1,56.157,68.2,154.218,154.218,0,0,1,9.032,29.1,156.512,156.512,0,0,1,0,62.476,154.15,154.15,0,0,1-23.322,55.424,155.451,155.451,0,0,1-68.2,56.157,154.224,154.224,0,0,1-29.1,9.032A156.146,156.146,0,0,1,155,310ZM155,8a148.086,148.086,0,0,0-29.626,2.986A146.193,146.193,0,0,0,72.811,33.105,147.431,147.431,0,0,0,19.552,97.781a146.263,146.263,0,0,0-8.565,27.593,148.434,148.434,0,0,0,0,59.251,146.2,146.2,0,0,0,22.119,52.564,147.43,147.43,0,0,0,64.676,53.259,146.262,146.262,0,0,0,27.593,8.566,148.434,148.434,0,0,0,59.251,0A146.2,146.2,0,0,0,237.189,276.9a147.43,147.43,0,0,0,53.259-64.676,146.262,146.262,0,0,0,8.566-27.594,148.434,148.434,0,0,0,0-59.251A146.195,146.195,0,0,0,276.9,72.811a147.43,147.43,0,0,0-64.676-53.259,146.263,146.263,0,0,0-27.594-8.565A148.088,148.088,0,0,0,155,8Z',
                translate: '-161 -123',
                translate2: '161 123',
            },
            text: {
                translate: '172 125',
                translate2: '69 34',
                translate3: '25.5 12',
                translate4: '26 40',
                x: '-25.413',
                x2: '-13.886',
            },
        },
        standard: {
            shape: {
                path: 'M6.53,59.7a17.315,17.315,0,0,1,6.939-30.417L141.716,0a186.234,186.234,0,0,1,3.105,33.958A184.4,184.4,0,0,1,109.945,142.18Z',
                translate: '1000.178 597.44',
            },
            text: {
                translate: '1162 658',
            },
        },
    },
    {
        dimension: 4 as any,
        results: {
            filter: {
                x: '158.03',
                y: '161.22',
                width: '152.28',
                height: '184.74',
            },
            clip: {
                d: 'M0,0V148.74A154.645,154.645,0,0,0,116.28,92.73Z',
                translate: '0.03 0.22',
                translate2: '176.03 177.22',
            },
            shape: {
                d: 'M155,310a156.143,156.143,0,0,1-31.238-3.149,154.151,154.151,0,0,1-55.424-23.322,155.452,155.452,0,0,1-56.157-68.2,154.218,154.218,0,0,1-9.032-29.1,156.509,156.509,0,0,1,0-62.476A154.151,154.151,0,0,1,26.472,68.338a155.453,155.453,0,0,1,68.2-56.157,154.22,154.22,0,0,1,29.1-9.032,156.509,156.509,0,0,1,62.476,0,154.151,154.151,0,0,1,55.424,23.323,155.452,155.452,0,0,1,56.157,68.2,154.218,154.218,0,0,1,9.032,29.1,156.512,156.512,0,0,1,0,62.476,154.15,154.15,0,0,1-23.322,55.424,155.451,155.451,0,0,1-68.2,56.157,154.224,154.224,0,0,1-29.1,9.032A156.146,156.146,0,0,1,155,310ZM155,8a148.086,148.086,0,0,0-29.626,2.986A146.193,146.193,0,0,0,72.811,33.105,147.431,147.431,0,0,0,19.552,97.781a146.263,146.263,0,0,0-8.565,27.593,148.434,148.434,0,0,0,0,59.251,146.2,146.2,0,0,0,22.119,52.564,147.43,147.43,0,0,0,64.676,53.259,146.262,146.262,0,0,0,27.593,8.566,148.434,148.434,0,0,0,59.251,0A146.2,146.2,0,0,0,237.189,276.9a147.43,147.43,0,0,0,53.259-64.676,146.262,146.262,0,0,0,8.566-27.594,148.434,148.434,0,0,0,0-59.251A146.195,146.195,0,0,0,276.9,72.811a147.43,147.43,0,0,0-64.676-53.259,146.263,146.263,0,0,0-27.594-8.565A148.088,148.088,0,0,0,155,8Z',
                translate: '-158 -161',
                translate2: '158 161',
            },
            text: {
                translate: '158 170',
                translate2: '12 66',
                translate3: '35.5 12',
                translate4: '36 39',
                x: '-35.38',
                x2: '-15.002',
            },
        },
        standard: {
            shape: {
                path: 'M0,17.347A17.312,17.312,0,0,1,28.105,3.809L130.974,85.852A184.754,184.754,0,0,1,0,149.031Z',
                translate: '967.81 666.867',
            },
            text: {
                translate: '1063 804',
            },
        },
    },
    {
        dimension: 5 as any,
        results: {
            filter: {
                x: '35.71',
                y: '161.22',
                width: '152.28',
                height: '184.74',
            },
            clip: {
                d: 'M116.28,0,0,92.73a154.678,154.678,0,0,0,116.28,56.01Z',
                translate: '0.71 0.22',
                translate2: '53.71 177.22',
            },
            shape: {
                d: 'M155,310a156.143,156.143,0,0,1-31.238-3.149,154.151,154.151,0,0,1-55.424-23.322,155.452,155.452,0,0,1-56.157-68.2,154.218,154.218,0,0,1-9.032-29.1,156.509,156.509,0,0,1,0-62.476A154.151,154.151,0,0,1,26.472,68.338a155.453,155.453,0,0,1,68.2-56.157,154.22,154.22,0,0,1,29.1-9.032,156.509,156.509,0,0,1,62.476,0,154.151,154.151,0,0,1,55.424,23.323,155.452,155.452,0,0,1,56.157,68.2,154.218,154.218,0,0,1,9.032,29.1,156.512,156.512,0,0,1,0,62.476,154.15,154.15,0,0,1-23.322,55.424,155.451,155.451,0,0,1-68.2,56.157,154.224,154.224,0,0,1-29.1,9.032A156.146,156.146,0,0,1,155,310ZM155,8a148.086,148.086,0,0,0-29.626,2.986A146.193,146.193,0,0,0,72.811,33.105,147.431,147.431,0,0,0,19.552,97.781a146.263,146.263,0,0,0-8.565,27.593,148.434,148.434,0,0,0,0,59.251,146.2,146.2,0,0,0,22.119,52.564,147.43,147.43,0,0,0,64.676,53.259,146.262,146.262,0,0,0,27.593,8.566,148.434,148.434,0,0,0,59.251,0A146.2,146.2,0,0,0,237.189,276.9a147.43,147.43,0,0,0,53.259-64.676,146.262,146.262,0,0,0,8.566-27.594,148.434,148.434,0,0,0,0-59.251A146.195,146.195,0,0,0,276.9,72.811a147.43,147.43,0,0,0-64.676-53.259,146.263,146.263,0,0,0-27.594-8.565A148.088,148.088,0,0,0,155,8Z',
                translate: '-35 -161',
                translate2: '35 161',
            },
            text: {
                translate: '39 170',
                translate2: '45 65',
                translate3: '14.5 12',
                translate4: '16 39',
                x: '-14.387',
                x2: '-13.85',
            },
        },
        standard: {
            shape: {
                path: 'M0,85.658,102.587,3.812a17.312,17.312,0,0,1,28.108,13.535V149A184.769,184.769,0,0,1,0,85.658Z',
                translate: '819.804 666.879',
            },
            text: {
                translate: '799 804',
            },
        },
    },
    {
        dimension: 6 as any,
        results: {
            text: {
                translate: '0 125',
                translate2: '9 34',
                translate3: '31.5 12',
                translate4: '31.5 39',
                x: '-31.209',
                x2: '-13.826',
            },
            filter: {
                x: '0',
                y: '123.41',
                width: '184.22',
                height: '161.82',
            },
            clip: {
                d: 'M148.22,33.09,3.23,0A155.23,155.23,0,0,0,31.94,125.82Z',
                translate: '0 0.41',
                translate2: '18 139.41',
            },
            shape: {
                d: 'M155,310a156.143,156.143,0,0,1-31.238-3.149,154.151,154.151,0,0,1-55.424-23.322,155.452,155.452,0,0,1-56.157-68.2,154.218,154.218,0,0,1-9.032-29.1,156.509,156.509,0,0,1,0-62.476A154.151,154.151,0,0,1,26.472,68.338a155.453,155.453,0,0,1,68.2-56.157,154.22,154.22,0,0,1,29.1-9.032,156.509,156.509,0,0,1,62.476,0,154.151,154.151,0,0,1,55.424,23.323,155.452,155.452,0,0,1,56.157,68.2,154.218,154.218,0,0,1,9.032,29.1,156.512,156.512,0,0,1,0,62.476,154.15,154.15,0,0,1-23.322,55.424,155.451,155.451,0,0,1-68.2,56.157,154.224,154.224,0,0,1-29.1,9.032A156.146,156.146,0,0,1,155,310ZM155,8a148.086,148.086,0,0,0-29.626,2.986A146.193,146.193,0,0,0,72.811,33.105,147.431,147.431,0,0,0,19.552,97.781a146.263,146.263,0,0,0-8.565,27.593,148.434,148.434,0,0,0,0,59.251,146.2,146.2,0,0,0,22.119,52.564,147.43,147.43,0,0,0,64.676,53.259,146.262,146.262,0,0,0,27.593,8.566,148.434,148.434,0,0,0,59.251,0A146.2,146.2,0,0,0,237.189,276.9a147.43,147.43,0,0,0,53.259-64.676,146.262,146.262,0,0,0,8.566-27.594,148.434,148.434,0,0,0,0-59.251A146.195,146.195,0,0,0,276.9,72.811a147.43,147.43,0,0,0-64.676-53.259,146.263,146.263,0,0,0-27.594-8.565A148.088,148.088,0,0,0,155,8Z',
                translate: '0 -123',
                translate2: '0 123',
            },
        },
        standard: {
            shape: {
                path: 'M0,33.556A186.344,186.344,0,0,1,3.031,0L129.661,28.89A17.314,17.314,0,0,1,136.606,59.3L34.28,140.945A184.406,184.406,0,0,1,0,33.556Z',
                translate: '774.998 597.842',
            },
            text: {
                translate: '631 660',
            },
        },
    },
    {
        dimension: 7 as any,
        results: {
            filter: {
                x: '4.59',
                y: '16.65',
                width: '180.98',
                height: '169.96',
            },
            clip: {
                d: 'M144.98,133.96,80.47,0A155.291,155.291,0,0,0,0,100.87Z',
                translate: '0.59 0.65',
                translate2: '22.59 32.65',
            },
            shape: {
                d: 'M155,310a156.143,156.143,0,0,1-31.238-3.149,154.151,154.151,0,0,1-55.424-23.322,155.452,155.452,0,0,1-56.157-68.2,154.218,154.218,0,0,1-9.032-29.1,156.509,156.509,0,0,1,0-62.476A154.151,154.151,0,0,1,26.472,68.338a155.453,155.453,0,0,1,68.2-56.157,154.22,154.22,0,0,1,29.1-9.032,156.509,156.509,0,0,1,62.476,0,154.151,154.151,0,0,1,55.424,23.323,155.452,155.452,0,0,1,56.157,68.2,154.218,154.218,0,0,1,9.032,29.1,156.512,156.512,0,0,1,0,62.476,154.15,154.15,0,0,1-23.322,55.424,155.451,155.451,0,0,1-68.2,56.157,154.224,154.224,0,0,1-29.1,9.032A156.146,156.146,0,0,1,155,310ZM155,8a148.086,148.086,0,0,0-29.626,2.986A146.193,146.193,0,0,0,72.811,33.105,147.431,147.431,0,0,0,19.552,97.781a146.263,146.263,0,0,0-8.565,27.593,148.434,148.434,0,0,0,0,59.251,146.2,146.2,0,0,0,22.119,52.564,147.43,147.43,0,0,0,64.676,53.259,146.262,146.262,0,0,0,27.593,8.566,148.434,148.434,0,0,0,59.251,0A146.2,146.2,0,0,0,237.189,276.9a147.43,147.43,0,0,0,53.259-64.676,146.262,146.262,0,0,0,8.566-27.594,148.434,148.434,0,0,0,0-59.251A146.195,146.195,0,0,0,276.9,72.811a147.43,147.43,0,0,0-64.676-53.259,146.263,146.263,0,0,0-27.594-8.565A148.088,148.088,0,0,0,155,8Z',
                translate: '-4 -16',
                translate2: '4 16',
            },
            text: {
                translate: '6 19',
                translate2: '41 42',
                translate3: '20 12',
                translate4: '20 40',
                x: '-19.895',
                x2: '-13.826',
            },
        },
        standard: {
            shape: {
                path: 'M126.2,140.632,0,111.839A185.693,185.693,0,0,1,89.668,0l55.983,116.239a17.333,17.333,0,0,1-15.47,24.849A17.86,17.86,0,0,1,126.2,140.632Z',
                translate: '782.094 468.552',
            },
            text: {
                translate: '714 490',
            },
        },
    },
    {
        dimension: 7 as any,
        results: {
            filter: {
                x: '4.59',
                y: '16.65',
                width: '180.98',
                height: '169.96',
            },
            clip: {
                d: 'M144.98,133.96,80.47,0A155.291,155.291,0,0,0,0,100.87Z',
                translate: '0.59 0.65',
                translate2: '22.59 32.65',
            },
            shape: {
                d: 'M155,310a156.143,156.143,0,0,1-31.238-3.149,154.151,154.151,0,0,1-55.424-23.322,155.452,155.452,0,0,1-56.157-68.2,154.218,154.218,0,0,1-9.032-29.1,156.509,156.509,0,0,1,0-62.476A154.151,154.151,0,0,1,26.472,68.338a155.453,155.453,0,0,1,68.2-56.157,154.22,154.22,0,0,1,29.1-9.032,156.509,156.509,0,0,1,62.476,0,154.151,154.151,0,0,1,55.424,23.323,155.452,155.452,0,0,1,56.157,68.2,154.218,154.218,0,0,1,9.032,29.1,156.512,156.512,0,0,1,0,62.476,154.15,154.15,0,0,1-23.322,55.424,155.451,155.451,0,0,1-68.2,56.157,154.224,154.224,0,0,1-29.1,9.032A156.146,156.146,0,0,1,155,310ZM155,8a148.086,148.086,0,0,0-29.626,2.986A146.193,146.193,0,0,0,72.811,33.105,147.431,147.431,0,0,0,19.552,97.781a146.263,146.263,0,0,0-8.565,27.593,148.434,148.434,0,0,0,0,59.251,146.2,146.2,0,0,0,22.119,52.564,147.43,147.43,0,0,0,64.676,53.259,146.262,146.262,0,0,0,27.593,8.566,148.434,148.434,0,0,0,59.251,0A146.2,146.2,0,0,0,237.189,276.9a147.43,147.43,0,0,0,53.259-64.676,146.262,146.262,0,0,0,8.566-27.594,148.434,148.434,0,0,0,0-59.251A146.195,146.195,0,0,0,276.9,72.811a147.43,147.43,0,0,0-64.676-53.259,146.263,146.263,0,0,0-27.594-8.565A148.088,148.088,0,0,0,155,8Z',
                translate: '-4 -16',
                translate2: '4 16',
            },
            text: {
                translate: '6 19',
                translate2: '41 42',
                translate3: '20 12',
                translate4: '20 40',
                x: '-19.895',
                x2: '-13.826',
            },
        },
        standard: {
            shape: {
                path: 'M126.2,140.632,0,111.839A185.693,185.693,0,0,1,89.668,0l55.983,116.239a17.333,17.333,0,0,1-15.47,24.849A17.86,17.86,0,0,1,126.2,140.632Z',
                translate: '782.094 468.552',
            },
            text: {
                translate: '714 490',
            },
        },
    },
];
