import { combineReducers } from 'redux';

import headerReducer from './header';
import footerReducer from './footer';
import questionsReducer from './questions';
import fieldErrorsReducer from 'lib/src/redux/reducers/fieldErrors';
import redirectsReducer from 'lib/src/redux/reducers/redirects';
import darkModeReducer from 'lib/src/redux/reducers/darkMode';
import resultsReducer from './results';
import pagesReducer from './pages';

const rootReducer = combineReducers({
    headerReducer,
    footerReducer,
    fieldErrorsReducer,
    redirectsReducer,
    resultsReducer,
    questionsReducer,
    darkModeReducer,
    pagesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
