import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setCurrentPage } from '@actions/pages/setCurrentPage';

import { QuestionnairePages } from '../../../../types/shared';

export default function useQuestionnairePage(resumeCode: string | null) {
    const isNewUser = resumeCode == null;
    const dispatch = useDispatch();

    const initialState = isNewUser
        ? QuestionnairePages.yourAssessment
        : QuestionnairePages.dimensions;
    const [page, setPage] = useState(initialState);

    useEffect(() => window.scroll(0, 0), [page]);

    const next = () => {
        setPage(page + 1);
        dispatch(setCurrentPage(page + 1));
    };

    return [page, next, setPage] as const;
}
