import Cursor from '@pages/home/questionnaire/sevenDimensions/Cursor';
import Tooltip from '@pages/home/questionnaire/sevenDimensions/Tooltip';
import { FC } from 'react';
import { Trans } from 'react-i18next';
import { CompletedTextType, IVector2 } from 'src/types/shared';

export const CompletedText = (props: CompletedTextType) => {
    const { offset, scale, dimensions, numCompleted } = props;
    return (
        <g transform={`translate(${offset.x} ${offset.y}) scale(${scale.x} ${scale.y})`}>
            <NumericCompletion numCompleted={numCompleted} total={dimensions} />
            <CompletedTextHeader />
            <DimensionText />
        </g>
    );
};

interface INumericCompletion {
    total: number;
    numCompleted: number;
}
const NumericCompletion: FC<INumericCompletion> = ({ numCompleted, total }) => (
    <g id="Group_24" data-name="Group 24" transform="translate(0 0)">
        <text
            id="_1"
            data-name="1"
            transform="translate(-95 55)"
            fill="#393f44"
            fontSize="149"
            fontFamily="Montserrat Regular, Montserrat"
            letterSpacing="-0.008em"
        >
            <tspan x="0" y="0">
                {numCompleted}
            </tspan>
        </text>
        <text
            id={`_${total}`}
            data-name={`/ ${total}`}
            transform="translate(16 25)"
            fill="#999"
            fontSize="58"
            fontFamily="Montserrat Regular, Montserrat"
            letterSpacing="-0.021em"
        >
            <tspan x="0" y="0">
                / {total}
            </tspan>
        </text>
    </g>
);

const CompletedTextHeader: FC = () => (
    <text
        id="Completed"
        transform="translate(0 -70)"
        fill="#393f44"
        fontSize="25"
        fontFamily="Montserrat SemiBold, Montserrat"
        fontWeight="600"
        letterSpacing="-0.031em"
    >
        <tspan x="-68.386" y="0">
            <Trans i18nKey="wheel.completed" ns="common" />
        </tspan>
    </text>
);

const DimensionText: FC = () => (
    <text
        id="dimensions"
        transform="translate(-56 100)"
        fill="#999"
        fontSize="19"
        fontFamily="Montserrat Regular, Montserrat"
        fontWeight="500"
        letterSpacing="-0.03em"
    >
        <tspan x="0" y="0">
            <Trans i18nKey="wheel.dimensions" ns="common" />
        </tspan>
    </text>
);

interface IToolTipsCursors {
    numCompleted: number;
    position: IVector2;
}
export const ToolTipsCursors: FC<IToolTipsCursors> = ({ numCompleted, position }) => (
    <>
        {numCompleted <= 0 && (
            <>
                <Cursor position={position} />
                <Tooltip position={position} />
            </>
        )}
    </>
);

export default CompletedText;
