import { useTranslation } from 'react-i18next';
import Title from '@components/title/Title';
import huggingImg from '../../../../_content/images/hugging.svg';

export default function Thanks() {
    const { t } = useTranslation(['pages']);
    return (
        <div id="thanks-page">
            <div className="flex-row justify-center align-end banner">
                <img src={huggingImg} alt="" />
            </div>
            <div className="wrapper">
                <Title
                    title={t('thanks.thanks.title')}
                    subTitle={t('thanks.thanks.subTitle')}
                    extraClasses="full-width"
                />
            </div>
        </div>
    );
}
