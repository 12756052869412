import { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getQuestionsData } from '@selectors/questions';
import Title from '@components/title/Title';
import Modal from 'lib/src/components/modal/Modal';
import ActionButton from 'lib/src/components/button/ActionButton';

interface Props {
    state: boolean;
    onClose: () => void;
}

export default function AccessCodeModal({ state, onClose }: Props) {
    const questionData = useSelector(getQuestionsData);
    const { t } = useTranslation(['pages', 'common']);

    const [copied, setCopied] = useState(false);

    const codeRef = useRef<HTMLInputElement>(null);
    const copyCode = () => {
        codeRef.current?.select();
        codeRef.current?.setSelectionRange(0, 8);
        document.execCommand('copy');
        setCopied(true);
    };

    useLayoutEffect(() => setCopied(false), [setCopied, state]);

    if (!state || !questionData?.resumeCode) return null;

    return (
        <Modal closeModal={onClose}>
            <Title title={t('saveModal.accessCode')} subTitle={t('saveModal.subTitle')} />
            <div className="flex-column align-center access-code-modal">
                <div>
                    <div className="flex-row align-center">
                        <input
                            className="form-input"
                            name="code"
                            value={questionData.resumeCode}
                            readOnly
                            ref={codeRef}
                            onFocus={copyCode}
                        />
                        <i className="far fa-copy copy-icon" onClick={copyCode} />
                    </div>
                    {copied && (
                        <p className="flex-row justify-center copied">{t('saveModal.clipboard')}</p>
                    )}
                </div>
                <ActionButton onClick={onClose}>{t('button.close', { ns: 'common' })}</ActionButton>
            </div>
        </Modal>
    );
}
