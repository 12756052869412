import { useState } from 'react';
import { SelectedAnswer } from 'src/types/shared/QuestionData';

const useCustomAnswerHandler = () => {
    const [answers, setAnswer] = useState<SelectedAnswer[]>([]);

    const handleChange = (questionID: number, answer: string | null) => {
        setAnswer(selected => {
            let newSelectedAnswers = selected.filter(
                selectedAnswer => selectedAnswer.questionID !== questionID,
            );

            if (!!answer) {
                newSelectedAnswers.push({ questionID, answer });
            }
            return newSelectedAnswers;
        });
    };

    const selectAnswer = (
        questionID: number,
        answerID: number | number[] | null,
        score: number | undefined,
    ) => {
        setAnswer(selected => {
            let newSelectedAnswers = selected.filter(
                selectedAnswer => selectedAnswer.questionID !== questionID,
            );
            if (Array.isArray(answerID)) {
                answerID.forEach(answerID =>
                    newSelectedAnswers.push({ questionID, answerID, score }),
                );
            } else {
                if (!!answerID) {
                    newSelectedAnswers.push({ questionID, answerID, score });
                }
            }
            return newSelectedAnswers;
        });
    };

    return { answers, handleChange, selectAnswer };
};

export default useCustomAnswerHandler;
