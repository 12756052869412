import { useCallback, useEffect, useState } from 'react';
import useSubmitQuestionnaire from '../../../hooks/useSubmitQuestionnaire';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useSelector } from 'react-redux';

import { getQuestionsIsPosting, getQuestionsPostSuccess } from '@selectors/questions';
import { dimensionColours } from '../../../../../../constants/dimensions';
import QuestionData, { Dimension } from 'src/types/shared/QuestionData';
import useCompleted from '../../hooks/useCompleted';

const useSingleDimension = (dimension: Dimension, questionData: QuestionData) => {
    const [resetSlide, setResetSlide] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [questionNumber, setQuestionNumber] = useState(0);

    const { selectedDimensionAnswers, selectDimensionAnswer, handleSubmit, handleTextInputChange } =
        useSubmitQuestionnaire(questionData);

    useEffect(() => {
        if (resetSlide) {
            setResetSlide(false);
        }
    }, [resetSlide]);

    const dimensionColourNumber =
        dimension.sort > 10 ? Number(dimension.sort.toString().slice(-1)) : dimension.sort;

    const color = dimensionColours[dimensionColourNumber];
    const titleText = dimension.questions[questionNumber]?.subcategoryTitle;

    const isPosting = useSelector(getQuestionsIsPosting);
    const postSuccess = useSelector(getQuestionsPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    const completed: Record<number, boolean> = useCompleted(
        questionData.dimensions,
        selectedDimensionAnswers,
    );

    useEffect(() => {
        if (completed[dimension.sort]) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    }, [completed, dimension]);

    const getNextDimension = useCallback(
        (completed: Record<number, boolean>) => {
            const { dimensions } = questionData;
            const completedValues = Object.values(completed);
            const currentDimension = dimension.sort - 1;
            for (let i = currentDimension; i < currentDimension + completedValues.length; i++) {
                const safeIndex = i % completedValues.length;
                if (!completedValues[safeIndex]) return dimensions[safeIndex];
            }
            return dimensions[0];
        },
        [questionData, dimension],
    );

    return {
        titleText,
        selectedDimensionAnswers,
        selectDimensionAnswer,
        handleSubmit,
        color,
        resetSlide,
        setResetSlide,
        isPosting,
        postSuccess,
        prevPostSuccess,
        getNextDimension,
        isSubmitDisabled,
        questionNumber,
        setQuestionNumber,
        completed,
        handleTextInputChange,
    };
};

export default useSingleDimension;
