import { IVector2 } from 'src/types/shared';
import { useSpring, a } from 'react-spring';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

interface ITooltipConfig {
    position: IVector2;
}
export default function Tooltip({ position }: ITooltipConfig) {
    const { t } = useTranslation(['pages']);
    const [hovered, setHovered] = useState<boolean>(false);
    const spring = useSpring({ opacity: hovered ? 0 : 1 });
    const style = {
        pointerEvents: hovered ? 'none' : 'auto',
    };
    return (
        <a.g
            id="Group_108"
            data-name="Group 108"
            onMouseEnter={() => setHovered(true)}
            transform={`translate(${position.x} ${position.y - 50})`}
            {...spring}
            {...style}
        >
            <rect opacity={0} width={600} height={350} transform={`translate(-300,-150)`} />
            <g id="Group_107" data-name="Group 107">
                <rect
                    id="Rectangle_17"
                    data-name="Rectangle 17"
                    width="145"
                    height="30"
                    fill="#ededed"
                    transform="translate(0,0)"
                />
                <path
                    transform="translate(0,30)"
                    id="Path_60"
                    data-name="Path 60"
                    d="M20,0 l-20,20 V0Z"
                    fill="#ededed"
                />
            </g>
            <text
                id="Try_starting_here_"
                data-name="Try starting here?"
                fill="#393f44"
                fontSize="13.6"
                fontFamily="Montserrat Regular, Montserrat"
            >
                <tspan x="15" y="20">
                    {t('sevenDimensions.toolTip')}
                </tspan>
            </text>
        </a.g>
    );
}
