import useWheelCompletedClasses from './hooks/useWheelCompletedClasses';
import { DrawBackdrop, DrawSegments } from '@components/wheel/wheelSlicesDynamic';
import { Dimension, Dimensions } from 'src/types/shared/QuestionData';
import {
    IBarRadius,
    IDimensionDraw,
    ISegment,
    ISegmentTextProps,
    IVector2,
} from 'src/types/shared';
import { FC, useMemo } from 'react';
import CompletedText, { ToolTipsCursors } from '@components/wheel/completedText';

interface Props {
    completed: Record<number, boolean>;
    setDimension: (dimension: Dimension | null) => void;
    dimensions: Dimensions;
}

export default function Wheel({ dimensions, completed, setDimension }: Props) {
    const completedClasses = useWheelCompletedClasses(completed);

    const onClickDimension = (dimension: Dimension) => {
        if (!completed[dimension.sort]) setDimension(dimension);
    };

    return (
        <DrawDimension
            dimensions={dimensions}
            onClickDimension={onClickDimension}
            completedClasses={completedClasses}
            completed={completed}
        />
    );
}

const DrawDimension = (dimensionDraw: IDimensionDraw) => {
    const segs = dimensionDraw.dimensions.length;
    const segments: ISegment[] = dimensionDraw.dimensions.map((_, i) => ({
        rotation: i * (360 / segs),
    }));

    const offset: IVector2 = { x: 322, y: 234 };
    const scale: IVector2 = { x: 3, y: 3 };
    const barRadius: IBarRadius = { innerRadius: 58, outerRadius: 63 };
    const numCompleted = useMemo(
        () => Object.values(dimensionDraw.completed).filter(dimension => dimension).length,
        [dimensionDraw.completed],
    );
    return (
        <svg
            width="1000"
            height="463.68"
            viewBox="0 0 642 463.68"
            className="wheel"
            xmlns="http://www.w3.org/2000/svg"
        >
            <DrawBackdrop offset={offset} scale={scale} barRadius={barRadius} />
            <CompletedText
                dimensions={segs}
                numCompleted={numCompleted}
                offset={offset}
                scale={{ x: 1, y: 1 }}
            />
            {segments.map((segment, index) => (
                <g key={index}>
                    <DrawSegments
                        key={index}
                        totalSegs={segs}
                        offset={offset}
                        scale={scale}
                        index={index}
                        dimension={dimensionDraw.dimensions[index]}
                        segment={segment}
                        opacity={{ from: 0.4, to: 1 }}
                        onClickDimension={dimensionDraw.onClickDimension}
                        completedClass={dimensionDraw.completedClasses[index]}
                        barRadius={barRadius}
                        segmentTextComponent={WheelText}
                    />
                </g>
            ))}

            <g transform={`translate(${offset.x} ${offset.y}) scale(1 1)`}>
                <ToolTipsCursors numCompleted={numCompleted} position={{ x: 30, y: -180 }} />
            </g>
        </svg>
    );
};

const WheelText: FC<ISegmentTextProps> = ({
    transform,
    onClickDimension,
    name,
    dimension,
    centerTextTransform,
}) => (
    <text
        transform={transform + centerTextTransform}
        fontSize="20"
        fontFamily="Montserrat SemiBold, Montserrat"
        fontWeight="600"
        z={-1}
        onClick={() => {
            if (onClickDimension) {
                onClickDimension(dimension);
            }
        }}
    >
        <tspan>{name}</tspan>
    </text>
);
