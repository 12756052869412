const Title: React.FC<TitleProps> = ({ title, subTitle, extraClasses = '', children }) => {
    return (
        <div className="container flex-row justify-center">
            <section className={`title-wrapper flex-column align-center ${extraClasses}`}>
                <h1 className="title">{title}</h1>
                {!!subTitle && <h2 className="sub-title">{subTitle}</h2>}
                {children}
            </section>
        </div>
    );
};

interface TitleProps {
    title: string;
    subTitle?: string;
    extraClasses?: string;
}

export default Title;
