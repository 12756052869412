import { setOnboardingSectionAction } from '@actions/questions/setOnboardingSection';
import { getCurrentOnboardingSection } from '@selectors/questions';
import { useDispatch, useSelector } from 'react-redux';
import QuestionData from 'src/types/shared/QuestionData';

const useOnboardingSections = (questionData: QuestionData) => {
    const dispatch = useDispatch();
    const currentSection = useSelector(getCurrentOnboardingSection);

    const isFirstSection = currentSection === null || currentSection === 0;
    const isLastSection = currentSection >= questionData.onboardingSections.length - 1;

    const setCurrentSection = (newSection: number) => {
        dispatch(setOnboardingSectionAction(newSection));
    };

    return [currentSection, setCurrentSection, isLastSection, isFirstSection] as const;
};

export default useOnboardingSections;
