import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HomeContent from './HomeContent';
import InitialScreen from './InitialScreen';
import {
    getQuestionsData,
    getQuestionsFetchError,
    getQuestionsFetchSuccess,
    getQuestionsIsFetching,
} from '@selectors/questions';
import Questionnaire from './questionnaire/Questionnaire';
import { setHeaderState } from '@actions/header/toggleHeader';
import { setFooterState } from '@actions/footer/toggleFooter';
import { setCurrentLanguage } from '@actions/pages/setCurrentLanguage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchQuestions } from '@actions/questions/getQuestions';

export default function Home() {
    const { i18n } = useTranslation();
    const [isLogin, setIsLogin] = useState(false);
    const { accessCode } = useParams<{ accessCode: string | undefined }>();
    const dispatch = useDispatch();
    const questionsFetchSuccess = useSelector(getQuestionsFetchSuccess);
    const isFetching = useSelector(getQuestionsIsFetching);
    const fetchError = useSelector(getQuestionsFetchError);
    const questionData = useSelector(getQuestionsData);
    useEffect(() => {
        if (accessCode && !questionData) {
            dispatch(fetchQuestions(accessCode));
        }
        if (questionData && questionData.languageCode) {
            // Set the language to the Language Code from the API
            i18n.changeLanguage(questionData.languageCode);
            dispatch(setCurrentLanguage(questionData.languageCode));
        }
        dispatch(setHeaderState(questionsFetchSuccess && !!questionData));
        dispatch(setFooterState(questionsFetchSuccess && !!questionData));
    }, [questionsFetchSuccess, questionData, dispatch, accessCode, i18n]);
    if (questionsFetchSuccess && !!questionData) {
        return (
            <Questionnaire questionData={questionData} hasQuickLink={accessCode !== undefined} />
        );
    }
    if (accessCode && isFetching) return <></>;
    if (accessCode && fetchError)
        return <h1 className="error">Access code does not exist or is no longer valid</h1>;

    dispatch(setHeaderState(false));
    dispatch(setFooterState(false));
    if (isLogin) return <HomeContent />;
    return <InitialScreen setIsLogin={setIsLogin} />;
}
