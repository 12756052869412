import { useState } from 'react';
import useAccessCodeForm from './hooks/useAccessCodeForm';

import Form from 'lib/src/components/form/Form';
import TextInput from 'lib/src/components/form/TextInput';
import Checkbox from 'lib/src/components/form/Checkbox';
import ActionButton from 'lib/src/components/button/ActionButton';
import Shield from '../../_content/images/security.png';
import { useDispatch } from 'react-redux';
import { fetchQuestionsError } from '@actions/questions/getQuestions';
// import { externalUrls } from '../../../src/utils/constants';
import { validateAccessCode } from 'lib/src/utils/validation';

const Home = () => {
    const {
        code,
        privacyAgree,
        authorizedConfirm,
        sentCode,
        onCodeChange,
        onPrivacyAgreeChange,
        onAuthorizedConfirmChange,
        onSubmit,
        isFetching,
        fetchError,
    } = useAccessCodeForm();

    const [showError, setShowError] = useState(false);
    const dispatch = useDispatch();

    return (
        <div id="home-page">
            <div className="home-background">
                <div className="container banner">
                    <h1 className="flex start-screen-title">
                        Improving the mental wellbeing of society percent by percent
                    </h1>
                </div>
                <div className="container banner">
                    <h2 className="get-started-subtitle">Get started</h2>
                </div>
                <div className="container lets-get-started align-center">
                    <Form onSubmit={onSubmit} omitButtons>
                        <div className="flex-row align-center actions">
                            <TextInput
                                placeholder="eg. 639313"
                                label="Access Code"
                                extraClasses="access-code-input"
                                labelClass="access-code-label"
                                name="code"
                                value={code}
                                onChange={onCodeChange}
                                required
                                showRequiredAsterix={false}
                                minLength={3}
                                maxLength={32}
                                customValidate={v => {
                                    if (fetchError && code === sentCode) return 'Invalid code';

                                    return validateAccessCode(v);
                                }}
                                hasError={(err: string) => {
                                    if (err) {
                                        setShowError(true);
                                    } else {
                                        setShowError(false);
                                    }
                                }}
                                showErrorTop={true}
                            />
                            <ActionButton
                                className={showError ? 'invalid' : ''}
                                color="gold"
                                variant="flat"
                                type="submit"
                                isPosting={isFetching}
                                onClick={() => dispatch(fetchQuestionsError(null))}
                            >
                                Go
                            </ActionButton>
                        </div>
                        <div className="flex-column radio-buttons">
                            <div className="flex-row align-center radio-wrapper">
                                <Checkbox
                                    name="authorized-confirm"
                                    value={authorizedConfirm}
                                    onChange={onAuthorizedConfirmChange}
                                    required
                                />
                                <label htmlFor="authorized-confirm" className="privacy-button">
                                    I confirm I received this code from my organisation and am
                                    authorised to complete this assessment
                                </label>
                            </div>
                            <div className="flex-row align-center radio-wrapper">
                                <Checkbox
                                    name="privacy-agree"
                                    value={privacyAgree}
                                    onChange={onPrivacyAgreeChange}
                                    required
                                />
                                <label htmlFor="privacy-agree" className="privacy-button">
                                    I agree to the &nbsp;
                                    <a
                                        href="https://www.87percent.co.uk/privacy-policy"
                                        className="privacy-link"
                                    >
                                        Privacy Policy
                                    </a>
                                </label>
                            </div>
                            <div className="rodent"></div>
                        </div>
                    </Form>
                </div>
                <div className="align-center privacy">
                    <div className="flex-row items">
                        <div className="left-footer wrapper">
                            <p className="align-centre title">
                                <img
                                    src={Shield}
                                    alt="Privacy Shield"
                                    className="align-centre shield"
                                />
                                Your Privacy is our top priority
                            </p>
                            <br />
                            <p>
                                All the data provided by you is strictly confidential. 87% will
                                store and analyse your data securely and your personal data will
                                never be passed on to your organisation or employer.
                            </p>
                        </div>
                        <div className="right-footer wrapper">
                            <p className="title">
                                Looking for support? Take a look at what's available to you.
                            </p>
                            <div className="support-link">
                                <a href="/support">Support Services</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-row justify-center copyright">
                    <p className="sub-text">Copyright @ 2018-2022 87% Ltd. All rights reserved.</p>
                </div>
            </div>
        </div>
    );
};

export default Home;
