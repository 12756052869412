import React from 'react';
import { useTranslation } from 'react-i18next';
import ResultsBreakdownTable from '@pages/home/questionnaire/results/ResultsBreakdownTable';
import ResultsBreakdownContent from '@pages/home/questionnaire/results/ResultsBreakdownContent';

import { ResultDimension } from '../../../../types/shared';

const BreakdownItem = ({ index, dimension, dimensionRatingClass, dimensionRating }: Props) => {
    const { t } = useTranslation(['pages']);
    return (
        <div className="breakdown-item">
            <div className="flex-row justify-between">
                <ResultsBreakdownTable dimensionIndex={index} selected={dimension} />
                <div className={`rating ${dimensionRatingClass}`}>
                    {t(
                        `results.resultsViewer.${dimensionRating
                            .replace(/\s+/g, '')
                            .toLowerCase()}`,
                    )}
                </div>
            </div>
            <ResultsBreakdownContent dimension={dimension} />
        </div>
    );
};

interface Props {
    index: number;
    dimension: ResultDimension;
    dimensionRatingClass: string;
    dimensionRating: string;
}
export default BreakdownItem;
