import TextArea from 'lib/src/components/form/TextArea';
import { SelectedAnswer } from '../../../../../../types/shared';
import { useEffect } from 'react';

const DimensionTextInputQuestion = ({ answer, handleChange, questionID, isActive }: IProps) => {
    useEffect(() => {
        if (answer?.answer) {
            handleChange(questionID, answer?.answer);
        } else if (isActive) {
            handleChange(questionID, '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive]);

    if (!answer) return null;

    return (
        <div className="dimension-answer large">
            <TextArea
                name="answer"
                value={answer?.answer || ''}
                onChange={(_, val) => handleChange(answer.questionID, val)}
                placeholder="Answer"
            />
        </div>
    );
};

interface IProps {
    answer: SelectedAnswer | undefined;
    handleChange: (questionID: number, value: string) => void;
    questionID: number;
    isActive: boolean;
}

export default DimensionTextInputQuestion;
