export const externalUrls = {
    main: 'https://www.87percent.co.uk',
    faq: 'https://www.87percent.co.uk/faqs',
    mailto: 'mailto:privacy@87percent.me',
    mail: 'privacy@87percent.me',
    ico: 'https://ico.org.uk/',
    mind: 'https://www.mind.org.uk/information-support/',
    samaritans: 'https://www.samaritans.org/',
    nhs: 'https://www.nhs.uk/mental-health/',
};
