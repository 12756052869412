/**
 * Footer State for Toggling
 * Mirrors header.ts
 */

import { setFooterState } from '@actions/footer/toggleFooter';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

export type FooterState = boolean;

const initialState: FooterState = false;

export default createReducer(initialState, {
    [setFooterState.type]: handleSetFooterState,
});

function handleSetFooterState(state: FooterState, action: PayloadAction<boolean>) {
    state = action.payload;
    return state;
}
