import { fetchResults } from '@actions/results/getResults';
import { getQuestionsData } from '@selectors/questions';
import {
    getResultsFetchError,
    getResultsFetchSuccess,
    getResultsIsFetching,
} from '@selectors/results';
import { useDispatch, useSelector } from 'react-redux';

const useGetResults = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getResultsIsFetching);
    const fetchSuccess = useSelector(getResultsFetchSuccess);
    const fetchError = useSelector(getResultsFetchError);
    const data = useSelector(getQuestionsData);

    const { resumeCode } = data || {};

    const getResults = () => !!resumeCode && dispatch(fetchResults(resumeCode));

    return { isFetching, fetchSuccess, fetchError, getResults, data };
};

export default useGetResults;
