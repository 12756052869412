import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { batch } from 'react-redux';

import useNextIncompleteQuestion from './hooks/useNextIncompleteQuestion';

import { Carousel } from 'react-responsive-carousel';
import {
    DimensionAnswer,
    DimensionQuestion,
    DimensionQuestionTypes,
    EmptyAnswer,
    SelectedAnswer,
} from '../../../../../types/shared';
import DimensionDropdownQuestion from '@pages/home/questionnaire/sevenDimensions/single/questionComponents/DimensionDropdownQuestion';
import DimensionRadioQuestion from '@pages/home/questionnaire/sevenDimensions/single/questionComponents/DimensionRadioQuestion';
import DimensionSliderQuestion from '@pages/home/questionnaire/sevenDimensions/single/questionComponents/DimensionSliderQuestion';
import DimensionTextInputQuestion from '@pages/home/questionnaire/sevenDimensions/single/questionComponents/DimensionTextInputQuestion';
import DimensionMultiSelectQuestion from '@pages/home/questionnaire/sevenDimensions/single/questionComponents/DimensionMultiSelectQuestion';

const DimensionQuestionCarousel = ({
    dimensionQuestions,
    color,
    selectDimensionAnswer,
    selectedDimensionAnswers,
    resetSlide,
    setQuestionNumber,
    handleTextInputChange,
}: DimensionCarouselProps) => {
    const [slide, setSlide] = useState<number | null>(null);
    useEffect(() => {
        if (resetSlide) {
            setSlide(null);
        }
    }, [resetSlide]);
    const { getNextQuestion } = useNextIncompleteQuestion(
        dimensionQuestions || [],
        selectedDimensionAnswers,
        slide,
    );
    useEffect(() => {
        if (slide === null) {
            const nextQuestionIndex = getNextQuestion();
            setSlide(nextQuestionIndex);
            setQuestionNumber(nextQuestionIndex);
        }
    }, [getNextQuestion, setQuestionNumber, slide]);

    if (!dimensionQuestions?.length || !dimensionQuestions) return null;

    return (
        <div className="carousel-container">
            <Carousel
                showThumbs={false}
                showStatus={false}
                centerMode={true}
                showArrows={true}
                centerSlidePercentage={100}
                selectedItem={slide ?? 0}
                autoPlay={false}
                swipeable={false}
                renderIndicator={() => {
                    return null;
                }}
                onChange={i => setSlide(i)}
            >
                {dimensionQuestions.map(({ id, text, answers, answerType }, index) => {
                    const isActive = index === slide;
                    const selectedAnswer = selectedDimensionAnswers.find(
                        answer => answer.questionID === id,
                    );
                    const selectedAnswers = selectedDimensionAnswers.filter(
                        answer => answer.questionID === id,
                    );

                    const handleSelect = (
                        answer: DimensionAnswer | DimensionAnswer[] | EmptyAnswer[],
                    ) => {
                        handleAnswerSelect(id, answer, answerType);
                    };

                    const sortedAnswers = [...answers].sort((a, b) => a.sort - b.sort);

                    return (
                        <div
                            key={id}
                            className="container carousel-item flex-column justify-center"
                        >
                            <div className="content-wrapper">
                                <p>{text}</p>
                            </div>

                            <RenderAnswer
                                answerType={answerType}
                                answers={sortedAnswers}
                                selectedAnswer={selectedAnswer}
                                selectedAnswers={selectedAnswers}
                                handleAnswerSelect={handleSelect}
                                color={color}
                                isActive={isActive}
                                handleTextInputChange={handleTextInputChange}
                                questionID={id}
                            />
                        </div>
                    );
                })}
            </Carousel>
            <div className="question-count flex-row justify-center">
                <p>{`${(slide ?? 0) + 1} of ${dimensionQuestions.length}`}</p>
            </div>
        </div>
    );

    function handleAnswerSelect(
        questionID: number,
        answer: DimensionAnswer | DimensionAnswer[] | EmptyAnswer[],
        answerType: DimensionQuestionTypes,
    ) {
        selectDimensionAnswer(questionID, answer);

        if (dimensionQuestions?.length) {
            const nextSlide = getNextQuestion(questionID);
            batch(() => {
                if (
                    answerType === DimensionQuestionTypes.Slider ||
                    answerType === DimensionQuestionTypes.MultiSelect
                ) {
                    setQuestionNumber(nextSlide);
                    return;
                }
                setSlide(nextSlide);
                setQuestionNumber(nextSlide);
            });
        }
    }
};

function RenderAnswer({
    answerType,
    answers,
    selectedAnswer,
    selectedAnswers,
    handleAnswerSelect,
    isActive,
    color,
    handleTextInputChange,
    questionID,
}: IRenderAnswerProps) {
    switch (answerType) {
        case DimensionQuestionTypes.Dropdown:
            return (
                <DimensionDropdownQuestion
                    answers={answers}
                    selectedAnswer={selectedAnswer}
                    handleSelect={handleAnswerSelect}
                />
            );
        case DimensionQuestionTypes.Radio:
            return (
                <DimensionRadioQuestion
                    answers={answers}
                    selectedAnswer={selectedAnswer}
                    handleSelect={handleAnswerSelect}
                    isActive={isActive}
                    color={color}
                />
            );
        case DimensionQuestionTypes.Slider:
            return (
                <DimensionSliderQuestion
                    answers={answers}
                    selectedAnswer={selectedAnswer}
                    handleSelect={handleAnswerSelect}
                />
            );
        case DimensionQuestionTypes.TextInput:
            return (
                <DimensionTextInputQuestion
                    answer={selectedAnswer}
                    handleChange={handleTextInputChange}
                    questionID={questionID}
                    isActive={isActive}
                />
            );
        case DimensionQuestionTypes.MultiSelect:
            return (
                <DimensionMultiSelectQuestion
                    answers={answers}
                    selectedAnswers={selectedAnswers}
                    handleSelect={handleAnswerSelect}
                    questionID={questionID}
                    isActive={isActive}
                />
            );
        default:
            return null;
    }
}

interface DimensionCarouselProps {
    dimensionQuestions: DimensionQuestion[] | undefined;
    color: string;
    selectDimensionAnswer: (
        questionID: number,
        answers: DimensionAnswer | DimensionAnswer[] | EmptyAnswer[] | null,
    ) => void;
    selectedDimensionAnswers: SelectedAnswer[];
    resetSlide: boolean;
    setQuestionNumber: Dispatch<SetStateAction<number>>;
    questionNumber: number;
    handleTextInputChange: (questionID: number, value: string) => void;
}

interface IRenderAnswerProps {
    answerType: DimensionQuestionTypes;
    answers: DimensionAnswer[];
    selectedAnswer: SelectedAnswer | undefined;
    selectedAnswers: SelectedAnswer[] | undefined;
    handleAnswerSelect: (answer: DimensionAnswer | DimensionAnswer[] | EmptyAnswer[]) => void;
    isActive: boolean;
    color: string;
    handleTextInputChange: (questionID: number, value: string) => void;
    questionID: number;
}

export default DimensionQuestionCarousel;
