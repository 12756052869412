import { useSelector } from 'react-redux';

// Move import of main.svg (duplicated why?!) from lib
// to be within src for module scope plugin
import logo from '../../_content/images/logos/main.svg';
import { getHeaderState } from '@selectors/header';
import { getCompanyLogo } from '@selectors/questions';
import { selectCurrentPage } from '@selectors/pages';

import config from '../../config';
import { QuestionnairePages } from '../../types/shared/QuestionnairePages';
const { S3_URL } = config;

const Header = () => {
    const headerState = useSelector(getHeaderState);
    const companyLogo = useSelector(getCompanyLogo);
    const page = useSelector(selectCurrentPage);
    const leftLogo = page === +QuestionnairePages.results;

    if (!headerState) return null;

    const logoURL = companyLogo ? `${S3_URL}/${companyLogo}` : logo;

    return (
        <header className={`header display-flex justify-${leftLogo ? 'start' : 'center'}`}>
            <div className="logo flex-row align-center">
                <a href="/">
                    <img alt="logo" src={logoURL} />
                </a>
            </div>
        </header>
    );
};

export default Header;
