import ActionButton from 'lib/src/components/button/ActionButton';
interface Props {
    setIsLogin: (bool: boolean) => void;
}

const Initial = ({ setIsLogin }: Props) => {
    const handleClick = () => {
        setIsLogin(true);
    };

    return (
        <div id="home-page">
            <div className="home-background">
                <div className="container banner">
                    <h1 className="flex start-screen-title">
                        Improving the mental wellbeing of society percent by percent
                    </h1>
                </div>
                <div className="container banner">
                    <h2 className="start-screen-subtitle">
                        We help your organisation understand and support your mental fitness
                    </h2>
                    <ActionButton
                        color="gold"
                        variant="flat"
                        type="button"
                        onClick={() => handleClick()}
                    >
                        Get started
                    </ActionButton>
                </div>
            </div>
        </div>
    );
};

export default Initial;
