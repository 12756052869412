import React, { useMemo } from 'react';
import { dimensionColours } from '../../constants/dimensions';
import { ResultDimension } from '../../types/shared';
import { getRatingClassFromBracket } from '../../utils';

interface Props {
    dimensions: ResultDimension[];
    score: number | null;
}

const getScore = (score: number) => ({
    strokeDashoffset: `calc((100% * 2.9333) - ((100% * 2.9333) * ${score}) / 100)`,
});

const getScoreClass = (dimension: ResultDimension): string => {
    const scoreBracket = dimension.scoreBracket;
    return !scoreBracket ? '' : getRatingClassFromBracket(scoreBracket);
};

const regexNoWhitespace = /(\s+)/;
const regexMaxStrLength = /.{1,10}/g;

const removeWhitespace = (str: string | null): string[] => str?.split(regexNoWhitespace) ?? [];
const truncateLongText = (strArr: string[]): string[] =>
    strArr.map(x => removeWhitespace(x?.match(regexMaxStrLength)?.join('- ') ?? '') ?? ['']).flat();

const SplitText = (content: string | null) =>
    truncateLongText(removeWhitespace(content)).map((str, i) => {
        return regexNoWhitespace.test(str) ? (
            <React.Fragment key={i}>
                {str}
                <br />
            </React.Fragment>
        ) : (
            <React.Fragment key={i}>{str}</React.Fragment>
        );
    }) ?? [<React.Fragment key={0}></React.Fragment>];

const SplitTextMapper = (dimensions: ResultDimension[]) =>
    dimensions.map(x => SplitText(x.dimensionName));

export default function ScoreRings({ dimensions, score }: Props) {
    const widthOfPage = 750;
    const widthOfRing = 90;
    const getMaxSize = widthOfPage / (widthOfRing + 10);
    const scale = Math.min(1, getMaxSize / dimensions.length);
    const newWidth = Math.min(widthOfRing, widthOfRing * scale + 17);
    const dimensionNames = useMemo(() => SplitTextMapper(dimensions), [dimensions]);
    return (
        <div className="score-container">
            {score &&
                dimensions.map((d, i) => {
                    return (
                        <div className="percent" key={i} style={{ width: newWidth }}>
                            <svg width="100" height="150" transform={`scale(${scale})`}>
                                <circle className="score-bg" cx="42" cy="42" r="42"></circle>
                                <circle className="score-ring-bg" cx="42" cy="42" r="42"></circle>
                                <circle
                                    className={`score-ring ${getScoreClass(d)}`}
                                    cx="42"
                                    cy="42"
                                    r="42"
                                    style={getScore(d?.dimensionScore ?? 0)}
                                ></circle>
                                <text
                                    className="number"
                                    textAnchor="middle"
                                    transform="translate(45, 54) scale(1)"
                                >
                                    {d?.dimensionScore ? Math.round(d.dimensionScore) : ''}
                                </text>
                            </svg>
                            <h4
                                className={`score-ring-title dimension-${dimensionColours}`}
                                style={{ top: 89 }}
                            >
                                {dimensionNames[i]}
                            </h4>
                        </div>
                    );
                })}
        </div>
    );
}
