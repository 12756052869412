import { Dimension, Dimensions, SelectedAnswer } from 'src/types/shared/QuestionData';

const useCompleted = (dimensions: Dimensions, dimensionAnswers: SelectedAnswer[]) => {
    const answersDict = dimensionAnswers.reduce((acc, answer) => {
        acc[answer.questionID] = answer;
        return acc;
    }, {} as Record<number, SelectedAnswer>);

    const completed = dimensions.reduce(
        (accum, dimension) => ({
            ...accum,
            [dimension.sort]: isDimensionComplete(dimension, answersDict),
        }),
        {} as Record<number, boolean>,
    );

    return completed;
};

const isDimensionComplete = (dimension: Dimension, answersDict: Record<number, SelectedAnswer>) => {
    return !!dimension.questions.length && dimension.questions.every(({ id }) => !!answersDict[id]);
};

export default useCompleted;
