import { useEffect } from 'react';

import { DimensionAnswer, EmptyAnswer, SelectedAnswer } from '../../../../../../types/shared';
import MultiSelect from 'lib/src/components/form/MultiSelect';

const DimensionMultiSelectQuestion = ({
    answers,
    selectedAnswers,
    handleSelect,
    questionID,
    isActive,
}: IProps) => {
    useEffect(() => {
        if (isActive && !selectedAnswers?.length) {
            handleSelect([{ questionID, id: null, score: null }]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, selectedAnswers]);

    const handleAnswerSelect = (answerIDs: number[]) => {
        const selectedAnswers = answers.filter(answer => answerIDs.includes(answer.id));

        handleSelect(selectedAnswers);
    };

    const filteredAnswers =
        selectedAnswers?.reduce((acc: number[], answer) => {
            if (answer.answerID !== null && !!answer.answerID) {
                acc.push(answer.answerID);
            }

            return acc;
        }, []) || [];

    return (
        <div className="dimension-answer">
            <MultiSelect
                name="answer"
                value={filteredAnswers}
                onChange={(_, value) => handleAnswerSelect(value)}
                options={answers.map(answer => ({
                    value: answer.id,
                    label: answer.text,
                }))}
            />
        </div>
    );
};
interface IProps {
    answers: DimensionAnswer[];
    selectedAnswers: SelectedAnswer[] | undefined;
    handleSelect: (answers: DimensionAnswer[] | EmptyAnswer[]) => void;
    questionID: number;
    isActive: boolean;
}

export default DimensionMultiSelectQuestion;
