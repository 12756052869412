import { useState } from 'react';
import { DimensionAnswer, EmptyAnswer, SelectedAnswer } from 'src/types/shared/QuestionData';

const useAnswerHandler = (answers: SelectedAnswer[]) => {
    const [selectedAnswers, setSelectedAnswers] = useState<SelectedAnswer[]>(answers);

    const selectAnswer = (
        questionID: number,
        answers: DimensionAnswer | DimensionAnswer[] | EmptyAnswer[] | null,
    ) => {
        setSelectedAnswers(selected => {
            let newSelectedAnswers = selected.filter(
                selectedAnswer => selectedAnswer.questionID !== questionID,
            );
            if (Array.isArray(answers)) {
                answers.forEach(({ id, score }) =>
                    newSelectedAnswers.push({ questionID, answerID: id, score: score }),
                );
            } else {
                if (!!answers) {
                    newSelectedAnswers.push({
                        questionID,
                        answerID: answers.id,
                        score: answers.score,
                    });
                }
            }
            return newSelectedAnswers;
        });
    };

    const handleTextInputChange = (questionID: number, answer: string) => {
        setSelectedAnswers(selected => {
            let newSelectedAnswers = selected.filter(
                selectedAnswer => selectedAnswer.questionID !== questionID,
            );

            newSelectedAnswers.push({ questionID, answer });
            return newSelectedAnswers;
        });
    };

    return { selectedAnswers, selectAnswer, handleTextInputChange };
};

export default useAnswerHandler;
