import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useDownloadPDF from '@pages/home/questionnaire/results/hooks/useDownloadPDF';
import usePageLeaveWarn from '../../../../../src/hooks/usePageLeaveWarn';
import usePrinter from './hooks/usePrinter';

import { getResultsData, getResultsIsFetching } from '@selectors/results';

import Recommendations from './Recommendations';
import ReflectionBanner from './ReflectionBanner';
import ResultsViewer from './ResultsViewer';
import ScoreBanner from './ScoreBanner';
import Loading from '@components/loader/Loading';
import CouldImproveMessage from '@pages/home/questionnaire/results/CouldImproveMessage';
import ResultsFooter from '@pages/home/questionnaire/results/ResultsFooter';

interface Props {
    onComplete?: () => void;
}

export default function Results({ onComplete }: Props) {
    const resultsData = useSelector(getResultsData);
    const isFetching = useSelector(getResultsIsFetching);
    const { t } = useTranslation(['pages']);

    const { print } = usePrinter();
    const { handleDownload, isFetching: isDownloading } = useDownloadPDF();
    usePageLeaveWarn();

    if (!resultsData) {
        return isFetching ? <Loading /> : <div>No results found</div>;
    }

    const { strengths, weaknesses, dimensions, recommendations, score, couldImproveMessage } =
        resultsData;

    return (
        <div id="results-page">
            <ScoreBanner score={score} />
            <ResultsViewer dimensions={dimensions} score={score} />
            {!!couldImproveMessage && (
                <CouldImproveMessage couldImproveMessage={couldImproveMessage} />
            )}
            <ReflectionBanner
                title={t('results.reflectionBanner.yourAreasOfStrengthAndDifficulty')}
                strengths={strengths}
                difficulties={weaknesses}
            />
            <Recommendations content={recommendations} />

            <ResultsFooter
                print={print}
                handleDownload={handleDownload}
                isDownloading={isDownloading}
                onComplete={onComplete}
            />
        </div>
    );
}
