import { RootState } from '@reducers/index';
import QuestionData from 'src/types/shared/QuestionData';

export const getQuestionsIsFetching = (state: RootState): boolean =>
    state.questionsReducer.isFetching;

export const getQuestionsFetchSuccess = (state: RootState): boolean =>
    state.questionsReducer.fetchSuccess;

export const getQuestionsFetchError = (state: RootState): string | null =>
    state.questionsReducer.error;

export const getQuestionsData = (state: RootState): QuestionData | null =>
    state.questionsReducer.data;

export const getCompanyLogo = (state: RootState): string | null =>
    state.questionsReducer.data?.companyImageS3Key || null;

export const getQuestionsIsPosting = (state: RootState) => state.questionsReducer.isPosting;
export const getQuestionsPostSuccess = (state: RootState) => state.questionsReducer.postSuccess;
export const getResumeCode = (state: RootState) => state.questionsReducer.data?.resumeCode;

export const getDimensionQuestions = (state: RootState, dimension: number) =>
    state.questionsReducer.data?.dimensions[dimension];

export const getCurrentOnboardingSection = (state: RootState) =>
    state.questionsReducer.currentOnboardingSection;

export const getDimensions = (state: RootState) => state.questionsReducer.data?.dimensions;
