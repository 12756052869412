import QuestionData from 'src/types/shared/QuestionData';
export default function useDimensionResume({ dimensions, dimensionAnswers }: QuestionData) {
    const answerCount = dimensionAnswers.length;
    let currentCount = 0;
    let i = 0;

    for (let dimension of dimensions) {
        let dimensionQuestionCount = dimension.questions.length;
        if (answerCount < dimensionQuestionCount + currentCount) {
            break;
        }
        i++;
        currentCount = currentCount + dimensionQuestionCount;
    }

    return i;
}
