import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dimensionColours } from '../../../../constants/dimensions';

interface Props {
    content: { title: string; content: string; summary: string }[];
}

export default function Recommendations({ content }: Props) {
    const { t } = useTranslation(['pages']);
    const [selectedTitle, setSelectedTitle] = useState(0);

    return (
        <div className="recommendations container">
            <h3 className="head">{t('results.recommendations.title')}</h3>
            <p className="desc">{t('results.recommendations.subTitle')}</p>
            <div className="body flex-row list-wrapper">
                <div className="flex-column interactive-bullets flex-4">
                    {content.map(({ title }, i) => {
                        const dimensionColourNumber =
                            i >= 10 ? Number(i.toString().slice(-1)) + 1 : i + 1;

                        return (
                            <div
                                key={i}
                                className={`flex-row align-center recommendation dimension-${
                                    dimensionColours[dimensionColourNumber]
                                } ${selectedTitle === i ? 'selected' : ''}`}
                            >
                                <div className="marker">
                                    <div className="marker-inner" />
                                </div>

                                <li key={i} onClick={() => setSelectedTitle(i)}>
                                    {title}
                                </li>
                            </div>
                        );
                    })}
                </div>
                <div className="flex-column flex-8">
                    <div className="content">
                        {content.map(
                            ({ summary }, i) =>
                                selectedTitle === i && (
                                    <div key={i} className="margin-bottom">
                                        <h4 className="title">
                                            {t('results.recommendations.titleLeft')}
                                        </h4>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: summary }}
                                            className="wysiwyg-recommendations"
                                        />
                                    </div>
                                ),
                        )}
                    </div>
                    <div className="content">
                        {content.map(
                            ({ content }, i) =>
                                selectedTitle === i && (
                                    <div key={i} className="margin-bottom">
                                        <h4 className="title">
                                            {t('results.recommendations.titleRight')}
                                        </h4>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: content }}
                                            className="wysiwyg-recommendations"
                                        />
                                    </div>
                                ),
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
