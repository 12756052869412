import Title from '@components/title/Title';
import { externalUrls } from '../../../src/utils/constants';
// Deprecated in favour of https://www.87percent.co.uk/privacy-policy
const Privacy = () => {
    return (
        <div id="privacy-page" className="page-padding">
            <Title title="Privacy Policy" />

            <section className="flex-column align-center privacy-main-wrapper">
                <p className="privacy-policy">
                    This policy was last updated on 17th February 2020.
                </p>
                <br />
                <p>
                    87% takes the privacy of your information very seriously. This privacy policy
                    applies to our use of any and all Personal Data collected by us or provided by
                    you in relation to your use of our Solution and tells you about your privacy
                    rights and how the law protects you. Please read this privacy policy carefully.
                </p>
                <br />
                <p>
                    The Privacy Policy is intended to meet our duties of Transparency under the
                    “General Data Protection Regulation” or “GDPR”. We will post any modifications
                    or changes to this Privacy Policy on this page.
                </p>
                <br />
                <p>
                    We will post any modifications or changes to this Privacy Policy on this page.
                </p>
                <br />
                <h3>Who we are and contacting us</h3>

                <p>
                    87% Limited (<strong>“87%”, “we”, “us”, “our”</strong>) is a company
                    incorporated in England and Wales (registration number 11020037), whose
                    registered office is at 2 Stone Buildings, Lincoln’s Inn, London, WC2A 3TH.
                </p>
                <br />
                <p>
                    We are the Controller (for the purposes of the GDPR) of your Personal Data. This
                    means we are in charge for determining the types, means and purposes for which
                    we collect your Personal Data.
                </p>
                <br />
                <p>
                    The GDPR requires us to appoint a “Data Protection Officer”, this is a person
                    who is responsible for overseeing and advising us in relation to our compliance
                    with the GDPR (including compliance with the practices described in this Privacy
                    Policy). If you want to contact our Data Protection Officer directly, you can
                    email:&nbsp;
                    <a href={externalUrls.mailto}>{externalUrls.mail}</a>.
                </p>
                <br />
                <h3>Definitions and interpretation</h3>
                <p>In this privacy policy, the following definitions are used:</p>
                <br />
                <p>
                    <strong>Cookies:</strong> A small text file placed on your computer by this
                    Website when you visit certain parts of the Website and/or when you use certain
                    features of the Website. Details of the cookies used by this Website are set out
                    in the clause below (Cookies);
                </p>
                <br />
                <p>
                    <strong>Data Protection Laws:</strong> Any applicable law relating to the
                    processing of personal Data, including but not limited to the GDPR, the Data
                    Protection Act 2018 and any national implementing laws, regulations and
                    secondary legislation;
                </p>
                <br />
                <p>
                    <strong>Employer:</strong> Any business representative of a third party that
                    accesses and uses the Solution as an employer in relation to their employees
                    (who will be a “User”) in the course of their business. For the avoidance of
                    doubt, this will exclude any parties who are (i) employed by 87% and acting in
                    the course of their employment or (ii) engaged as a consultant or otherwise
                    providing services to 87% and accessing the Solution in connection with the
                    provision of such services.
                </p>
                <br />
                <p>
                    <strong>GDPR:</strong> The General Data Protection Regulation (EU) 2016/679;
                </p>
                <br />
                <p>
                    <strong>Personal Data:</strong> Collectively all personally identifying
                    information about you that you submit to 87% Limited via the Solution which
                    constitute Personal Data and Special Categories of Personal Data as those terms
                    are defined within the Data Protection Laws;
                </p>
                <br />
                <p>
                    <strong>Solution:</strong> The 87% platform, app or website that you are
                    currently using (
                    <a href={externalUrls.main} target="_blank" rel="noreferrer">
                        {externalUrls.main}
                    </a>
                    ) (the <strong>“Website”</strong>), and any sub-domains of the Website unless
                    expressly excluded by their own terms and conditions;
                </p>
                <br />
                <p>
                    <strong>User or you:</strong> Any individual employee that accesses the Solution
                    following an invitation sent on behalf of their Employer and is not either (i)
                    employed by 87% and acting in the course of their employment,(ii) engaged as a
                    consultant or otherwise providing services to 87% and accessing the Solution in
                    connection with the provision of such services .
                </p>
                <br />
                <p>
                    <strong>
                        In this privacy policy, unless the context requires a different
                        interpretation:
                    </strong>
                </p>
                <ol>
                    <li>The singular includes the plural and vice versa;</li>
                    <li>
                        A reference to a person includes firms, companies, government entities,
                        trusts and partnerships;
                    </li>
                    <li>"Including" is understood to mean "including without limitation"</li>
                    <li>
                        Reference to any statutory provision includes any modification or amendment
                        of it
                    </li>
                    <li>The headings and sub-headings do not form part of this privacy policy.</li>
                </ol>
                <h3>What Personal Data we collect</h3>
                <p>
                    The GDPR definitions of Personal Data and Special Categories of Personal Data
                    can be found here.
                </p>
                <br />
                <p>
                    We collect the following categories of Personal Data about Users and Employers:
                </p>
                <br />
                <ul>
                    <li>
                        <strong>Identity Data:</strong> First name last name, email, account
                        usernames, the unique invitation code assigned to each invited User of the
                        Solution
                    </li>
                    <li>
                        <strong>Contact Data:</strong>
                        Email address
                    </li>
                    <li>
                        <strong>Communications Data:</strong> Your preferences for communication
                    </li>
                    <li>
                        <strong>Technical Data:</strong> Internet protocol (IP) address, your login
                        data, browser type and version, time zone setting and location, browser
                        plug-in types and versions, operating system and platform and other
                        technology on the devices you use to access this website or use our
                        services.
                    </li>
                </ul>
                <p>
                    As well as the above, we also collect the following categories of Personal Data
                    about Users:
                </p>
                <ul>
                    <li>
                        <strong>Behavioural Data:</strong> Inferred or assumed information relating
                        to your behaviour and interests, based on your online activity (i.e. which
                        features and articles on the Solution you use the most).
                    </li>

                    <li>
                        <strong>Profile Data:</strong> Personal Data, including data regarding your
                        physical and mental wellbeing, which may be inferred from your dimension
                        test results.
                    </li>

                    <li>
                        <strong>User-Generated Data:</strong> Personal Data you may include in your
                        user-generated content (i.e. information and content that you submit to the
                        Solution), for example, answers to questions about your mental wellbeing or
                        questions and comments in the community section.
                    </li>

                    <li>
                        <strong>Personalisation Data (Optional):</strong> Date of birth, Gender,
                        Ethnicity, Career Responsibility and details regarding whether you are a
                        Carer and/or Parent. We’ll use this information to tailor your experience so
                        that it’s personal to you and show you more about what you care about, and
                        less about what you don’t.[1]
                    </li>

                    <li>
                        <strong>Employer-Provided Data:</strong> Any Personal Data which your
                        Employer chooses to share with us in connection with their use of the
                        Solution. This may include:
                        <ul>
                            <li>
                                Your name and email (for the purposes of sending you an initial
                                invitation and access code to the Solution);
                            </li>

                            <li>
                                Your phone number, gender, date of birth, location and reporting
                                entity (which your Employer may have chosen to provide us);
                            </li>

                            <li>
                                Any other types of Personal Data that your Employer has chosen to
                                provide us at their discretion, based on their Solution requirements
                                for their organisation.
                            </li>
                        </ul>
                    </li>
                </ul>
                <p>
                    To use all features and functions available on the Solution, it may be mandatory
                    for you to submit certain types of Personal Data (in particular Identity and
                    Contact Data).
                </p>

                <h3>How we collect your Personal Data</h3>
                <p>87% collects your Personal Data in a number of ways:</p>
                <br />
                <ul>
                    <li>
                        <strong> Data that is given to us by you </strong>
                        <ul>
                            <li>
                                When you contact us through our Solution, by telephone, post, email,
                                contact form, or through any other means;
                            </li>

                            <li>
                                When you make payments to us, through this Website or otherwise;
                            </li>

                            <li>When you elect to receive marketing communications from us;</li>

                            <li>When you use our Solution;</li>

                            <li>In each case, in accordance with this privacy policy.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Data that is given to us by your Employer</strong>
                    </li>
                    <li>
                        <strong>Data that is collected automatically</strong>
                    </li>
                </ul>
                <p>
                    To the extent that you access the Solution, we will collect your Personal Data
                    automatically, for example:
                </p>
                <ul>
                    <li>
                        We automatically collect some information about your visit to the Solution.
                        This information helps us to make improvements to the Solution, content and
                        navigation, and includes your IP address, the date, times and frequency with
                        which you access the Solution and the way you use and interact with its
                        content.
                    </li>
                    <li>
                        We will collect your Personal Data automatically via cookies, in line with
                        the cookie settings on your browser. For more information about cookies, and
                        how we use them on the Website, see our Cookie Policy&nbsp;
                        <a
                            href="https://www.87percent.co.uk/cookies-policy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.87percent.co.uk/cookies-policy
                        </a>
                        .
                    </li>
                </ul>
                <h3>Who we share your Personal Data with</h3>
                <p>We may disclose your Personal Data with the following third parties:</p>
                <br />
                <ul>
                    <li>
                        Our third party providers of our business and operational services. These
                        parties are authorised to process your Personal Data on our behalf and
                        pursuant to our instructions, and only as necessary to provide the Solution.
                        These include our providers of:
                        <ul>
                            <li>
                                Payment processing and accounting services, as necessary to process
                                payments and accounting obligations
                            </li>

                            <li>
                                App development services for updating and enhancing our app and
                                technical service offering
                            </li>

                            <li>
                                Analytical services (such as Mix Panel) to understand and improve
                                the performance of the different features of the Solution
                            </li>

                            <li>
                                Technology platform hosting and support services (such as Amazon Web
                                Services) who host our apps and web services
                            </li>

                            <li>
                                Research services (please indicate why you use Research services)
                            </li>
                        </ul>
                    </li>
                    <li>
                        Any potential or actual third party buyer of our business and/or assets in
                        the event that we sell, trade or licence ownership of any part of the 87%
                        business or assets (including management of any part of the Solution
                        Solution). Personal Data provided by Users will, where it is relevant to any
                        part of our business so transferred, be transferred along with that part and
                        the new owner or newly controlling party will, under the terms of this
                        privacy policy, be permitted to use the Personal Data for the purposes for
                        which it was originally supplied to us.
                    </li>
                    <li>
                        Third parties we may be required to disclose such personal data to in order
                        to comply with our legal obligations, to enforce our legal rights or to
                        protect our users, e.g. any relevant authority or enforcement body and fraud
                        protection.
                    </li>
                </ul>
                <h3>Aggregated Data</h3>
                <p>
                    We also collect, use and share <strong>“Aggregated Data”</strong> such as
                    statistical or demographic data for any purpose. Aggregated Data may be derived
                    from your Personal Data, but once in aggregated form it will not constitute
                    Personal Data for the purposes of the GDPR as this data does not directly or
                    indirectly reveal your identity. For example, we may aggregate your 87% score to
                    create a benchmark for mental wellbeing or to identify the most popular content
                    within the Solution.
                </p>
                <br />
                <p>
                    We share certain Aggregated Data (i.e. anonymous data) with your Employer(s) –
                    for instance, they will be able to see the overall score in each dimension and
                    how that changes over time. Your Employer(s) will not have access to any
                    Personal Data that you submit to or that is generated through your use of the
                    Solution (in particular your User-Generated Data) as a direct consequence of
                    your use of the Solution. This exclusion does not apply to any Employer-Provided
                    Data or any Personal Data that your Employer may already possess in their course
                    of employing you independently of the Solution (such as Identity and Contact
                    Data).
                </p>
                <br />
                <p>
                    We may also disclose Aggregated Data to a prospective purchaser of our business
                    or any part of it.
                </p>
                <br />
                <p>
                    We also use such Aggregated Data to understand our end-users, to develop the 87%
                    offering, to publish white papers and to share the aggregated data with health
                    professionals and researchers. We may also use it to demonstrate the value of
                    our offering to others.
                </p>
                <br />
                <p>
                    In all the above instances, we will take reasonable steps to ensure your privacy
                    is protected.
                </p>
                <h3>Our use of your Personal Data </h3>
                <p>
                    We will only use your Personal Data if we have a permitted lawful basis to do
                    so. The primary purpose for which we collect information about you is to provide
                    you with the best possible service and experience when using the Solution. We
                    also process Personal Data about you for the following purposes under the
                    following legal bases:
                </p>
                <p>
                    <strong>
                        To enable you to use the solution and perform our contract with your
                        Employer
                    </strong>
                </p>
                <ul>
                    <li>
                        To create your User account or access to your Employer portal of the
                        Solution
                    </li>
                    <li>
                        To create, manage and save your progress in completing your User scores and
                        reports
                    </li>
                    <li>
                        To provide you with information, products or services that you request from
                        us. For Employers this will include the production of research and
                        analytical reports of employees based on their Aggregated Data.
                    </li>
                    <li>For handling contacts, queries, complaints or disputes</li>
                </ul>
                <p>
                    <strong>For our legitimate interests</strong>
                </p>
                <ul>
                    <li>To invite Users nominated by their Employer to sign up to the Solution</li>
                    <li>
                        To provide Users’ Employers with the services they request from us,
                        including the production of research and analytical reports of employees
                        based on their Aggregated Data.
                    </li>
                    <li>
                        For market research and analytical purposes, e.g. to improve our services
                        and understanding of market trends in the industry and offer these insights
                        to clients as part of our consultancy services. Any personal data used for
                        this purpose will strictly be anonymised and Aggregated Data only.
                    </li>
                    <li>Making important service communications about the Solution</li>
                    <li>
                        To contact you for market research purposes (whether you choose to
                        participate is entirely at your discretion)
                    </li>
                    <li>
                        To generally run the Solution and for internal operations in order to
                        provide you with an up to date, efficient and reliable service (including
                        internal record keeping)
                    </li>
                    <li>
                        To improve our existing services (including by tailoring your experience of
                        the Solution) and develop new ones.
                    </li>
                    <li>
                        For marketing our Solution (using Aggregated Data only unless we have
                        received your consent.
                    </li>
                </ul>
                <p>
                    <strong>To comply with our legal obligations</strong>
                </p>
                <ul>
                    <li>
                        To effectively handle any legal claims or regulatory enforcement actions
                        taken against us
                    </li>
                    <li>
                        To comply with our legal and regulatory obligations (including under
                        applicable Data Protection Laws)
                    </li>
                    <li>
                        For preventing, investigating and detecting crime, fraud or anti-social
                        behaviour and prosecuting offenders, including working with law enforcement
                        agencies
                    </li>
                    <li>To fulfil our duties to our employees and other stakeholders.</li>
                </ul>
                <p>
                    <strong>With your explicit consent</strong>
                </p>
                <ul>
                    <li>
                        To keep you informed of the latest news and offers regarding the Solution,
                        including through our weekly newsletter.
                    </li>
                    <li>
                        For marketing our Solution through testimonials and other promotional
                        materials.
                    </li>
                    <li>
                        If you are a User, for processing the following types of Special Categories
                        of Personal Data:
                        <ul>
                            <li>
                                Your ethnicity (where you have voluntarily provided this information
                                as Personalised Data), to tailor your experience
                            </li>
                            <li>
                                Any health data you may have volunteered on our community forums, to
                                enable you to participate in our forums
                            </li>
                            <li>
                                Profile Data arising from your test scores, based on the questions
                                you have answered regarding the dimensions on the Solution
                            </li>
                        </ul>
                    </li>
                </ul>
                <p>
                    Where we process your Personal Data on the basis of consent, you have the right
                    to withdraw your consent to these activities at any time, which will mean that
                    we will cease to use your Personal Data for these purposes. If you simply wish
                    to stop receiving marketing emails from us, you can do so by clicking the
                    “unsubscribe” link at the bottom of each email.
                </p>
                <h3>Transfers of Personal Data abroad</h3>
                <p>
                    The majority of our service providers required to process your Personal Data are
                    based in the UK/EEA (e.g. due to the location of you or your Employer) and will
                    be processing your Personal Data subject to the same data protection laws as us.
                </p>
                <p>
                    Where we do need to transfer your Personal Data in the future to any countries
                    outside the EEA, we ensure that such transfers are made subject to appropriate
                    safeguards as required by applicable data protection laws to ensure that a
                    similar degree of protection is afforded to your Personal Data, including by
                    ensuring that overseas recipients of your data have been self-certified under
                    the Privacy Shield framework, are located in a country deemed to hold adequacy
                    status or through the use of EU Commission approved standard contractual
                    clauses.
                </p>
                <p>
                    For more details on the safeguards in place for transfers of your Personal Data
                    abroad, including how to obtain copies of these where relevant, please contact
                    us at <a href={externalUrls.mailto}>{externalUrls.mail}</a>.
                </p>
                <h3>Storage of your Personal Data</h3>
                <p>
                    Unless a longer retention period is required or permitted by law (including to
                    satisfy any legal, accounting or reporting requirements), we will only hold your
                    Personal Data on our systems for the period necessary to fulfil the purposes
                    outlined in this privacy policy or until you request that the Personal Data be
                    deleted.
                </p>
                <p>
                    To determine the appropriate length of time for holding your Personal Data, we
                    consider the amount, nature, and sensitivity of the Personal Data, the potential
                    risk of harm, from unauthorized use or disclosure of your Personal Data, the
                    purpose for which we process your data and whether we can achieve those purposes
                    through other means, along with the applicable legal requirements.
                </p>
                <h3>Rights to and access of your Personal Data</h3>
                <p>You have the following rights in relation to your Personal Data:</p>
                <ul>
                    <li>
                        You have the right to request (i) copies of the Personal Data held by 87%
                        (where such Personal Data is held) at any time (ii) that we modify, update
                        or delete such information.
                    </li>
                    <li>
                        You have the right to have your Personal Data corrected if it is inaccurate
                        or incomplete.
                    </li>
                    <li>
                        You have the right to request that we delete or remove your Personal Data
                        from our systems.
                    </li>
                    <li>
                        You have the right to "block" us from using your Personal Data or limit the
                        way in which we can use it.
                    </li>
                    <li>
                        You have the right to request that we move, copy or transfer your Personal
                        Data.
                    </li>
                    <li>
                        You have the right to object to our use of your Personal Data including
                        where we use it for our legitimate interests.
                    </li>
                </ul>
                <p>
                    Where we are legally permitted to do so, we may refuse your request. If we
                    refuse your request, we will tell you the reasons why.
                </p>
                <p>
                    In general, it is important that the Personal Data we hold about you is accurate
                    and current. Please keep us informed if your Personal Data changes during the
                    period for which we hold it.
                </p>
                <h3>How to exercise your rights</h3>
                <p>
                    If you want to exercise any of the rights described above, please contact us
                    using <a href={externalUrls.mailto}>{externalUrls.mail}</a>.
                </p>
                <p>
                    Typically, you will not have to pay a fee to access your Personal Data (or to
                    exercise any of the other rights). However, except in relation to Consent
                    Withdrawal, we may charge a reasonable fee if your request is clearly unfounded,
                    repetitive or excessive, or, we may refuse to comply with your request in these
                    circumstances.
                </p>
                <p>
                    We may need to request specific information from you to help us confirm your
                    identity and ensure your right to access your Personal Data (or to exercise any
                    of your other rights). This is a security measure to ensure that Personal Data
                    is not disclosed to any person who has no right to receive it. We may also
                    contact you to ask you for further information in relation to your request to
                    speed up our response.
                </p>
                <p>
                    We try to respond to all legitimate requests within 30 days. Occasionally it may
                    take us longer than a month if your request is particularly complex or you have
                    made a number of requests. In this case, we will notify you and keep you
                    updated.
                </p>
                <h3>Complaints</h3>
                <p>
                    If, for any reason, you have a complaint about our handling of your Personal
                    Data, please contact the Data Protection Officer to discuss your concerns. They
                    can be contacted at <a href={externalUrls.mailto}>{externalUrls.mail}</a>.
                </p>
                <p>
                    Following this, if you are still dissatisfied, you have the right to contact
                    your local data protection supervisory authority, which in the UK is the
                    Information Commissioner’s Office (
                    <a href={externalUrls.ico} target="_blank" rel="noreferrer">
                        {externalUrls.ico}
                    </a>
                    ).
                </p>
                <h3>Keeping Data secure</h3>
                <p>
                    Data security is of great importance to 87% and to protect your Personal Data we
                    have put in place suitable physical, electronic and managerial procedures to
                    safeguard and secure Personal Data collected via our solution.
                </p>
                <p>
                    We will use technical and organisational measures to safeguard your Personal
                    Data, for example:
                </p>
                <ul>
                    <li>We store your Personal Data on secure servers</li>
                    <li>Data in transit and data at rest is encrypted</li>
                    <li>We have configured firewalls in our system</li>
                    <li>We have information security policies in place</li>
                    <li>We hold a Cyber Essentials+ certification</li>
                </ul>
                <p>
                    We endeavour to do our best to protect your Personal Data. However, transmission
                    of information over the internet is not entirely secure and is done at your own
                    risk. We cannot ensure the security of your Personal Data transmitted to the
                    Solution.
                </p>
                <p>
                    If password access is required for certain parts of the Solution, you are
                    responsible for keeping this password confidential.
                </p>
                <p>
                    If you suspect any misuse or loss or unauthorised access to your Personal Data,
                    please let us know immediately by contacting us via this email address:{' '}
                    <a href={externalUrls.mailto}>{externalUrls.mail}</a>.
                </p>
                <p>
                    For further details of our security measures, including details of the security
                    certifications we hold in place, please contact us at the details above.
                </p>
                <h3>Links to other websites</h3>
                <p>
                    Our Solution provides links to other websites. We have no control over such
                    websites and are not responsible for the content of these websites. This privacy
                    policy does not extend to your use of such websites. You are advised to read all
                    separate legal terms that may apply to these websites, including their privacy
                    and cookie policies prior to using them.
                </p>
                <p>
                    Articles contained within our Solution may also include embedded content (e.g.
                    videos, images, articles, etc.). Embedded content from other websites behaves in
                    the exact same way as if the visitor has visited the other website. These
                    websites may collect data about you, use cookies, embed additional third-party
                    tracking, and monitor your interaction with that embedded content, including
                    tracking your interaction with the embedded content if you have an account and
                    are logged in to that website.
                </p>
                <h3>How 87% works: a note to Users</h3>
                <p>
                    The 87% platform is designed to help Employers manage and improve mental
                    wellbeing in the workplace. Therefore, your Employer will have their own access
                    to a client portal version of the Solution in parallel to your use of the 87%
                    app, and the User and Employer experiences of the Solution will necessarily
                    influence each other.
                </p>
                <p>
                    While you may have signed up to our app as an individual user, this has been
                    prompted by your Employer requesting us to grant you access to the 87% app (at
                    your discretion) in connection with the services they seek from us. This means
                    that your access to the Solution, types of Personal Data collected about you or
                    uses made of your Personal Data (e.g. for aggregated research reports), will be
                    conditional on and may be influenced by the decisions of your specific Employer.
                </p>
                <p>
                    87% will process all Personal Data we receive from your Employer or you directly
                    in connection with your use of the app in accordance with all applicable Data
                    Protection Laws and this privacy policy. However, it is entirely your Employer’s
                    responsibility to comply with all necessary legal requirements regarding your
                    Personal Data outside of this context, in particular ensuring that they have
                    obtained any necessary consents or otherwise transferred any Employer-Provided
                    Data to us lawfully.
                </p>
                <p>
                    If you have any concerns about the types and uses of Employer-Provided Data used
                    in connection with the Solution, please contact the person in your Employer’s
                    organisation responsible for data protection compliance.
                </p>
                <p>
                    This privacy policy covers Personal Data processed in respect of both Employers
                    and Users.
                </p>
                <h3>General</h3>
                <p>
                    You may not transfer any of your rights under this privacy policy to any other
                    person. We may transfer our rights under this privacy policy where we reasonably
                    believe your rights will not be affected.
                </p>
                <p>
                    If any court or competent authority finds that any provision of this privacy
                    policy (or part of any provision) is invalid, illegal or unenforceable, that
                    provision or part-provision will, to the extent required, be deemed to be
                    deleted, and the validity and enforceability of the other provisions of this
                    privacy policy will not be affected.
                </p>
                <p>
                    Unless otherwise agreed, no delay, act or omission by a party in exercising any
                    right or remedy will be deemed a waiver of that, or any other, right or remedy.
                </p>
                <p>
                    This privacy policy is governed by and interpreted according to the law of
                    England and Wales. All disputes arising under this privacy policy are subject to
                    the exclusive jurisdiction of the English and Welsh courts.
                </p>
                <h3>Changes to this privacy policy</h3>
                <p>
                    87% reserves the right to change this privacy policy as we may deem necessary
                    from time to time or as may be required by law. Any changes will be immediately
                    posted on the Website and you are deemed to have accepted the terms of the
                    privacy policy on your first use of the Solution following the alterations.
                </p>
            </section>
        </div>
    );
};

export default Privacy;
