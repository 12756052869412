import { IVector2, NumberToWords } from 'src/types/shared';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const radToDeg = (angle: number) => (angle * 180) / Math.PI;
export const degToRad = (angle: number) => (angle * Math.PI) / 180;
export const getPositionFromAngledRadius = (angle: number, radius: number): IVector2 => ({
    x: Math.cos(angle) * radius,
    y: Math.sin(angle) * radius,
});

let num =
    'zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(
        ' ',
    );
let tens = 'twenty thirty forty fifty sixty seventy eighty ninety'.split(' ');
let hundred = 'hundred';
let thousand = 'thousand';
let and = 'and';
export const numberToWords = (n: number, words: NumberToWords | null): any => {
    // Handle translation passed by words param
    if (words !== null) {
        num = words.zeroToNineteen;
        tens = words.tens;
        hundred = words.hundredAndThousand[0];
        thousand = words.hundredAndThousand[1];
        and = words.and;
    }
    if (n < 20) return num[n];
    const digit = n % 10;
    if (n < 100) return tens[~~(n / 10) - 2] + (digit ? '-' + num[digit] : '');
    if (n < 1000)
        return (
            // eslint-disable-next-line eqeqeq
            num[~~(n / 100)] +
            ' ' +
            hundred +
            (n % 100 === 0 ? '' : ' ' + and + ' ' + numberToWords(n % 100, words))
        );
    return (
        numberToWords(~~(n / 1000), words) +
        ' ' +
        thousand +
        // eslint-disable-next-line eqeqeq
        (n % 1000 != 0 ? ' ' + numberToWords(n % 1000, words) : '')
    );
};

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const getTextWidth = (text: string, font: string): TextMetrics | null => {
    const element = document.createElement('canvas');
    const context = element.getContext('2d');
    if (!context) return null;
    context.font = font;
    return context.measureText(text);
};

export const multVector = ({ x, y }: IVector2, multiplier: number): IVector2 => ({
    x: x * multiplier,
    y: y * multiplier,
});

export const addVector = ({ x, y }: IVector2, addition: number): IVector2 => ({
    x: x + addition,
    y: y + addition,
});

export const addVectors = ({ x, y }: IVector2, addition: IVector2): IVector2 => ({
    x: x + addition.x,
    y: y + addition.y,
});

export const subtractVectors = ({ x, y }: IVector2, subtraction: IVector2): IVector2 => ({
    x: x - subtraction.x,
    y: y - subtraction.y,
});
