import { FunctionComponent, useState } from 'react';
import { Trans } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import man from '../../../../_content/images/man-brush-brain.svg';
import woman from '../../../../_content/images/woman-question.svg';
import mind from '../../../../_content/images/mind-meter.svg';
import peopleGroup from '../../../../_content/images/people-group.svg';
import { externalUrls } from '../../../../utils';

const AssessmentSlider = () => {
    const [slide, setSlide] = useState(0);
    return (
        <div className="flex-row justify-center slider-container">
            <Carousel
                showThumbs={false}
                showStatus={false}
                centerMode={true}
                centerSlidePercentage={100}
                selectedItem={slide}
                renderIndicator={(_, isSelected, i) => (
                    <Indicator isActive={isSelected} onPress={() => setSlide(i)} />
                )}
            >
                {slides.map(({ Title, Content, img }, i) => (
                    <div key={i} className="container slider-item">
                        <div className="slider-image-box">
                            <img src={img} alt="" />
                        </div>
                        <div className="slider-content-item">
                            <h3>{Title({})}</h3>
                            <span className="content">{Content({})}</span>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

interface IndicatorProps {
    isActive: boolean;
    onPress: () => void;
}
const Indicator = ({ isActive, onPress }: IndicatorProps) => {
    return (
        <button
            type="button"
            onClick={onPress}
            className={`slider-indicator ${isActive ? 'active' : ''}`}
        >
            ⬤
        </button>
    );
};

const slides: { Title: FunctionComponent; Content: FunctionComponent; img: string }[] = [
    {
        Title: () => (
            <Trans i18nKey="yourAssessment.assessmentSlider.whatAreTheBenefitsTitle" ns="pages" />
        ),
        Content: () => (
            <Trans
                i18nKey="yourAssessment.assessmentSlider.whatAreTheBenefits"
                ns="pages"
                components={{ bold: <b /> }}
            />
        ),
        img: mind,
    },
    {
        Title: () => (
            <Trans
                i18nKey="yourAssessment.assessmentSlider.howDoesMyOrganisationBenefitTitle"
                ns="pages"
            />
        ),
        Content: () => (
            <Trans
                i18nKey="yourAssessment.assessmentSlider.howDoesMyOrganisationBenefit"
                ns="pages"
                components={{ bold: <b /> }}
            />
        ),
        img: woman,
    },
    {
        Title: () => (
            <Trans
                i18nKey="yourAssessment.assessmentSlider.howDoesMyCommunityBenefitTitle"
                ns="pages"
            />
        ),
        Content: () => (
            <Trans
                i18nKey="yourAssessment.assessmentSlider.howDoesMyCommunityBenefit"
                ns="pages"
                components={{ bold: <b /> }}
            />
        ),
        img: peopleGroup,
    },
    {
        Title: () => (
            <Trans
                i18nKey="yourAssessment.assessmentSlider.privacyAndConfidentialityTitle"
                ns="pages"
            />
        ),
        Content: () => (
            <Trans i18nKey="yourAssessment.assessmentSlider.privacyAndConfidentiality" ns="pages">
                <b> </b>
                <a href={externalUrls.faq} target="_blank" rel="noreferrer">
                    {' '}
                    {}{' '}
                </a>
            </Trans>
        ),
        img: man,
    },
];

export default AssessmentSlider;
