import React, { useMemo } from 'react';
import Slider from 'lib/src/components/form/Slider';
import { DimensionAnswer, SelectedAnswer } from '../../../../../../types/shared';

const DimensionSliderQuestion = ({ answers, selectedAnswer, handleSelect }: Props) => {
    const sortedAnswers = [...answers].sort((a, b) => a.score - b.score);
    const min = 0;
    const max = sortedAnswers.length - 1;

    const stepMarkers = sortedAnswers.map((answer, index) => {
        if (index === 0 || index === sortedAnswers.length - 1) {
            return { value: index, label: answer.text };
        } else {
            return { value: index, label: '' };
        }
    });

    const value = useMemo(() => {
        return sortedAnswers.reduce((acc, answer, index) => {
            if (selectedAnswer && answer.id === selectedAnswer.answerID) {
                acc = index;
            }

            return acc;
        }, 0);
    }, [sortedAnswers, selectedAnswer]);

    return (
        <Slider
            name="answer"
            value={value}
            onChange={(_, value) => handleChange(value)}
            min={min}
            max={max}
            fieldClassName="questionnaire-slider"
            stepMarkers={stepMarkers}
            step={1}
            hideLabels
        />
    );

    function handleChange(value: number) {
        const answer = answers.find((_, index) => index === value);

        if (!!answer) {
            handleSelect(answer);
        }
    }
};

interface Props {
    answers: DimensionAnswer[];
    selectedAnswer: SelectedAnswer | undefined;
    handleSelect: (answer: DimensionAnswer) => void;
}

export default DimensionSliderQuestion;
