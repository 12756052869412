const useWheelCompletedClasses = (completed: Record<number, boolean>) => {
    return {
        0: completed[1] ? 'default completed' : 'dim',
        1: completed[2] ? 'default completed' : 'dim',
        2: completed[3] ? 'default completed' : 'dim',
        3: completed[4] ? 'default completed' : 'dim',
        4: completed[5] ? 'default completed' : 'dim',
        5: completed[6] ? 'default completed' : 'dim',
        6: completed[7] ? 'default completed' : 'dim',
        7: completed[8] ? 'default completed' : 'dim',
        8: completed[9] ? 'default completed' : 'dim',
        9: completed[10] ? 'default completed' : 'dim',
    } as Record<number, string>;
};

export default useWheelCompletedClasses;
