import { DimensionQuestion, SelectedAnswer } from 'src/types/shared/QuestionData';

const useNextIncompleteQuestion = (
    questions: DimensionQuestion[],
    answers: SelectedAnswer[],
    currentSlide: number | null,
) => {
    const safeSlide = currentSlide ?? 0;

    const getNextQuestion = (newAnsweredQuestion = -1): number => {
        for (let i = safeSlide; i < questions.length + safeSlide; i++) {
            const safeIndex = i % questions.length;
            if (
                !answers.some(x => x.questionID === questions[safeIndex].id) &&
                questions[safeIndex].id !== newAnsweredQuestion
            ) {
                return safeIndex;
            }
        }
        return currentSlide ?? 0;
    };
    return { getNextQuestion };
};

export default useNextIncompleteQuestion;
