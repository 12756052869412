import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubcategoryContent } from 'src/types/shared/QuestionnaireResult';

interface Props {
    title: string;
    strengths: SubcategoryContent[];
    difficulties: SubcategoryContent[];
}

export default function ReflectionBanner({ title, strengths, difficulties }: Props) {
    const { t } = useTranslation(['pages']);
    return (
        <div className="reflection-banner container">
            <h3 className="head">{title}</h3>
            {strengths.length > 0 && (
                <div className="body flex-row">
                    <div className="flex-4" />
                    <div className="flex-8 content">
                        <h4 className="title">{t('results.reflectionBanner.strengthTitle')}</h4>
                    </div>
                </div>
            )}
            {strengths.map(({ subcategoryName, content }, i) => (
                <div key={i} className="body flex-row">
                    <ul className="flex-column bullets flex-4">
                        <li key={i} className="strength">
                            {subcategoryName}
                        </li>
                    </ul>
                    <div className="flex-8 content">
                        <p key={i}>{content}</p>
                    </div>
                </div>
            ))}
            {difficulties.length > 0 && (
                <div className="body flex-row">
                    <div className="flex-4" />
                    <div className="flex-8 content">
                        <h4 className="title">{t('results.reflectionBanner.weaknessTitle')}</h4>
                    </div>
                </div>
            )}
            {difficulties.map(({ subcategoryName, content }, i) => (
                <div key={i} className="body flex-row">
                    <ul className="flex-column bullets flex-4">
                        <li key={i} className="difficulty">
                            {subcategoryName}
                        </li>
                    </ul>
                    <div className="flex-8 content">
                        <p key={i}>{content}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}
