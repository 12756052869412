import { useEffect } from 'react';

const usePageLeaveWarn = () => {
    useEffect(() => {
        window.onbeforeunload = () => 'Are you sure?';
        return () => {
            window.onbeforeunload = null;
        };
    }, []);
};

export default usePageLeaveWarn;
