import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Station } from '../../../../types/shared';
/** Icons for TubeMap */
/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iI]con" }] */
// eslint-disable-next-line
import greenTickIcon from '../../../../_content/images/tubeMap/green_tick.png';
// eslint-disable-next-line
import greenOneIcon from '../../../../_content/images/tubeMap/green_1.png';
// eslint-disable-next-line
import greenTwoIcon from '../../../../_content/images/tubeMap/green_2.png';
// eslint-disable-next-line
import greenThreeIcon from '../../../../_content/images/tubeMap/green_3.png';
// eslint-disable-next-line
import purpleTickIcon from "../../../../_content/images/tubeMap/purple_tick.png";
// eslint-disable-next-line
import purpleOneIcon from '../../../../_content/images/tubeMap/purple_1.png';
// eslint-disable-next-line
import purpleTwoIcon from '../../../../_content/images/tubeMap/purple_2.png';
// eslint-disable-next-line
import purpleThreeIcon from '../../../../_content/images/tubeMap/purple_3.png';

import QuestionData, { CustomSection, Dimension } from 'src/types/shared/QuestionData';

export interface Ticks {
    aboutyou: string;
    wellbeingassessment: string;
    complete: string;
    station: string;
}

export default function useTubeMapStops(questionData: QuestionData) {
    const { t } = useTranslation(['common']);
    const TICKS: Ticks = {
        aboutyou: greenOneIcon,
        wellbeingassessment: greenTwoIcon,
        complete: greenTickIcon,
        station: greenTickIcon,
    };

    let stopMap = [
        {
            id: null,
            name: t('tubeMap.stops.aboutYou'),
            label: 'aboutyou',
            icon: greenOneIcon,
            size: 'SMALL',
            isCurrent: false,
            isHeading: false,
            isStatic: true,
        },
        ...questionData.onboardingSections.map((section, index) => {
            return {
                id: parseInt('01' + section.id.toString()),
                name: section.name,
                label: 'station',
                icon: null,
                size: 'SMALL',
                isCurrent: index === 0 ? true : false,
                isHeading: false,
                isStatic: false,
            };
        }),
        {
            id: null,
            name: t('tubeMap.stops.wellbeingAssessment'),
            icon: purpleTwoIcon,
            label: 'wellbeingassessment',
            size: 'SMALL',
            isCurrent: false,
            isHeading: false,
            isStatic: true,
        },
        ...questionData.dimensions.map(dimension => {
            return {
                id: parseInt('02' + dimension.id.toString()),
                name: dimension.name,
                label: 'station',
                icon: null,
                size: 'SMALL',
                isCurrent: false,
                isHeading: false,
                isStatic: false,
            };
        }),
        {
            id: null,
            name: t('tubeMap.stops.complete'),
            label: 'complete',
            icon: purpleTickIcon,
            size: 'SMALL',
            isCurrent: false,
            isHeading: false,
            isStatic: true,
        },
    ];

    const [tubeMapData, setTubeMapData] = useState<Array<Station>>(stopMap);

    const reinitTubeMap = (resumeIndex: number = 0) => {
        stopMap = [
            {
                id: null,
                name: t('tubeMap.stops.aboutYou'),
                label: 'aboutyou',
                icon: greenOneIcon,
                size: 'SMALL',
                isCurrent: false,
                isHeading: false,
                isStatic: true,
            },
            ...questionData.onboardingSections.map((section, index) => {
                return {
                    id: parseInt('01' + section.id.toString()),
                    name: section.name,
                    label: 'station',
                    icon: null,
                    size: 'SMALL',
                    isCurrent: index === 0 ? true : false,
                    isHeading: false,
                    isStatic: false,
                };
            }),
            {
                id: null,
                name: t('tubeMap.stops.wellbeingAssessment'),
                icon: purpleTwoIcon,
                label: 'wellbeingassessment',
                size: 'SMALL',
                isCurrent: false,
                isHeading: false,
                isStatic: true,
            },
            ...questionData.dimensions.map(dimension => {
                return {
                    id: parseInt('02' + dimension.id.toString()),
                    name: dimension.name,
                    label: 'station',
                    icon: null,
                    size: 'SMALL',
                    isCurrent: false,
                    isHeading: false,
                    isStatic: false,
                };
            }),
            {
                id: null,
                name: t('tubeMap.stops.complete'),
                label: 'complete',
                icon: purpleTickIcon,
                size: 'SMALL',
                isCurrent: false,
                isHeading: false,
                isStatic: true,
            },
        ];

        if (resumeIndex > 0) {
            // We are resuming from a save and changed language
            // So we need to make sure the map reflects the save
            // position in the new language and
            // as we can only save while in a dimension section
            // we follow the logic in updateStopMapFromDimensionSection
            const dimension = questionData.dimensions[resumeIndex];
            const result = stopMap.findIndex(
                (obj: any) => obj.id === parseInt('02' + dimension.id.toString()),
            );
            if (result !== -1) {
                // Update the stop map completing the stops before
                // we place it in state. We work on the translated array of objects
                // before we place it in state because of race conditions. See updateTubeMap
                if (!stopMap[result].isStatic) {
                    stopMap[result].isCurrent = true;
                }

                if (stopMap[result - 1]) {
                    if (!stopMap[result - 1].isStatic) {
                        stopMap[result - 1].isCurrent = false;
                    }
                    let iconEntry = TICKS[stopMap[result - 1].label as keyof Ticks];
                    stopMap[result - 1].icon = iconEntry;
                }

                // When navigating backwards in onboarding we need to check the next station
                // to the current result isnt set as current
                if (stopMap[result + 1]) {
                    if (!stopMap[result + 1].isStatic && stopMap[result + 1].isCurrent) {
                        stopMap[result + 1].isCurrent = false;
                    }
                }
            }
        }
        setTubeMapData(stopMap);
    };

    const updateTubeMap = (index: number) => {
        const originalMap = [...tubeMapData];
        if (!originalMap[index].isStatic) {
            originalMap[index].isCurrent = true;
        }

        if (originalMap[index - 1]) {
            if (!originalMap[index - 1].isStatic) {
                originalMap[index - 1].isCurrent = false;
            }
            let iconEntry = TICKS[originalMap[index - 1].label as keyof Ticks];
            originalMap[index - 1].icon = iconEntry;
        }

        // When navigating backwards in onboarding we need to check the next station
        // to the current index isnt set as current
        if (originalMap[index + 1]) {
            if (!originalMap[index + 1].isStatic && originalMap[index + 1].isCurrent) {
                originalMap[index + 1].isCurrent = false;
            }
        }

        setTubeMapData(originalMap);
    };

    const updateStopMapFromOnboardingSection = (section: CustomSection) => {
        const result = tubeMapData.findIndex(
            (obj: any) => !!obj.id && obj.id === parseInt('01' + section.id.toString()),
        );
        if (result !== -1) {
            updateTubeMap(result);
        }
    };

    const updateStopMapFromDimensionSection = (dimension: Dimension) => {
        const result = tubeMapData.findIndex(
            (obj: any) => obj.id === parseInt('02' + dimension.id.toString()),
        );
        if (result !== -1) {
            updateTubeMap(result);
        }
    };

    const completeSectionStops = (name: string, setLastIndex: number | null) => {
        // Experimental use labels as they remain in fallback language
        const formattedLabel = name.replace(' ', '').toLowerCase();
        const startingIndex = tubeMapData.findIndex((obj: any) => {
            return obj.label.toLowerCase() === formattedLabel && obj.id === null;
        });
        if (startingIndex === -1) {
            return;
        }

        let lastIndex = setLastIndex;
        let currentIndex = 0;

        if (!lastIndex) {
            for (let item of tubeMapData) {
                if (item.isStatic && currentIndex > startingIndex) {
                    lastIndex = currentIndex;
                    break;
                }
                currentIndex++;
            }
        }

        if (lastIndex === null) {
            return;
        }

        for (let i = startingIndex; i < lastIndex; i++) {
            tubeMapData[i].isCurrent = false;
            let iconEntry = TICKS[tubeMapData[i].label as keyof Ticks];
            tubeMapData[i].icon = iconEntry;
        }
    };

    return [
        tubeMapData,
        updateStopMapFromOnboardingSection,
        updateStopMapFromDimensionSection,
        completeSectionStops,
        reinitTubeMap,
    ] as const;
}
