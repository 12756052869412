import { SubcategoryResponse } from './QuestionnaireResult';

export default interface QuestionData {
    companyCode: string;
    resumeCode: string | null;
    dimensionAnswers: SelectedAnswer[];
    dimensions: Dimensions;
    onboardingSections: CustomSection[];
    companyImageS3Key: string | null;
    hideDimensionWheel: boolean;
    isScoring: boolean;
    languageCode: string;
}

export interface CustomSection {
    id: number;
    companyId: number;
    sort: number;
    name: string;
    description: string;
    imageS3Key: string | null;
    questions: SectionQuestion[];
    userAnswers: SelectedAnswer[];
}

export interface CustomAnswer {
    id: number;
    questionID: number;
    text: string;
    sectionID: number;
}

export interface Question {
    id: number;
    text: string;
    subcategoryTitle?: string;
    answers: CustomAnswer[];
    isRequired?: boolean;
    answerType: number;
    sliderOptions: SliderOptions | null;
}

export interface SectionQuestion {
    id: number;
    text: string;
    subcategoryTitle?: string;
    answers: CustomAnswer[];
    isRequired?: boolean;
    type: number;
    sliderOptions: SliderOptions | null;
    sort: number;
    sectionID: number;
}

export interface SliderOptions {
    minValue: number;
    maxValue: number;
    increment: number;
} // All integers

export enum QuestionTypes {
    Dropdown = 1,
    TextInput = 2,
    Radio = 3,
    Slider = 4,
    Checkbox = 5,
    MultiSelect = 6,
}
export interface SelectedAnswer {
    questionID: number;
    answerID?: number | null;
    answer?: string; // Can be stringified number for slider
    score?: number | null;
}

export type Dimensions = Dimension[];

export interface Dimension {
    id: number;
    name: string;
    description: string;
    imageS3Key: string | null;
    sort: number;
    questions: DimensionQuestion[];
}

export interface ResultDimension {
    dimensionID: number;
    dimensionName: string;
    dimensionScore: number | null;
    dimensionScoreContent?: any;
    isScoring: boolean;
    scoreBracket: number;
    subcategories: SubcategoryResponse[];
}

export interface DimensionQuestion {
    id: number;
    text: string;
    subcategoryTitle?: string;
    answers: DimensionAnswer[];
    isRequired?: boolean;
    answerType: number;
}

export interface QuestionnairePostBody {
    companyCode: string;
    resumeCode: string | null;
    onboardingAnswers: SelectedAnswer[];
    dimensionAnswers: SelectedAnswer[];
}

export interface DimensionColours {
    [key: number]: string;
}
export interface DimensionTitleText {
    [key: number]: string;
}

export enum DimensionQuestionTypes {
    Dropdown = 1,
    Radio = 2,
    Slider = 3,
    TextInput = 4,
    MultiSelect = 5,
}

export interface DimensionAnswer {
    id: number;
    questionID: number;
    text: string;
    score: number;
    sort: number;
}

export interface EmptyAnswer {
    questionID: number;
    id: null;
    score: null;
}

export interface NumberToWords {
    and: string;
    zeroToNineteen: Array<string>;
    tens: Array<string>;
    hundredAndThousand: Array<string>;
}
