import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { setCurrentPage } from '@actions/pages/setCurrentPage';
import { setCurrentLanguage } from '@actions/pages/setCurrentLanguage';

interface PagesState {
    page: number;
    language: string;
}

const initialState: PagesState = {
    page: 0,
    language: 'en',
};

export default createReducer(initialState, {
    [setCurrentPage.type]: handleSetPage,
    [setCurrentLanguage.type]: handleSetLanguage,
});

function handleSetPage(state: PagesState, action: PayloadAction<number>) {
    state.page = action.payload;
}

function handleSetLanguage(state: PagesState, action: PayloadAction<string>) {
    state.language = action.payload;
}
