import React from 'react';
import Select from 'lib/src/components/form/Select';
import { convertArrToObj } from 'lib/src/utils/generic';
import { DimensionAnswer, SelectedAnswer } from '../../../../../../types/shared';

const DimensionDropdownQuestion = ({ answers, selectedAnswer, handleSelect }: Props) => {
    return (
        <div className="dimension-answer">
            <Select
                name="answer"
                value={selectedAnswer?.answerID || 0}
                onChange={(_, value) => value && handleSelect(convertArrToObj(answers)[value])}
                options={answers.map(answer => ({
                    value: answer.id,
                    label: answer.text,
                }))}
            />
        </div>
    );
};

interface Props {
    answers: DimensionAnswer[];
    selectedAnswer: SelectedAnswer | undefined;
    handleSelect: (answer: DimensionAnswer) => void;
}

export default DimensionDropdownQuestion;
