import React from 'react';
import { DimensionAnswer, SelectedAnswer } from '../../../../../../types/shared';

const DimensionRadioQuestion = ({
    answers,
    selectedAnswer,
    handleSelect,
    isActive,
    color,
}: Props) => {
    return (
        <div className="answers-wrapper flex-row align-center">
            {answers.map((answer, answerIndex) => (
                <div key={answer.id} className="answer flex-column align-center justify-center-lm">
                    <button
                        tabIndex={isActive ? answerIndex + 1 : -1}
                        className={`select-icon ${color} ${
                            selectedAnswer?.answerID === answer.id && 'active'
                        }`}
                        onClick={() => handleSelect(answer)}
                    />
                    <p>{!isNaN(parseInt(answer.text)) ? '' : answer.text}</p>
                </div>
            ))}
        </div>
    );
};

interface Props {
    answers: DimensionAnswer[];
    selectedAnswer: SelectedAnswer | undefined;
    handleSelect: (answer: DimensionAnswer) => void;
    isActive: boolean;
    color: string;
}

export default DimensionRadioQuestion;
