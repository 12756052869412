import React, { MouseEvent } from 'react';

import useFieldValidation from '../../hooks/useFieldValidation';
import { DropdownOption } from '../../types/shared/DropdownOption';
import { FormInputProps } from '../../types/shared/FormInputProps';
import Checkbox from './Checkbox';

import FormField from './FormField';

const Checkboxes: React.FC<CheckboxesProps> = ({
    name,
    disabled = false,
    value = [],
    options = [],
    onChange,
    label = '',
    required,
    customValidate,
    emptyValidationMsg = 'This is a required field'
}) => {
    const [error, showError] = useFieldValidation({
        name,
        required,
        customValidate,
        value,
        emptyValidationMsg
    });

    return (
        <FormField name={name} label={label} required={required} error={error}>
            {!options.length && <p>There are no options to display</p>}
            {options.map(opt => (
                <Checkbox
                    disabled={disabled}
                    key={opt.value}
                    name={opt.label}
                    label={opt.label}
                    value={value.includes(opt.value)}
                    onChange={() => {
                        handleSelect(opt.value);
                    }}
                    noFormField
                    hideError
                    // placeholder={opt.label}
                />
            ))}
        </FormField>
    );

    function handleSelect(clicked: number) {
        const newVal = value.includes(clicked)
            ? value.filter(item => item !== clicked)
            : value.concat(clicked);
        handleChange(newVal);
        showError();
    }

    function handleChange(newVal: number[]) {
        onChange(name, newVal);
    }
};

interface CheckboxesProps extends FormInputProps<number[]> {
    options: DropdownOption<number>[];
    label?: string;
    placeholder?: string;
    emptyValidationMsg?: string;
}

export default Checkboxes;
