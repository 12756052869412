import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api } from 'lib/src/utils/api';
import QuestionnaireResult from 'src/types/shared/QuestionnaireResult';

export const fetchResultsRequest = createAction('fetchResultsRequest');
export const fetchResultsSuccess = createAction<QuestionnaireResult>('fetchResultsSuccess');
export const fetchResultsError = createAction<string | null>('fetchResultsError');

export const fetchResults =
    (accessCode: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchResultsRequest());
        try {
            const res = await api.get<QuestionnaireResult>(`questionnaire/results/${accessCode}`);
            dispatch(fetchResultsSuccess(res.data));
        } catch (err) {
            dispatch(fetchResultsError(err as string | null));
        }
    };
