import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ActionButton from 'lib/src/components/button/ActionButton';

const ResultsFooter = ({ print, handleDownload, isDownloading, onComplete }: Props) => {
    const { t } = useTranslation(['pages', 'common']);
    return (
        <footer className="footer">
            <div className="container">
                <div className="flex-row contact">
                    <div className="flex-column justify-center wrapper">
                        <div className="column">
                            <h4 className="title">{t('footer.leftTitle', { ns: 'common' })}</h4>
                            <br />
                            <p>
                                {t('footer.subLeftTitle', { ns: 'common' })}{' '}
                                <a href="mailto:support@87percent.me">support@87percent.me</a>
                            </p>
                        </div>
                        <div className="column">
                            <h4 className="title">{t('footer.rightTitle', { ns: 'common' })}</h4>
                            <br />
                            <p>
                                <Trans
                                    i18nKey="footer.support"
                                    ns="common"
                                    components={{
                                        supportlink: (
                                            <a href="/support" target="_blank" rel="noreferrer">
                                                here
                                            </a>
                                        ),
                                    }}
                                />
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-column wrapper print-hide">
                        <div className="flex-column align-center column finish ">
                            <div>
                                <h4>{t('results.resultsFooter.finishTitle')}</h4>
                                <p>{t('results.resultsFooter.finishSubTitle')}</p>

                                <div className="flex-row">
                                    <ActionButton
                                        onClick={handleDownload}
                                        className=""
                                        isPosting={isDownloading}
                                    >
                                        {t('button.downloadPDF', { ns: 'common' })}
                                    </ActionButton>
                                    <ActionButton onClick={print} className="">
                                        {t('button.print', { ns: 'common' })}
                                    </ActionButton>
                                </div>

                                <h4>{t('results.resultsFooter.finish')}</h4>
                            </div>
                        </div>
                        <div className="flex flex-row justify-end align-end print-hide">
                            <ActionButton onClick={onComplete} className="">
                                {t('button.finish', { ns: 'common' })}
                            </ActionButton>
                        </div>
                    </div>
                </div>
                <div className="flex-row align-center justify-center copyright-bar">
                    <p>Copyright © 2018–2023 87% Ltd. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

interface Props {
    print: () => void;
    handleDownload: () => void;
    isDownloading: boolean;
    onComplete?: () => void;
}

export default ResultsFooter;
