import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResultDimension } from '../../../../types/shared';
import ResultsWheel from './ResultsWheel';
import ResultsBreakdownTable from './ResultsBreakdownTable';
import ResultsBreakdownContent from './ResultsBreakdownContent';
import { getRatingClassFromBracket, getRatingFromBracket } from '../../../../utils';
import SevenDimensionWheel from '@components/wheel/SevenDimensionWheel';
import { Carousel } from 'react-responsive-carousel';
import BreakdownItem from '@pages/home/questionnaire/results/BreakdownItem';

interface Props {
    dimensions: ResultDimension[];
    score: number | null;
}

export default function ResultsViewer({ dimensions, score }: Props) {
    const { t } = useTranslation(['pages']);
    const [selected, setSelected] = useState<ResultDimension>(dimensions[0]);
    const [slide, setSlide] = useState(0);
    const scoreBracket = selected?.scoreBracket;

    const dimensionRating = !scoreBracket ? 'N/A' : getRatingFromBracket(scoreBracket);
    const dimensionRatingClass = !scoreBracket ? '' : getRatingClassFromBracket(scoreBracket);
    const dimensionIndex = dimensions.findIndex(x => x.dimensionID === selected.dimensionID);

    const showSimplifiedWheel = dimensions.length > 10;

    return (
        <div className="flex-row results-viewer">
            <div className="container">
                <div className="flex-column wheel-wrapper">
                    <div className="title-wrapper">
                        <h3 className="title">{t('results.resultsViewer.title')}</h3>
                        <p>{t('results.resultsViewer.subTitle')}</p>
                    </div>
                    <div className="flex flex-row justify-between score-wrapper">
                        <div className="wheel-wrapper">
                            {showSimplifiedWheel ? (
                                <SevenDimensionWheel score={score} />
                            ) : (
                                <ResultsWheel
                                    size={{ width: 600, height: 600 }}
                                    dimensions={dimensions}
                                    setDimension={setSelected}
                                    selected={selected}
                                    score={score}
                                    hideCenterText
                                    hasShadow
                                />
                            )}
                        </div>
                        <div className={`breakdown-wrapper ${showSimplifiedWheel ? 'fixed' : ''}`}>
                            <div className="flex-column breakdown">
                                {showSimplifiedWheel ? (
                                    <Carousel
                                        showThumbs={false}
                                        showStatus={false}
                                        centerMode={true}
                                        centerSlidePercentage={100}
                                        selectedItem={slide}
                                        renderIndicator={(_, isSelected, i) => (
                                            <Indicator
                                                isActive={isSelected}
                                                onPress={() => setSlide(i)}
                                            />
                                        )}
                                    >
                                        {dimensions.map((dimension, i) => (
                                            <BreakdownItem
                                                key={i}
                                                index={i}
                                                dimension={dimension}
                                                dimensionRatingClass={dimensionRatingClass}
                                                dimensionRating={dimensionRating}
                                            />
                                        ))}
                                    </Carousel>
                                ) : (
                                    selected != null && (
                                        <BreakdownItem
                                            index={dimensionIndex}
                                            dimension={selected}
                                            dimensionRatingClass={dimensionRatingClass}
                                            dimensionRating={dimensionRating}
                                        />
                                    )
                                )}
                            </div>
                            <div className="breakdown print">
                                <h3 className="title">{t('results.resultsViewer.results')}</h3>
                                {dimensions.map((dimension, index) => (
                                    <div key={index} className="flex-row justify-center wrapper">
                                        <div className="flex-6">
                                            <ResultsBreakdownTable
                                                dimensionIndex={dimensionIndex}
                                                selected={selected}
                                            />
                                        </div>
                                        <div className="flex-6">
                                            <ResultsBreakdownContent dimension={dimension} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

interface IndicatorProps {
    isActive: boolean;
    onPress: () => void;
}

const Indicator = ({ isActive, onPress }: IndicatorProps) => {
    return (
        <button
            type="button"
            onClick={onPress}
            className={`slider-indicator ${isActive ? 'active' : ''}`}
        >
            ⬤
        </button>
    );
};
