import { useMemo, useState } from 'react';
import { ITransformText, IVector2, IWheelPositioning, IWheelProps } from 'src/types/shared';
import { degToRad, getPositionFromAngledRadius, getTextWidth } from '../../utils';
import { useSpring, a } from 'react-spring';

const segments = 200;

const getValuesOuter = (radius: number, totalSegs: number, spacingDeg: number) => {
    const spacing = degToRad(spacingDeg);
    const spanRadius = degToRad(360 / totalSegs);

    let line = '';
    for (let angle = spacing; angle < spanRadius - spacing; angle += spanRadius / segments) {
        const { x, y } = getPositionFromAngledRadius(angle, radius);
        line = `${line} ${x},${y}`;
    }
    return line;
};

const getValuesInner = (
    radius: number,
    totalSegs: number,
    spacingDeg: number,
    outerRadius: number,
) => {
    const spanRadius = degToRad(360 / totalSegs);
    let line = '';
    const innerPerspectiveSpacing = spacingDeg * (outerRadius / radius);
    const spacing = degToRad(innerPerspectiveSpacing);
    for (
        let angle = spanRadius - spacing - spanRadius / segments;
        angle >= spacing - spanRadius / segments;
        angle -= spanRadius / segments
    ) {
        const { x, y } = getPositionFromAngledRadius(angle, radius);
        line = `${line} ${x},${y}`;
    }
    return line;
};

const isLeft = (xCoord: number) => xCoord < 0;
export const GetXWheelBias = ({ x }: IVector2, width: number | undefined) => {
    const boundary = 20;
    if (x > -boundary && x < boundary) return (width ?? 0) * 0.5;
    if (isLeft(x)) return (width ?? 0) * 0.88;
    return (width ?? 0) * 0.12;
};

const DrawRadialText = (props: IWheelProps & ITransformText) => {
    const { dimension, onClickDimension, offset, radius, index, barRadius, totalSegs } = props;
    const { name } = dimension;

    const [translate, vector, angledPosition, textMetric, centerTextTransform] = useMemo(() => {
        const textMetric = getTextWidth(name, "normal 600 24px 'Montserrat SemiBold, Montserrat'");
        const division = 360 / totalSegs;
        const spanRadius = degToRad(division);
        const angleOffset = -degToRad(90);
        const angle = index * spanRadius + angleOffset;
        const radialTweakOffset = radius * (barRadius.outerRadius * 0.07);
        const position = getPositionFromAngledRadius(angle, radialTweakOffset);
        const translatedText: IVector2 = {
            x: offset.x + position.x,
            y: offset.y + position.y,
        };
        const centerTextTransform = `translate(${
            0 - GetXWheelBias(position, textMetric?.width ?? 0)
        } ${24 * 0.25})`;

        return [
            `translate(${translatedText.x}, ${translatedText.y})`,
            translatedText,
            position,
            textMetric,
            centerTextTransform,
        ];
    }, [barRadius.outerRadius, index, name, offset.x, offset.y, radius, totalSegs]);
    if (totalSegs >= 10 || !props.segmentTextComponent) return <></>;

    return (
        <props.segmentTextComponent
            transform={translate}
            centerTextTransform={centerTextTransform}
            offset={offset}
            angledPosition={angledPosition}
            transformVector={vector}
            onClickDimension={onClickDimension}
            textMetric={textMetric}
            name={name}
            dimension={dimension}
        />
    );
};

export const DrawSegments = (props: IWheelProps) => {
    const {
        offset,
        segment,
        scale,
        index,
        dimension,
        barRadius,
        onClickDimension,
        totalSegs,
        opacity,
        fill,
    } = props;
    const { rotation } = segment;
    const { innerRadius, outerRadius } = barRadius;
    const spacing = getVariableSpacing(totalSegs, props?.spacing);
    const halfSeg = (360 / totalSegs) * 0.5;
    const dimensionColourNumber = index >= 10 ? Number(index.toString().slice(-1)) : index;

    const className = `dimension dimension-${dimensionColourNumber} ${props.completedClass}`;
    const segmentPoints = useMemo(
        () =>
            `${
                getValuesOuter(outerRadius, totalSegs, spacing) +
                getValuesInner(innerRadius, totalSegs, spacing, outerRadius)
            }`,
        [innerRadius, outerRadius, spacing, totalSegs],
    );
    const segmentPointsInvisible = useMemo(
        () =>
            `${
                getValuesOuter(outerRadius + 50, totalSegs, spacing) +
                getValuesInner(20, totalSegs, spacing, outerRadius)
            }`,
        [outerRadius, spacing, totalSegs],
    );
    const shouldHover: boolean = !!onClickDimension;
    const [hovered, setHovered] = useState<boolean>(!shouldHover);
    const pointer = {
        pointerEvents: shouldHover ? 'all' : 'none',
        cursor: 'pointer',
    };
    const styles = useSpring({
        opacity: !!opacity ? (hovered ? opacity.to : opacity?.from) : 1,
        config: {
            clamp: true,
        },
    });

    const processHover = (isHover: boolean): void => {
        if (shouldHover) setHovered(isHover);
    };
    return (
        <>
            <a.g
                id={`dimension-${index}`}
                className={className}
                {...styles}
                transform={`rotate(${rotation - (90 + halfSeg)} ${offset.x} ${offset.y})
                translate(${offset.x} ${offset.y})
                scale(${scale.x} ${scale.y})`}
            >
                <polyline points={segmentPoints} stroke={fill} fill={fill} />
            </a.g>

            {props.segmentTextComponent && (
                <a.g {...styles} className={className} id={`dimension-${index}-text`} {...pointer}>
                    <DrawRadialText
                        {...props}
                        offset={offset}
                        angle={rotation}
                        radius={50}
                        barRadius={barRadius}
                    />
                </a.g>
            )}
            {!!onClickDimension && (
                <g
                    {...pointer}
                    onClick={() => {
                        if (onClickDimension) {
                            onClickDimension(dimension);
                        }
                    }}
                    onMouseOver={() => processHover(true)}
                    onMouseLeave={() => setHovered(false)}
                    z={100}
                    transform={`rotate(${rotation - (90 + halfSeg)} ${offset.x} ${offset.y})
                translate(${offset.x} ${offset.y})
                scale(${scale.x} ${scale.y})`}
                    opacity={0}
                >
                    <polyline points={segmentPointsInvisible} style={{ pointerEvents: 'all' }} />
                </g>
            )}
        </>
    );
};

const getVariableSpacing = (segs: number, averageSpacing: number | undefined = 2) => {
    if (segs < 2) {
        return 0;
    }
    return segs > 10 ? Math.max(3 - segs * 0.1, 0) : averageSpacing;
};

export const DrawBackdrop = ({ offset, scale, barRadius }: IWheelPositioning) => {
    const { innerRadius, outerRadius } = barRadius;
    const segmentPoints = useMemo(
        () =>
            `${getValuesOuter(innerRadius, 1, 0) + getValuesInner(outerRadius, 1, 0, outerRadius)}`,
        [innerRadius, outerRadius],
    );

    return (
        <polyline
            fill="black"
            opacity={0.02}
            transform={`translate(${offset.x} ${offset.y})
                scale(${scale.x} ${scale.y})`}
            points={segmentPoints}
        />
    );
};

export default DrawSegments;
