import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import QuestionData from 'src/types/shared/QuestionData';
import { APIError } from 'lib/src/types/APIError';

export const fetchQuestionsRequest = createAction('fetchQuestionsRequest');
export const fetchQuestionsSuccess = createAction<QuestionData>('fetchQuestionsSuccess');
export const fetchQuestionsError = createAction<string | null>('fetchQuestionsError');

export const fetchQuestions =
    (accessCode: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchQuestionsRequest());
        try {
            const res = await api.get<QuestionData>(`questionnaire/${accessCode}`);
            dispatch(fetchQuestionsSuccess(res.data));
        } catch (err) {
            dispatch(handleApiErrors(dispatch, fetchQuestionsError, err as APIError));
        }
    };
