import React from 'react';

const boldRegex = /(low score)|(average score)|(high score)/;

const useDimensionContent = (content: string | null) =>
    content
        ?.split(boldRegex)
        .map((str, i) =>
            boldRegex.test(str) ? (
                <b key={i}>{str}</b>
            ) : (
                <React.Fragment key={i}>{str}</React.Fragment>
            ),
        ) ?? [<React.Fragment key={0}></React.Fragment>];

export default useDimensionContent;
