import { postQuestions } from '@actions/questions/postQuestions';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QuestionData from 'src/types/shared/QuestionData';
import useAnswerHandler from './useAnswerHandler';
import useCustomAnswerHandler from './useCustomAnswerHandler';
import { getQuestionsIsPosting } from '@selectors/questions';

const useSubmitQuestionnaire = (questionData: QuestionData) => {
    const dispatch = useDispatch();
    const { companyCode, resumeCode, dimensionAnswers } = questionData;

    const {
        answers: selectedCustomAnswers,
        handleChange,
        selectAnswer: selectCustomAnswer,
    } = useCustomAnswerHandler();

    const isPosting = useSelector(getQuestionsIsPosting);
    const {
        selectedAnswers: selectedDimensionAnswers,
        selectAnswer: selectDimensionAnswer,
        handleTextInputChange,
    } = useAnswerHandler(dimensionAnswers);

    const handleSubmit = useCallback(() => {
        if (!companyCode || isPosting) return;
        const postBody = {
            companyCode,
            onboardingAnswers: selectedCustomAnswers.length ? selectedCustomAnswers : [],
            dimensionAnswers:
                selectedDimensionAnswers?.length ?? 0 ? selectedDimensionAnswers : dimensionAnswers,
            resumeCode,
        };

        dispatch(postQuestions(postBody));
    }, [
        isPosting,
        companyCode,
        resumeCode,
        dimensionAnswers,
        dispatch,
        selectedCustomAnswers,
        selectedDimensionAnswers,
    ]);

    return {
        selectedCustomAnswers,
        handleChange,
        selectCustomAnswer,
        handleSubmit,
        selectedDimensionAnswers,
        selectDimensionAnswer,
        handleTextInputChange,
        isPosting,
    };
};

export default useSubmitQuestionnaire;
