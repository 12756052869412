import Badge from './Badge';
import logo from '../../_content/images/logos/white-logo.png';

import mindImg from '../../_content/images/organisations/mind.svg';
import samaritansImg from '../../_content/images/organisations/samaritans.svg';
import nhsImg from '../../_content/images/organisations/nhs.svg';
import { externalUrls } from '../../../src/utils/constants';

const Support = () => {
    return (
        <div id="support-page" className="page-padding">
            <div className="content-one">
                <div className="flex-row align-center header-items">
                    <div className="flex-column left-header">
                        <a href="/">
                            <img alt="logo" className="logo" src={logo} />
                        </a>
                    </div>
                    <div className="flex-column right-header">
                        <a href="/" target="_blank" rel="noreferrer">
                            Login
                        </a>
                    </div>
                </div>
                <div className="header-text">
                    <h4 className="main-header">Support whenever you need it.</h4>
                    <h3 className="services-available">Services available to you</h3>
                    <p className="paragraph">
                        If you are currently experiencing difficulties with your mental health
                        please visit one of these organisations for further information and support.
                    </p>
                </div>
            </div>
            <div className="content-two">
                <div className="flex-row flex-column-mm badge-wrapper">
                    <Badge
                        name="Mind"
                        img={mindImg}
                        color="mind-dark-blue"
                        href={externalUrls.mind}
                    />
                    <Badge
                        name="Samaritans"
                        img={samaritansImg}
                        color="samaritans-green"
                        href={externalUrls.samaritans}
                    />
                    <Badge name="NHS" img={nhsImg} color="nhs-blue" href={externalUrls.nhs} />
                </div>
                <div className="content">
                    <h3 className="questions-header">Questions for us?</h3>
                    <p className="questions-paragraph">
                        If you have any questions about the platform, you can contact us at
                    </p>
                    <a
                        href="mailto:support@87percent.me"
                        target="_blank"
                        rel="noreferrer"
                        className="questions-link"
                    >
                        support@87percent.me
                    </a>
                </div>
                <div>
                    <p className="footer">Copyright @ 2018-2021 87% Ltd. All rights reserved.</p>
                </div>
            </div>
        </div>
    );
};

export default Support;
