import useSingleDimension from '@pages/home/questionnaire/sevenDimensions/single/hooks/useSingleDimension';
import { useEffect } from 'react';
import { batch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import QuestionData, { Dimension } from 'src/types/shared/QuestionData';
import AccessCodeModal from '@components/modals/AccessCodeModal';
// import config from '../../../../../config';
import ActionBar from '@components/actionBar/ActionBar';
import ActionButton from 'lib/src/components/button/ActionButton';
import Loading from '@components/loader/Loading';
import DimensionQuestionCarousel from '@pages/home/questionnaire/sevenDimensions/single/DimensionQuestionCarousel';
// import leftArrow from '../../../../../_content/icons/leftArrow.svg';

// const { S3_URL } = config;

const SingleDimension = ({
    dimension,
    questionData,
    setDimension,
    modalState,
    setModalState,
    children,
}: DimensionProps) => {
    // const { imageS3Key, questions, description, name } = dimension;
    const { t } = useTranslation(['common']);
    const { questions, description, name } = dimension;
    const {
        // titleText = '',
        selectedDimensionAnswers,
        selectDimensionAnswer,
        handleSubmit,
        color,
        resetSlide,
        setResetSlide,
        postSuccess,
        prevPostSuccess,
        isPosting,
        getNextDimension,
        isSubmitDisabled,
        questionNumber,
        setQuestionNumber,
        completed,
        handleTextInputChange,
    } = useSingleDimension(dimension, questionData);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            const nextDimension = getNextDimension(completed);

            setDimension(nextDimension);
            setResetSlide(true);
        }
    }, [
        prevPostSuccess,
        postSuccess,
        setDimension,
        setResetSlide,
        completed,
        getNextDimension,
        setQuestionNumber,
    ]);

    return (
        <>
            <div className={`dimension ${color}`}>
                <section className="dimension-header container">
                    <h3 className="dimension-subtitle">
                        {t('tubeMap.stops.wellbeingAssessment', { ns: 'common' })}
                    </h3>
                    <h2 className="title">{name ?? ''}</h2>
                </section>

                <section className="dimension-info-wrapper container flex-row flex-column-lm">
                    {/* <div className="flex-2 image">
                        <img src={`${S3_URL}/${imageS3Key}`} alt="" />
                    </div> */}

                    <div className="dimension-info-text">
                        <p>{description}</p>
                    </div>
                </section>
                {isPosting ? (
                    <div className="loading-wrapper">
                        <Loading />
                    </div>
                ) : (
                    <DimensionQuestionCarousel
                        dimensionQuestions={questions}
                        color={color}
                        selectDimensionAnswer={selectDimensionAnswer}
                        selectedDimensionAnswers={selectedDimensionAnswers}
                        resetSlide={resetSlide}
                        setQuestionNumber={setQuestionNumber}
                        questionNumber={questionNumber}
                        handleTextInputChange={handleTextInputChange}
                    />
                )}
                <ActionBar>
                    {/* {!questionData.hideDimensionWheel && (
                        <ActionButton
                            type="button"
                            variant="outlined"
                            color="white"
                            onClick={handleBackToDimensions}
                            disabled={isPosting}
                        >
                            <img src={leftArrow} alt="Back" className="svg-icon" /> BACK TO
                            DIMENSIONS
                        </ActionButton>
                    )} */}
                    <ActionButton color="white" onClick={handleSaveAndFinish} isPosting={isPosting}>
                        {t('button.save', { ns: 'common' })}
                    </ActionButton>
                    <ActionButton
                        onClick={handleSaveAndContinue}
                        disabled={isSubmitDisabled}
                        isPosting={isPosting}
                    >
                        {t('button.next', { ns: 'common' })}
                    </ActionButton>
                </ActionBar>
                {children ? children : null}
            </div>

            <AccessCodeModal state={modalState} onClose={() => setModalState(false)} />
        </>
    );

    function handleSaveAndContinue() {
        handleSubmit();
    }

    // function handleBackToDimensions() {
    //     batch(() => {
    //         handleSubmit();
    //         setDimension(null);
    //     });
    // }

    function handleSaveAndFinish() {
        batch(() => {
            handleSubmit();
            // setDimension(null);
            setModalState(true);
        });
    }
};

interface DimensionProps {
    dimension: Dimension;
    questionData: QuestionData;
    setDimension: (dimension: Dimension | null) => void;
    modalState: boolean;
    setModalState: (setState: boolean) => void;
    children?: React.ReactChild;
}

export default SingleDimension;
