import { ResultDimension } from 'src/types/shared';
import useDimensionContent from './hooks/useDimensionContent';

interface Props {
    dimension: ResultDimension;
}

export default function ResultsBreakdownContent({ dimension }: Props) {
    const dimensionContent = useDimensionContent(dimension.dimensionScoreContent);

    return <p className="breakdown-content">{dimensionContent}</p>;
}
