import { setHeaderState } from '@actions/header/toggleHeader';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

export type HeaderState = boolean;

const initialState: HeaderState = false;

export default createReducer(initialState, {
    [setHeaderState.type]: handleSetHeaderState,
});

function handleSetHeaderState(state: HeaderState, action: PayloadAction<boolean>) {
    state = action.payload;
    return state;
}
