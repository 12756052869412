import { QuestionnairePages } from '../../types/shared/QuestionnairePages';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { getFooterState } from '@selectors/footer';
import { selectCurrentPage } from '@selectors/pages';

export default function Footer() {
    const footerState = useSelector(getFooterState);
    const page = useSelector(selectCurrentPage);
    const { t } = useTranslation(['common']);
    const shouldHideFooter = page === +QuestionnairePages.results;

    if (shouldHideFooter || !footerState) return null;
    return (
        <footer className="footer">
            <div className="flex-row justify-center contact">
                <div className="flex-row justify-center wrapper">
                    <div className="column">
                        <p className="title">{t('footer.leftTitle', { ns: 'common' })}</p>
                        <br />
                        <p>
                            {t('footer.subLeftTitle', { ns: 'common' })}{' '}
                            <a href="mailto:support@87percent.me">support@87percent.me</a>
                        </p>
                    </div>
                    <div className="column">
                        <p className="title">{t('footer.rightTitle', { ns: 'common' })}</p>
                        <br />
                        <p>
                            <Trans
                                i18nKey="footer.support"
                                ns="common"
                                components={{
                                    supportlink: (
                                        <a href="/support" target="_blank" rel="noreferrer">
                                            here
                                        </a>
                                    ),
                                }}
                            />
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex-row align-center justify-center copyright-bar">
                <p>Copyright © 2018–2023 87% Ltd. All rights reserved.</p>
            </div>
        </footer>
    );
}
