import { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Stations } from '../../types/shared';

import useWindowSize from '../../hooks/useWindowSize';

const SECTION_WIDTH = 137.1428;

export const StatusLine = ({ data }: Stations) => {
    // console.log({ data });
    const ref = useRef<HTMLDivElement>(null);
    const { t } = useTranslation(['common']);

    const { width } = useWindowSize();

    /*
  window.addEventListener("resize", () => {
    //YOU MIGHT NOT WANT THIS - OR IF YOU DO THEN YOU MIGHT WANT TO DEBOUNCE IT

    console.log("resize ");
    if (ref?.current) {
      if (width !== ref.current.offsetWidth) setWidth(ref.current.offsetWidth);
    }
  });
  */

    useLayoutEffect(() => {
        // setWidth(ref.current!.offsetWidth);
        //setHeight(ref.current!.offsetHeight!);
    }, []);

    //Dynamic number of sections
    //base on max layout of 1920, then increase number of lines as the screen size narrows
    //!!!! HAS TWO MODES see slide 8 and slide 9

    //mockJson

    // const rowsNeeded = Math.ceil((data.length * SECTION_WIDTH) / width);
    // const itemsPerRow = Math.floor(width / SECTION_WIDTH);

    return (
        <div ref={ref}>
            {/*
      <img src={greenTick} alt="Green tick" width="20" />
      <img src={greenOne} alt="Green tick" width="20" />
      <img src={purpleTick} alt="Green tick" width="20" />

      <img src={purpleTwo} alt="Green tick" width="20" />
      <img src={purpleThree} alt="Green tick" width="20" />
      <div>{SECTION_WIDTH}</div>
      <div>
        {width}: {width / data.length}
      </div>
      <div>
        {width}: {(data.length * SECTION_WIDTH) / width}
      </div>
      <div>Max items per row: {itemsPerRow}</div>
      <div>rows needed: {rowsNeeded}</div>
        */}

            <div
                style={{
                    fontSize: '20px',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    color: 'black',
                }}
            >
                {t('tubeMap.yourAssessmentProgress')}
            </div>

            {width < 800 &&
                /* 14 row version */
                singleColumn(data)}
            {width >= 800 && width < 1200 && (
                /* Four row version */
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <svg width={1200} viewBox={`0 0 ${800} ${140 * 4}`}>
                        <rect
                            x="0"
                            y="0"
                            width={800}
                            height={140 * 4}
                            fill="#FFFFFF"
                            stroke="#FFF"
                        />
                        <>
                            {data[0] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 1 + 50,
                                    30,
                                    data[0],
                                    false,
                                    true,
                                )}
                            ,
                            {data[1] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 2 + 50,
                                    30,
                                    data[1],
                                    true,
                                    true,
                                )}
                            {data[2] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 3 + 50,
                                    30,
                                    data[2],
                                    true,
                                    true,
                                )}
                            {data[3] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 4 + 50,
                                    30,
                                    data[3],
                                    true,
                                    true,
                                )}
                            {curveRight(SECTION_WIDTH * 4 + SECTION_WIDTH / 2 + 50, 80)}
                            {data[4] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 4 + 50,
                                    170,
                                    data[4],
                                    true,
                                    true,
                                )}
                            {data[5] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 3 + 50,
                                    170,
                                    data[5],
                                    true,
                                    true,
                                )}
                            {data[6] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 2 + 50,
                                    170,
                                    data[6],
                                    true,
                                    true,
                                )}
                            {data[7] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 1 + 50,
                                    170,
                                    data[7],
                                    data.length !== 8,
                                    true,
                                )}
                            {curveLeft(SECTION_WIDTH * 1 - SECTION_WIDTH / 2 + 50, 220)}
                            {data[8] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 1 + 50,
                                    310,
                                    data[8],
                                    data.length !== 9,
                                    true,
                                )}
                            {data[9] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 2 + 50,
                                    310,
                                    data[9],
                                    data.length !== 10,
                                    true,
                                )}
                            {data[10] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 3 + 50,
                                    310,
                                    data[10],
                                    true,
                                    data.length !== 11,
                                )}
                            {data[11] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 4 + 50,
                                    310,
                                    data[11],

                                    true,
                                    data.length !== 12,
                                )}
                            {data.length > 12 &&
                                curveRight(SECTION_WIDTH * 4 + SECTION_WIDTH / 2 + 50, 360)}
                            {data[12] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 4 + 50,
                                    450,
                                    data[12],
                                    data.length !== 13,
                                    true,
                                )}
                            {data[13] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 3 + 50,
                                    450,
                                    data[13],
                                    data.length !== 14,
                                    true,
                                )}
                        </>
                    </svg>
                </div>
            )}
            {width >= 1200 && width < 1600 && (
                /* TWO ROW VERSION  */

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <svg width={1200} viewBox={`0 0 ${1200} ${140 * 2}`}>
                        <rect
                            x="0"
                            y="0"
                            width={1200}
                            height={140 * 2}
                            fill="#FFFFFF"
                            stroke="#FFF"
                        />
                        <>
                            {data[0] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 1 + 50,
                                    30,
                                    data[0],
                                    false,
                                    data[1] ? true : false,
                                )}
                            ,
                            {data[1] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 2 + 50,
                                    30,
                                    data[1],
                                    true,
                                    data[2] ? true : false,
                                )}
                            {data[2] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 3 + 50,
                                    30,
                                    data[2],
                                    true,
                                    data[3] ? true : false,
                                )}
                            {data[3] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 4 + 50,
                                    30,
                                    data[3],
                                    true,
                                    data[4] ? true : false,
                                )}
                            {data[4] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 5 + 50,
                                    30,
                                    data[4],
                                    true,
                                    data[5] ? true : false,
                                )}
                            {data[5] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 6 + 50,
                                    30,
                                    data[5],
                                    true,
                                    data[6] ? true : false,
                                )}
                            {data[6] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 7 + 50,
                                    30,
                                    data[6],
                                    true,
                                    data[7] ? true : false,
                                )}
                            {data[7] && curveRight(SECTION_WIDTH * 7 + SECTION_WIDTH / 2 + 50, 80)}
                            {data[7] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 7 + 50,
                                    170,
                                    data[7],
                                    data.length !== 8,
                                    data[8] ? true : false,
                                )}
                            {data[8] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 6 + 50,
                                    170,
                                    data[8],
                                    data.length !== 9,
                                    data[9] ? true : false,
                                )}
                            {data[9] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 5 + 50,
                                    170,
                                    data[9],
                                    data.length !== 10,
                                    data[10] ? true : false,
                                )}
                            {data[10] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 4 + 50,
                                    170,
                                    data[10],
                                    data.length !== 11,
                                    data[11] ? true : false,
                                )}
                            {data[11] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 3 + 50,
                                    170,
                                    data[11],
                                    data.length !== 12,
                                    data[12] ? true : false,
                                )}
                            {data[12] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 2 + 50,
                                    170,
                                    data[12],
                                    data.length !== 13,
                                    data[13] ? true : false,
                                )}
                            {data[13] &&
                                complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH * 1 + 50,
                                    170,
                                    data[13],
                                    data.length !== 14,
                                    data[14] ? true : false,
                                )}
                        </>
                    </svg>
                </div>
            )}
            {width >= 1601 && (
                /* 1 row version 1920*/
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <svg
                        width={SECTION_WIDTH * data.length}
                        viewBox={`0 0 ${SECTION_WIDTH * data.length} ${140}`}
                    >
                        <rect
                            x="0"
                            y="0"
                            width={SECTION_WIDTH * data.length}
                            height={140}
                            fill="#FFFFFF"
                            stroke="#FFF"
                        />
                        {complexItem(SECTION_WIDTH, SECTION_WIDTH - 80, 30, data[0], false, true)}
                        {data.slice(1, -1).map((point: any, i: number) => {
                            return (
                                <g key={i}>
                                    {data[i + 1] &&
                                        complexItem(
                                            SECTION_WIDTH,
                                            SECTION_WIDTH * (i + 1) + 50,
                                            30,
                                            data[i + 1],
                                            true,
                                            data[i + 2] ? true : false,
                                        )}
                                </g>
                            );
                        })}
                        {complexItem(
                            SECTION_WIDTH,
                            SECTION_WIDTH * (data.length - 1) + 50,
                            30,
                            data[data.length - 1],
                            true,
                            false,
                        )}
                    </svg>
                </div>
            )}
        </div>
    );
};

const singleColumn = (data: any) => {
    const ROW_HEIGHT = 140;
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <svg width={360} viewBox={`0 0 ${360} ${ROW_HEIGHT * 14}`}>
                <rect x="0" y="0" width={360} height={ROW_HEIGHT * 14} fill="#FFFFFF" />
                <>
                    {complexItem(SECTION_WIDTH, SECTION_WIDTH + 50, 30, data[0], false, true)}
                    {curveRight(SECTION_WIDTH * 1 + SECTION_WIDTH / 2 + 50, 80)}

                    {data.slice(1, -1).map((point: any, i: number) => {
                        return (
                            <g key={i}>
                                {complexItem(
                                    SECTION_WIDTH,
                                    SECTION_WIDTH + 50,
                                    (i + 1) * ROW_HEIGHT + 30,
                                    data[i + 1],
                                    true,
                                    true,
                                )}
                                {i % 2 === 0 &&
                                    curveLeft(
                                        SECTION_WIDTH * 1 - SECTION_WIDTH / 2 + 50,
                                        i * ROW_HEIGHT + 220,
                                    )}
                                {i % 2 === 1 &&
                                    curveRight(
                                        SECTION_WIDTH * 1 + SECTION_WIDTH / 2 + 50,
                                        i * ROW_HEIGHT + 220,
                                    )}
                            </g>
                        );
                    })}
                    {complexItem(
                        SECTION_WIDTH,
                        SECTION_WIDTH + 50,
                        (data.length - 1) * ROW_HEIGHT + 30,
                        data[data.length - 1],
                        data.length % 2 === 0 ? false : true,
                        data.length % 2 === 1 ? false : true,
                    )}
                </>
            </svg>
        </div>
    );
};

const curveRight = (x: number, y: number, color: string = '#C4C4C4') => {
    return (
        <path
            d={`
    M ${x} ${y} 
    C ${x + 100} ${y} , 
    ${x + 100} ${y + 140} , 
    ${x} ${y + 140}`}
            fill="transparent"
            stroke={color}
            strokeWidth={2}
        />
    );
};
const curveLeft = (x: number, y: number) => {
    return (
        <path
            d={`
    M ${x} ${y} 
    C ${x - 100} ${y} , 
    ${x - 100} ${y + 140} , 
    ${x} ${y + 140}`}
            fill="transparent"
            stroke={'#C4C4C4'}
            strokeWidth={2}
        />
    );
};

const complexItem = (
    width: number,
    x: number,
    y: number,
    data: any,
    leftLine: boolean = false,
    rightLine: boolean = false,
) => {
    // console.log({ data });
    return (
        <>
            <line
                x1={leftLine ? x - width / 2 : x}
                y1={y + 50}
                x2={rightLine ? x + width / 2 : x}
                y2={y + 50}
                /*stroke={`rgb(${Math.random() * 255},${Math.random() * 255},${
          Math.random() * 255
        })`}*/
                stroke={'#C4C4C4'}
                strokeWidth={2}
            />
            <circle
                cx={x}
                cy={y + 50}
                r={11}
                fill="white"
                stroke={data.isCurrent ? '#4DB267' : '#C4C4C4'}
                strokeWidth={2}
            />
            {data.isHeading ? (
                <foreignObject x={x - width / 2} y={y - 50} width={width} height="200">
                    <p
                        style={{
                            /*border: "1px solid black",*/
                            textAlign: 'center',
                            color: '#34268E',
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}
                    >
                        {data.name}
                    </p>
                </foreignObject>
            ) : (
                <foreignObject x={x - width / 2} y={y + 60} width={width} height="200">
                    <p
                        style={{
                            /*border: "1px solid black",*/
                            textAlign: 'center',
                            color: '#C4C4C4',
                            fontSize: '16px',
                        }}
                    >
                        {data.name}
                    </p>
                </foreignObject>
            )}

            {/* THE FOLLOWING ARE FORCED RENDERS OF STATES THAT I DONT KNOW HOW TO DETERMINE */}

            {data.icon && data.size === 'BIG' && (
                <image href={data.icon} height="35" width="35" x={x - 35 / 2} y={y + 50 - 35 / 2} />
            )}

            {data.icon && data.size === 'SMALL' && (
                <image href={data.icon} height="26" width="26" x={x - 26 / 2} y={y + 50 - 26 / 2} />
            )}

            {data.isCurrent && (
                /* current position \/ */
                <>
                    <line
                        x1={x - 8}
                        y1={y + 20}
                        x2={x}
                        y2={y + 50 - 22}
                        stroke={'#C4C4C4'}
                        strokeWidth={2}
                    />
                    <line
                        x1={x + 8}
                        y1={y + 20}
                        x2={x}
                        y2={y + 50 - 22}
                        stroke={'#C4C4C4'}
                        strokeWidth={2}
                    />
                </>
            )}
        </>
    );
};
