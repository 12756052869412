import React from 'react';
import { Trans } from 'react-i18next';

export default function Wheelcompleted() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="654.335"
            height="496.666"
            viewBox="0 0 654.335 496.666"
            className="wheel"
        >
            <g id="Group_78" data-name="Group 78" transform="translate(-250 -554.227)">
                <g id="Group_56" data-name="Group 56" transform="translate(397.858 601.177)">
                    <path
                        id="Combined_Shape"
                        data-name="Combined Shape"
                        d="M589.984,744.306,533.333,626.682a186.356,186.356,0,0,1,144.577-.031L621.271,744.3a17.365,17.365,0,0,1-31.287,0Z"
                        transform="translate(-419.889 -612.074)"
                        className="dimension dimension-0 completed"
                    />
                    <path
                        id="Combined_Shape-2"
                        data-name="Combined Shape-2"
                        d="M631.2,750.8l56.487-117.336A186.238,186.238,0,0,1,778.913,745.99l-128.2,29.272a17.871,17.871,0,0,1-3.991.457A17.389,17.389,0,0,1,631.2,750.8Z"
                        transform="translate(-415.215 -611.034)"
                        className="dimension dimension-1 completed"
                    />
                    <path
                        id="Combined_Shape-3"
                        data-name="Combined Shape-3"
                        d="M647.077,816.7a17.366,17.366,0,0,1,6.961-30.507l128.63-29.371a186.333,186.333,0,0,1-31.867,142.6Z"
                        transform="translate(-414.676 -605.034)"
                        className="dimension dimension-2 completed"
                    />
                    <path
                        id="Combined_Shape-4"
                        data-name="Combined Shape-4"
                        d="M609.568,840.626a17.364,17.364,0,0,1,28.189-13.579l103.175,82.288A185.3,185.3,0,0,1,609.568,972.7Z"
                        transform="translate(-416.182 -601.805)"
                        className="dimension dimension-3 completed"
                    />
                    <path
                        id="Combined_Shape-5"
                        data-name="Combined Shape-5"
                        d="M468.006,909.153,570.9,827.061a17.363,17.363,0,0,1,28.19,13.576V972.682A185.316,185.316,0,0,1,468.006,909.153Z"
                        transform="translate(-423.067 -601.804)"
                        className="dimension dimension-4 completed"
                    />
                    <path
                        id="Combined_Shape-6"
                        data-name="Combined Shape-6"
                        d="M425.151,790.867a186.891,186.891,0,0,1,3.041-33.661L555.2,786.183a17.366,17.366,0,0,1,6.965,30.506l-102.63,81.883A184.956,184.956,0,0,1,425.151,790.867Z"
                        transform="translate(-425.151 -605.016)"
                        className="dimension dimension-5 completed"
                    />
                    <path
                        id="Combined_Shape-7"
                        data-name="Combined Shape-7"
                        d="M558.517,774.6l-126.579-28.88a186.244,186.244,0,0,1,89.936-112.172L578.023,750.13a17.385,17.385,0,0,1-15.516,24.923A17.871,17.871,0,0,1,558.517,774.6Z"
                        transform="translate(-424.821 -611.03)"
                        className="dimension dimension-6 completed"
                    />
                    <circle
                        id="Ellipse_11"
                        data-name="Ellipse 11"
                        cx="98.216"
                        cy="98.216"
                        r="98.216"
                        transform="translate(87.231 87.269)"
                        fill="#34268e"
                    />
                </g>
            </g>

            <g
                id="completed"
                transform="translate(-626.595 -427.455)"
                style={{ isolation: 'isolate' }}
            >
                <text
                    id="completed"
                    transform="translate(959.328 670.783)"
                    fill="#fff"
                    fontSize="22"
                    fontFamily="Montserrat SemiBold, Montserrat"
                    fontWeight="600"
                    letterSpacing="-0.031em"
                    style={{ isolation: 'isolate' }}
                >
                    <tspan x="-61.039" y="0">
                        <Trans i18nKey="wheel.completedUpper" ns="common" />
                    </tspan>
                </text>
            </g>
        </svg>
    );
}
