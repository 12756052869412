import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Dimension,
    IBarRadius,
    IOffset,
    ISegment,
    ISegmentTextProps,
    IVector2,
} from '../../../../types/shared';
import { getTextWidth } from '../../../../utils';
import { DrawSegments } from '@components/wheel/wheelSlicesDynamic';

interface ICompletionWheel {
    dimensions: Dimension[];
}

const GetSegments = (segs: number): ISegment[] => {
    const segments: ISegment[] = [];
    for (let i = 0; i < segs; i++) {
        segments.push({ rotation: i * (360 / segs) });
    }
    return segments;
};

const CompletionWheel: FC<ICompletionWheel> = ({ dimensions }) => {
    const segs = dimensions.length;
    const segments = GetSegments(segs);
    const offset: IVector2 = { x: 322, y: 234 };
    const scale: IVector2 = { x: 3, y: 3 };
    const barRadius: IBarRadius = { innerRadius: 30, outerRadius: 63 };
    const spacing = 3;
    const completeRadius = 95;
    return (
        <svg
            width="642"
            height="463.68"
            viewBox="0 0 642 463.68"
            className="wheel"
            xmlns="http://www.w3.org/2000/svg"
        >
            {segments.map((segment, index) => (
                <DrawSegments
                    key={index}
                    offset={offset}
                    scale={scale}
                    index={index}
                    totalSegs={dimensions.length}
                    dimension={dimensions[index]}
                    segment={segment}
                    spacing={spacing}
                    barRadius={barRadius}
                    segmentTextComponent={WheelText}
                />
            ))}
            <CompletedText radius={completeRadius} color={'#2C1C77'} offset={offset} />
        </svg>
    );
};

interface ICompletedText {
    radius: number;
    color: string;
    offset: IOffset;
}

const CompletedText: FC<ICompletedText> = ({ radius, color, offset }) => {
    const { t } = useTranslation(['pages']);
    const text = 'COMPLETED';
    const fontSize = 20;
    const textWidth = useMemo(
        () =>
            getTextWidth(text, `normal 600 ${fontSize}px 'Montserrat SemiBold, Montserrat'`)
                ?.width ?? 0,
        [],
    );
    const textPosition: IVector2 = { x: offset.x - textWidth * 0.5, y: offset.y + fontSize * 0.25 };
    return (
        <g>
            <filter id="shadow">
                <feDropShadow
                    dx="0"
                    dy="0"
                    stdDeviation="10"
                    floodColor="#000000"
                    floodOpacity="0.5"
                />
            </filter>

            <ellipse
                cx={offset.x}
                cy={offset.y}
                rx={radius}
                ry={radius}
                fill={color}
                filter={'url(#shadow)'}
            />
            <text
                x={textPosition.x}
                y={textPosition.y}
                name={text}
                fontSize={fontSize}
                fontFamily="Montserrat SemiBold, Montserrat"
                fontWeight="600"
            >
                <tspan fill="white">{t('sevenDimensions.wheelCompleted')}</tspan>
            </text>
        </g>
    );
};

const WheelText: FC<ISegmentTextProps> = ({
    transform,
    onClickDimension,
    name,
    dimension,
    centerTextTransform,
}) => (
    <text
        transform={transform + centerTextTransform}
        fontSize="20"
        fontFamily="Montserrat SemiBold, Montserrat"
        fontWeight="600"
        z={-1}
        onClick={() => {
            if (onClickDimension) {
                onClickDimension(dimension);
            }
        }}
    >
        <tspan>{name}</tspan>
    </text>
);

export default CompletionWheel;
