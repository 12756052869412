import { Switch, Route, useLocation, Redirect } from 'react-router-dom';

import Home from '@pages/home/Home';
import { useDispatch } from 'react-redux';
import { setHeaderState } from '@actions/header/toggleHeader';
import { setFooterState } from '@actions/footer/toggleFooter';
import Support from '@pages/support/Support';
import Privacy from '@pages/privacy/Privacy';
import ResultsForPDF from '@pages/results/ResultsForPDF';

const Routes = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const routesWithoutHeader = ['/', '/support'];
    dispatch(setHeaderState(!routesWithoutHeader.includes(location.pathname)));
    dispatch(setFooterState(!routesWithoutHeader.includes(location.pathname)));

    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/privacy">
                <Privacy />
            </Route>
            <Route exact path="/support">
                <Support />
            </Route>

            <Route exact path="/results/:resumeCode/:languageCode">
                <ResultsForPDF />
            </Route>

            <Route exact path="/q/:accessCode">
                <Home />
            </Route>

            <Redirect to="/" />
        </Switch>
    );
};

export default Routes;
