import { ScoreBrackets } from './enums';

export function getRatingFromBracket(scoreBracket: ScoreBrackets) {
    switch (scoreBracket) {
        case ScoreBrackets.COULD_IMPROVE:
            return 'COULD IMPROVE';
        case ScoreBrackets.DOING_WELL:
            return 'DOING WELL';
        default:
            return 'THRIVING';
    }
}

export function getRatingClassFromBracket(scoreBracket: ScoreBrackets) {
    switch (scoreBracket) {
        case ScoreBrackets.COULD_IMPROVE:
            return 'improve';
        case ScoreBrackets.DOING_WELL:
            return 'well';
        default:
            return 'thriving';
    }
}
