import { useCallback, useEffect, useState } from 'react';
import i18next from 'i18next';
import QuestionData from 'src/types/shared/QuestionData';
import { QuestionnairePages, Dimension } from '../../../types/shared';
import YourAssessment from './yourAssessment/YourAssessment';
import Onboarding from './onboarding/Onboarding';
import SevenDimensions from './sevenDimensions/Dimensions';
import Results from './results/Results';
import Thanks from './thanks/Thanks';
import useQuestionnairePage from './hooks/useQuestionnairePage';
import useOnboardingSections from './hooks/useOnboardingSections';
import useTubeMapStops from './hooks/useTubeMapStops';
import useDimensionResume from './hooks/useDimensionResume';

import { StatusLine } from '../../../components/tubeMap/StatusLine';

interface Props {
    questionData: QuestionData;
    hasQuickLink: boolean;
}

export default function Questionnaire({ questionData, hasQuickLink }: Props) {
    const [language, setLanguage] = useState('en');
    const [page, next, setPage] = useQuestionnairePage(questionData.resumeCode);
    const [currentSection, setCurrentSection, isLastSection, isFirstSection] =
        useOnboardingSections(questionData);
    const [
        tubeMapData,
        updateStopMapFromOnboardingSection,
        updateStopMapFromDimensionSection,
        completeSectionStops,
        reinitTubeMap,
    ] = useTubeMapStops(questionData);
    const dimensionIndex = useDimensionResume(questionData);

    const updateCurrentSection = (section: number) => {
        setCurrentSection(section);
        updateStopMapFromOnboardingSection(questionData.onboardingSections[section]);
    };

    const updateCurrentDimension = useCallback(
        (dimension: Dimension | null) => {
            if (dimension) {
                updateStopMapFromDimensionSection(dimension);
            }
        },
        [updateStopMapFromDimensionSection],
    );

    // Handle the language change from the fallback (en) to czech
    // and reinit the tube map with translations from that language
    // while retaining any resume index
    const handleLanguageChanged = useCallback(() => {
        if (i18next.resolvedLanguage && i18next.resolvedLanguage !== language) {
            setLanguage(i18next.resolvedLanguage);
            if (questionData.resumeCode) {
                reinitTubeMap(dimensionIndex);
            } else {
                reinitTubeMap();
            }
        }
    }, [language, reinitTubeMap, dimensionIndex, questionData]);

    useEffect(() => {
        i18next.on('languageChanged', handleLanguageChanged);
        return () => {
            i18next.off('languageChanged', handleLanguageChanged);
        };
    }, [handleLanguageChanged]);

    const handlePageChange = useCallback(
        (page: number) => {
            setPage(page);
        },
        [setPage],
    );

    switch (page) {
        case QuestionnairePages.yourAssessment:
            if (hasQuickLink) next();
            return <YourAssessment onComplete={next} />;
        case QuestionnairePages.onboarding:
            if (!questionData.onboardingSections.length) next(); // Skip onboardingquestions if no questions are present
            return (
                <Onboarding
                    questionData={questionData}
                    onComplete={next}
                    currentSection={currentSection}
                    setCurrentSection={updateCurrentSection}
                    isLastSection={isLastSection}
                    isFirstSection={isFirstSection}
                >
                    <StatusLine data={tubeMapData} />
                </Onboarding>
            );
        case QuestionnairePages.dimensions:
            completeSectionStops('About You', null);
            if (questionData.resumeCode) {
                // We are using a resume code in dimensions.
                // Skip the onboarding and fill in from wellbeing to
                // our current todo dimension index
                completeSectionStops('Wellbeing Assessment', dimensionIndex + 3);
            }
            return (
                <SevenDimensions
                    currentDimension={questionData.resumeCode ? dimensionIndex : 0}
                    updateDimension={updateCurrentDimension}
                    questionData={questionData}
                    onComplete={next}
                    setPage={handlePageChange}
                >
                    <StatusLine data={tubeMapData} />
                </SevenDimensions>
            );
        case QuestionnairePages.results:
            return <Results onComplete={next} />;
        case QuestionnairePages.thanks:
            return <Thanks />;
        default:
            return null;
    }
}
