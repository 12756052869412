import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    QuestionTypes,
    SectionQuestion,
    SelectedAnswer,
} from '../../../../types/shared/QuestionData';

import TextInput from 'lib/src/components/form/TextInput';
import Select from 'lib/src/components/form/Select';
import Radio from 'lib/src/components/form/Radio';
import Slider from 'lib/src/components/form/Slider';
import Checkboxes from 'lib/src/components/form/Checkboxes';
import MultiSelect from 'lib/src/components/form/MultiSelect';
import config from '../../../../config';
const { S3_URL } = config;

interface Props {
    title?: string;
    description: string;
    img: string | null;
    questions: SectionQuestion[];
    answers: SelectedAnswer[];
    handleChange: (questionID: number, answer: string | null) => void;
    selectAnswer: (questionID: number, answerID: number | number[] | null, score?: number) => void;
    length: number;
    sectionNumber: number;
    setNavValue: Dispatch<SetStateAction<1 | -1 | null>>;
}

interface IQuestionGrid {
    children: React.ReactNode;
}
const QuestionGrid: FC<IQuestionGrid> = ({ children }) => (
    <div className="question-grid">{children}</div>
);

export default function CustomSection({
    title = '',
    description,
    img,
    questions,
    answers,
    handleChange,
    selectAnswer,
    length,
    sectionNumber,
    setNavValue,
}: Props) {
    const { t } = useTranslation('common');
    useEffect(() => {
        setNavValue(null);
    }, [setNavValue]);

    return (
        <div className="question-group">
            <div className="container flex-row align-center wrapper">
                {!!img ? (
                    <img src={`${S3_URL}/${img}`} alt="Section graphic" />
                ) : (
                    <div className="dummy-image" />
                )}

                <div className="questions">
                    {title && <h2 className="title">{`${title} (${sectionNumber}/${length})`}</h2>}
                    {!!description && <p className="description">{description}</p>}
                    <div className="selects">
                        {questions.map((question, i) => {
                            switch (question.type) {
                                case QuestionTypes.Dropdown:
                                    return (
                                        <QuestionGrid key={i}>
                                            <Select
                                                name={question.text}
                                                label={question.text}
                                                value={
                                                    answers.find(
                                                        answer => answer.questionID === question.id,
                                                    )?.answerID || null
                                                }
                                                required={question?.isRequired}
                                                onChange={(_, value) =>
                                                    selectAnswer(question.id, value)
                                                }
                                                options={question.answers.map(answer => ({
                                                    value: answer.id,
                                                    label: answer.text,
                                                }))}
                                                placeholder={t('form.select.placeHolder')}
                                                emptyValidationMsg={t('form.validationEmpty')}
                                            />
                                        </QuestionGrid>
                                    );
                                case QuestionTypes.TextInput:
                                    return (
                                        <QuestionGrid key={i}>
                                            <TextInput
                                                name={question.text}
                                                label={question.text}
                                                value={
                                                    answers.find(
                                                        answer => answer.questionID === question.id,
                                                    )?.answer || ''
                                                }
                                                required={question?.isRequired}
                                                onChange={(_, value) =>
                                                    handleChange(question.id, value)
                                                }
                                                placeholder={t('form.textInput.placeHolder')}
                                                emptyValidationMsg={t('form.validationEmpty')}
                                            />
                                        </QuestionGrid>
                                    );
                                case QuestionTypes.Radio:
                                    return (
                                        <QuestionGrid key={i}>
                                            <Radio
                                                name={question.text}
                                                label={question.text}
                                                value={
                                                    answers.find(
                                                        answer => answer.questionID === question.id,
                                                    )?.answerID || null
                                                }
                                                required={question?.isRequired}
                                                options={question.answers.map(answer => ({
                                                    value: answer.id,
                                                    label: answer.text,
                                                }))}
                                                onChange={(_, value) =>
                                                    selectAnswer(question.id, value)
                                                }
                                            />
                                        </QuestionGrid>
                                    );
                                case QuestionTypes.Slider:
                                    const _value =
                                        answers.find(answer => answer.questionID === question.id)
                                            ?.answer || '0';
                                    return (
                                        <QuestionGrid key={i}>
                                            <Slider
                                                name={question.text}
                                                label={question.text}
                                                value={Number(_value)}
                                                onChange={(_, value) =>
                                                    handleChange(question.id, '' + value)
                                                }
                                                required={question?.isRequired}
                                                min={question.sliderOptions?.minValue || 0}
                                                max={question.sliderOptions?.maxValue || 10}
                                                step={question.sliderOptions?.increment || 1}
                                            />
                                        </QuestionGrid>
                                    );
                                case QuestionTypes.Checkbox:
                                    return (
                                        <QuestionGrid key={i}>
                                            <Checkboxes
                                                name={question.text}
                                                label={question.text}
                                                options={question.answers.map(answer => ({
                                                    value: answer.id,
                                                    label: answer.text,
                                                }))}
                                                value={
                                                    answers
                                                        .filter(
                                                            answer =>
                                                                answer.questionID === question.id,
                                                        )
                                                        .map(a => a.answerID as number) || []
                                                }
                                                onChange={(_, value) => {
                                                    selectAnswer(question.id, value);
                                                }}
                                                required={question?.isRequired}
                                                emptyValidationMsg={t('form.validationEmpty')}
                                            />
                                        </QuestionGrid>
                                    );
                                case QuestionTypes.MultiSelect:
                                    return (
                                        <QuestionGrid key={i}>
                                            <MultiSelect
                                                name={question.text}
                                                label={question.text}
                                                options={question.answers.map(answer => ({
                                                    value: answer.id,
                                                    label: answer.text,
                                                }))}
                                                value={
                                                    answers
                                                        .filter(
                                                            answer =>
                                                                answer.questionID === question.id,
                                                        )
                                                        .map(a => a.answerID as number) || []
                                                }
                                                onChange={(_, value) =>
                                                    selectAnswer(question.id, value)
                                                }
                                                required={question?.isRequired}
                                                placeholder={t('form.multiSelect.placeHolder')}
                                                emptyValidationMsg={t('form.validationEmpty')}
                                            />
                                        </QuestionGrid>
                                    );
                                default:
                                    return null;
                            }
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
