import React from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Footer from '@components/footer/Footer';
import Routes from './routes';

import Header from './components/header/Header';
import useGlobalRedirect from 'lib/src/hooks/useGlobalRedirect';

import commonTranslationEN from './locales/en/common.json';
import pagesTranslationEN from './locales/en/pages.json';
import commonTranslationCS from './locales/cs/common.json';
import pagesTranslationCS from './locales/cs/pages.json';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            common: commonTranslationEN,
            pages: pagesTranslationEN,
        },
        cs: {
            common: commonTranslationCS,
            pages: pagesTranslationCS,
        },
    },
    fallbackLng: 'en',
    returnNull: false,
});

const App: React.FC = () => {
    useGlobalRedirect();

    const { pathname } = useLocation();

    return (
        <>
            {!pathname.includes('/results') && <Header />}
            <Routes />
            {!pathname.includes('/results') && <Footer />}
        </>
    );
};

export default App;
