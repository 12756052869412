import {
    fetchQuestionsError,
    fetchQuestionsRequest,
    fetchQuestionsSuccess,
} from '@actions/questions/getQuestions';
import {
    postQuestionsError,
    postQuestionsRequest,
    postQuestionsSuccess,
} from '@actions/questions/postQuestions';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import QuestionData from 'src/types/shared/QuestionData';
import { setOnboardingSectionAction } from '@actions/questions/setOnboardingSection';

interface QuestionsState {
    isFetching: boolean;
    fetchSuccess: boolean;
    error: string | null;
    isPosting: boolean;
    postSuccess: boolean;
    data: QuestionData | null;
    currentOnboardingSection: number;
}

const initialState: QuestionsState = {
    isFetching: false,
    fetchSuccess: false,
    error: null,
    isPosting: false,
    postSuccess: false,
    data: null,
    currentOnboardingSection: 0,
};

export default createReducer(initialState, {
    [fetchQuestionsRequest.type]: handleFetchRequest,
    [fetchQuestionsSuccess.type]: handleFetchSuccess,
    [fetchQuestionsError.type]: handleFetchError,
    [postQuestionsRequest.type]: handlePostRequest,
    [postQuestionsSuccess.type]: handlePostSuccess,
    [postQuestionsError.type]: handlePostError,
    [setOnboardingSectionAction.type]: handleSetOnboardingAction,
});

function handleFetchRequest(state: QuestionsState) {
    state.isFetching = true;
    state.fetchSuccess = false;
    state.error = null;
}

function handleFetchSuccess(state: QuestionsState, action: PayloadAction<QuestionData>) {
    state.isFetching = false;
    state.fetchSuccess = true;
    state.data = action.payload;
}

function handleFetchError(state: QuestionsState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
}

function handlePostRequest(state: QuestionsState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;
}

function handlePostSuccess(state: QuestionsState, action: PayloadAction<QuestionData>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.data = action.payload;
}

function handlePostError(state: QuestionsState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.error = action.payload;
}
function handleSetOnboardingAction(state: QuestionsState, action: PayloadAction<number>) {
    state.currentOnboardingSection = action.payload;
}
