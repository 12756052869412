import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { slices } from '../../constants/wheelSlices';

interface Props {
    score: number | null;
    showYourScore?: boolean;
}

export default function ResultsWheel({ score, showYourScore = false }: Props) {
    const { t } = useTranslation(['common']);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 346.01 345.96"
            className="fixed-results-wheel"
            style={{ width: '100%' }}
        >
            <defs>
                {slices.map((slice, i) => (
                    <Fragment key={i}>
                        <filter
                            id={`Path-${slice.dimension}`}
                            x={slice.results.filter.x}
                            y={slice.results.filter.y}
                            width={slice.results.filter.width}
                            height={slice.results.filter.height}
                            filterUnits="userSpaceOnUse"
                        >
                            <feOffset dy="2" />
                            <feGaussianBlur stdDeviation="6" result={`blur-${slice.dimension}`} />
                            <feFlood floodOpacity="0.102" />
                            <feComposite operator="in" in2={`blur-${slice.dimension}`} />
                            <feComposite in="SourceGraphic" />
                        </filter>
                        <clipPath id={`clipPath-${slice.dimension}`}>
                            <path
                                id={`Mask-${slice.dimension}`}
                                data-name="Mask"
                                d={slice.results.clip.d}
                                transform={`translate(${slice.results.clip.translate})`}
                                fill="#fff"
                            />
                        </clipPath>
                    </Fragment>
                ))}
                <filter
                    id="bg_overall_score"
                    x="75"
                    y="72"
                    width="196"
                    height="196"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset />
                    <feGaussianBlur stdDeviation="6" result="blur-8" />
                    <feFlood floodOpacity="0.098" />
                    <feComposite operator="in" in2="blur-8" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Group_30"
                data-name="Group 30"
                transform="translate(18 16)"
                // className="dimension"
            >
                {slices.map((slice, i) => (
                    <g
                        key={i}
                        transform="matrix(1, 0, 0, 1, -18, -16)"
                        filter={`url(#Path-${slice.dimension})`}
                    >
                        <path
                            id={`Path-${slice.dimension}`}
                            data-name="Path"
                            d={slice.results.clip.d}
                            transform={`translate(${slice.results.clip.translate2})`}
                            className={`results-dimension dimension-${slice.dimension}`}
                        />
                    </g>
                ))}
            </g>
            <g id="Group_30_Copy" data-name="Group 30 Copy" transform="translate(18 16)">
                {slices.map((slice, i) => (
                    <g
                        key={i}
                        id={`Combined_Shape-${slice.dimension}`}
                        data-name="Combined Shape"
                        transform={`translate(${slice.results.shape.translate2})`}
                    >
                        <g
                            id={`Combined_Shape-${slice.dimension + 7}`}
                            data-name="Combined Shape"
                            clipPath={`url(#clipPath-${slice.dimension})`}
                        >
                            <path
                                id={`Combined_Shape-${slice.dimension + 14}`}
                                data-name="Combined Shape"
                                d={slice.results.shape.d}
                                transform={`translate(${slice.results.shape.translate})`}
                                className={`dimension dimension-${slice.dimension}`}
                            />
                        </g>
                    </g>
                ))}
            </g>
            <g id="_chart_rcem" transform="translate(19 16)">
                <g id="_chart_overall">
                    <g id="score__overall" transform="translate(74 74)">
                        <g transform="matrix(1, 0, 0, 1, -93, -90)" filter="url(#bg_overall_score)">
                            <circle
                                className="dimension center"
                                id="bg_overall_score-2"
                                data-name="bg_overall_score"
                                cx="80"
                                cy="80"
                                r="80"
                                transform="translate(93 90)"
                            />
                        </g>
                    </g>
                    {showYourScore && (
                        <g
                            id="Group_4_Copy_2"
                            data-name="Group 4 Copy 2"
                            transform="translate(117 175)"
                        >
                            <text
                                transform="translate(38 16)"
                                fill="#999"
                                fontSize="9.1"
                                fontFamily="Montserrat-SemiBold, Montserrat, sans-serif"
                                fontWeight="600"
                                letterSpacing="-0.031em"
                                textAnchor="middle"
                                className="center-title"
                            >
                                <tspan className="print">{t('wheel.yourScore')}</tspan>
                            </text>
                        </g>
                    )}
                </g>
                <g id="score__overall-2" data-name="score__overall" transform="translate(120 110)">
                    <g id="score">
                        <text
                            id="_66"
                            data-name="66"
                            transform="translate(34 64)"
                            fill="#393f44"
                            fontSize="60"
                            fontFamily="Montserrat-Regular, Montserrat, sans-serif"
                            letterSpacing="-0.008em"
                            textAnchor="middle"
                        >
                            <tspan x="0" y="0">
                                {score ? Math.round(score) : 'N/A'}
                            </tspan>
                        </text>
                    </g>
                </g>
            </g>
        </svg>
    );
}
