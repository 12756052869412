import { useState } from 'react';
import { IVector2 } from 'src/types/shared';
import { useSpring, config, a } from 'react-spring';

interface ICursorConfig {
    position: IVector2;
}
export default function Cursor({ position }: ICursorConfig) {
    const { x, y } = position;
    const [flip, set] = useState<boolean>(false);
    const [hovered, setHovered] = useState<boolean>(false);
    const secondValues = { x: x + 200, y: y - 30 };
    const spring = useSpring({
        reset: false,
        reverse: flip && !hovered,
        from: { transform: `translate(${x} ${y})` },
        to: { transform: `translate(${secondValues.x} ${secondValues.y})` },
        config: config.slow,
        delay: 1000,
        onRest: () => set(!flip),
    });

    const opacitySpring = useSpring({
        opacity: hovered ? 0 : 1,
    });

    const style = {
        pointerEvents: hovered ? 'none' : 'auto',
    };

    return (
        <a.g onMouseEnter={() => setHovered(true)} {...style} {...spring} {...opacitySpring}>
            <rect opacity={0} width={125} height={125} transform={`translate(-50,-50)`} />
            <path
                d="M0,25.853
                l.054-25.838,
                18.238,
                18.292-7.386,
                3.066,
                4.373,
                10.426-4.053,
                1.706L6.878,
                23Z"
                className="cursor"
            />
        </a.g>
    );
}
