import { fetchQuestions } from '@actions/questions/getQuestions';
import {
    getQuestionsIsFetching,
    getQuestionsFetchSuccess,
    getQuestionsFetchError,
    getQuestionsData,
} from '@selectors/questions';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useAccessCodeForm = () => {
    const dispatch = useDispatch();
    const [code, setCode] = useState('');
    const [privacyAgree, setPrivacyAgree] = useState(false);
    const [authorizedConfirm, setAuthorizedConfirm] = useState(false);
    const [sentCode, setSentCode] = useState<string | null>(null);

    const isFetching = useSelector(getQuestionsIsFetching);
    const fetchSuccess = useSelector(getQuestionsFetchSuccess);
    const fetchError = useSelector(getQuestionsFetchError);
    const data = useSelector(getQuestionsData);

    const onCodeChange = (_name: string, value: string) => {
        setCode(value);
    };

    const onPrivacyAgreeChange = (_name: string, value: boolean) => {
        setPrivacyAgree(value);
    };

    const onAuthorizedConfirmChange = (_name: string, value: boolean) => {
        setAuthorizedConfirm(value);
    };

    const onSubmit = () => {
        setSentCode(code);
        dispatch(fetchQuestions(code));
    };

    return {
        code,
        privacyAgree,
        authorizedConfirm,
        sentCode,
        onCodeChange,
        onPrivacyAgreeChange,
        onAuthorizedConfirmChange,
        onSubmit,
        isFetching,
        fetchSuccess,
        fetchError,
        data,
    };
};

export default useAccessCodeForm;
