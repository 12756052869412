import { DimensionColours } from 'src/types/shared/QuestionData';

export const dimensionColours: DimensionColours = {
    1: 'yellow',
    2: 'red',
    3: 'pink',
    4: 'purple',
    5: 'blue',
    6: 'cyan',
    7: 'green',
    8: 'dark-red',
    9: 'red-low',
    10: 'pink',
};
