import { useTranslation } from 'react-i18next';
interface Props {
    score?: number | null;
    rating?: string;
    ratingClass?: string;
}

export default function ScoreBanner({ score }: Props) {
    const { t } = useTranslation(['pages']);
    return (
        <div className="score-banner">
            <div className="container flex-row wrapper">
                <div className="flex-column justify-start text-wrapper">
                    <h2 className="title">{t('results.scoreBanner.title')}</h2>

                    <p className="text">{t('results.scoreBanner.subTitle')}</p>

                    <p className="text info">{t('results.scoreBanner.info')}</p>
                </div>

                <div className="flex-column align-center justify-center score-wrapper">
                    {score && (
                        <>
                            <p className="flex-column justify-center score">{Math.round(score)}</p>
                            <div className="title-container">
                                <h3 className="title">{t('results.scoreBanner.fitnessScore')}</h3>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
