import React, { SyntheticEvent } from 'react';

import useFieldValidation from '../../hooks/useFieldValidation';
import { DropdownOption } from '../../types/shared/DropdownOption';
import { FormInputProps } from '../../types/shared/FormInputProps';
import FormField from './FormField';

const Slider: React.FC<RangeInputProps> = ({
    name,
    value,
    onChange,
    label = '',
    required = false,
    min = 0,
    max = 100,
    step = 1,
    disabled,
    customValidate,
    fieldClassName,
    forceAsterisk = false,
    stepMarkers,
    hideLabels
}) => {
    const [error, showError] = useFieldValidation({
        name,
        required,
        value,
        customValidate,
    });
    const scoreClass = value < 0.2 ? 2 : value < 0.4 ? 3 : value < 0.6 ? 4 : 5;
    return (
        <FormField
            name={name}
            label={label}
            required={required}
            error={error}
            className={fieldClassName}
        >
            {!hideLabels && <div className="slider-labels">
                <span>{min}</span>
                <span>{value}</span>
                <span>{max}</span>
            </div>}

            <input
                type="range"
                className={`form-input assessment-slider ${
                    error ? 'error' : ''
                } score-${scoreClass}`}
                name={name}
                id={name}
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                />
            <div className="assessment-slider-ratings flex-row justify-between">
                {stepMarkers?.map(({ label, value }, i) => (
                    <p key={i} onClick={() => onChange(name, value)}>{label}</p>
                ))}
            </div>
        </FormField>
    );

    function handleBlur() {
        showError();
    }

    function handleChange(e: SyntheticEvent) {
        e.preventDefault();
        const newVal = +(e.target as HTMLInputElement).value;
        onChange(name, newVal);
    }
};

interface RangeInputProps extends FormInputProps<number> {
    label?: string;
    min?: number;
    max?: number;
    step?: number;
    fieldClassName?: string;
    forceAsterisk?: boolean;
    stepMarkers?: DropdownOption<number>[];
    hideLabels?: boolean
}

export default Slider;
