import axios from 'axios';

import { useSelector } from 'react-redux';
import { useState } from 'react';
import { getResumeCode } from '@selectors/questions';
import { selectCurrentLanguage } from '@selectors/pages';

import { API_URL } from 'lib/src/utils/api';

const useDownloadPDF = () => {
    const resumeCode = useSelector(getResumeCode);
    const languageCode = useSelector(selectCurrentLanguage);
    const [isFetching, setIsFetching] = useState(false);

    const handleDownload = async () => {
        setIsFetching(true);
        const { data } = await axios.get(
            `${API_URL}/Questionnaire/results/${resumeCode}/download/${languageCode}`,
            {
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob',
            },
        );
        const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
        const link = document.createElement('a');

        setIsFetching(false);

        link.href = url;
        link.setAttribute('download', 'results.pdf');
        document.body.append(link);
        link.click();
        link.remove();

        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    };

    return { handleDownload, isFetching };
};

export default useDownloadPDF;
