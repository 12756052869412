import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api } from 'lib/src/utils/api';
import QuestionData, { QuestionnairePostBody } from 'src/types/shared/QuestionData';

export const postQuestionsRequest = createAction('postQuestionsRequest');
export const postQuestionsSuccess = createAction<QuestionData>('postQuestionsSuccess');
export const postQuestionsError = createAction<string | null>('postQuestionsError');

export const postQuestions =
    (postBody: QuestionnairePostBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postQuestionsRequest());
        try {
            const res = await api.post<QuestionnairePostBody, QuestionData>(
                `questionnaire`,
                postBody,
            );
            dispatch(postQuestionsSuccess(res.data));
        } catch (err) {
            dispatch(postQuestionsError(err as string | null));
        }
    };
