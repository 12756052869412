import React from 'react';

const ActionBar: React.FC<ActionBarProps> = ({ children, className = '' }) => {
    return <div className={`action-bar ${className}`}>{children}</div>;
};

interface ActionBarProps {
    className?: string;
}

export default ActionBar;
