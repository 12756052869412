import { DrawSegments } from '@components/wheel/wheelSlicesDynamic';
import { FC, ReactNode, useMemo } from 'react';
import { IBarRadius, IBox, ISegment, ISegmentTextProps, IVector2 } from 'src/types/shared';
import { ResultDimension } from 'src/types/shared/QuestionData';
import {
    addVectors,
    getRatingClassFromBracket,
    getTextWidth,
    multVector,
    subtractVectors,
} from '../../../../utils';

interface Props {
    dimensions: ResultDimension[];
    setDimension: (dimension: ResultDimension) => void;
    selected: ResultDimension | null;
    score: number | null;
    hideCenterText?: boolean;
    shouldDrawBackground?: boolean;
    hasShadow?: boolean;
    size?: IBox;
    showDescription?: boolean;
}

export default function ResultsWheel({
    dimensions,
    setDimension,
    score,
    shouldDrawBackground,
    showDescription,
    hasShadow,
    size,
}: Props) {
    const segs = dimensions.length;
    const segments: ISegment[] = [];
    for (let i = 0; i < segs; i++) {
        segments.push({ rotation: i * (360 / segs) });
    }

    const offset: IVector2 = { x: 0, y: 0 };
    const scale: IVector2 = { x: 3, y: 3 };
    const barRadius: IBarRadius = { innerRadius: 10, outerRadius: 100 };
    return (
        <svg
            width={size ? size.width : '315'}
            height={size ? size.height : '315'}
            viewBox="-350 -350 700 700"
            className="results-wheel"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: '100%' }}
        >
            <filter id="wheelShadow">
                <feDropShadow
                    dx="0"
                    dy="0"
                    stdDeviation="5"
                    floodColor="#c7c7c7"
                    floodOpacity="0.6"
                />
            </filter>
            {shouldDrawBackground && (
                <circle cx={offset.x} cy={offset.y} r={307} className={'wheel-bg'} />
            )}
            {segments.map((segment, index) => (
                <DrawSegment
                    key={index}
                    index={index}
                    segment={segment}
                    scale={scale}
                    offset={offset}
                    barRadius={barRadius}
                    dimensions={dimensions}
                    setDimension={setDimension}
                    hasShadow={hasShadow}
                />
            ))}

            <CenterScore offset={offset} fontSize={60} showDescription={!!score && showDescription}>
                {score ? Math.round(score) : 'N/A'}
            </CenterScore>
        </svg>
    );
}

interface IDrawSegment {
    index: number;
    segment: ISegment;
    scale: IVector2;
    offset: IVector2;
    barRadius: IBarRadius;
    dimensions: ResultDimension[];
    setDimension: (dimension: ResultDimension) => void;
    hasShadow?: boolean;
}
const DrawSegment: FC<IDrawSegment> = ({
    index,
    offset,
    scale,
    dimensions,
    setDimension,
    barRadius,
    segment,
    hasShadow,
}) => {
    const LipSegmentRadii: IBarRadius = {
        innerRadius: barRadius.outerRadius - 2,
        outerRadius: barRadius.outerRadius + 2,
    };
    const dimension: ResultDimension = dimensions[index];
    const scoreBracket = dimension.scoreBracket;
    const dimensionRatingClass = !scoreBracket ? '' : getRatingClassFromBracket(scoreBracket);

    return (
        <>
            <g key={index} filter={hasShadow ? 'url(#wheelShadow)' : ''}>
                <DrawSegments
                    index={index}
                    offset={offset}
                    scale={scale}
                    opacity={{ from: 1, to: 0.4 }}
                    fill={'#ffffff'}
                    spacing={1.5}
                    dimension={{
                        id: dimension.dimensionID,
                        name: dimension.dimensionName,
                        description: dimension.dimensionScore?.toString() ?? '',
                        imageS3Key: '',
                        sort: index,
                        questions: [],
                    }}
                    segment={segment}
                    onClickDimension={b => {
                        setDimension(dimension);
                    }}
                    barRadius={barRadius}
                    segmentTextComponent={WheelText}
                    totalSegs={dimensions.length}
                />
                <DrawSegments
                    spacing={1.5}
                    offset={offset}
                    scale={scale}
                    index={index}
                    completedClass={dimensionRatingClass}
                    dimension={{
                        id: dimension.dimensionID,
                        name: '',
                        description: '',
                        imageS3Key: '',
                        sort: index,
                        questions: [],
                    }}
                    segment={segment}
                    barRadius={LipSegmentRadii}
                    totalSegs={dimensions.length}
                />
            </g>
        </>
    );
};

interface ICenterScoreProps {
    fontSize: number;
    children: ReactNode;
    offset: IVector2;
    showDescription?: boolean;
}
const CenterScore: FC<ICenterScoreProps> = ({ offset, fontSize, children, showDescription }) => {
    const text = children?.toString() ?? '';
    const [textWidth] = useMemo(
        () => [
            getTextWidth(
                text,
                `normal 400 ${fontSize}px 'Montserrat Regular, Montserrat, sans-serif'`,
            )?.width ?? 0,
        ],
        [fontSize, text],
    );
    const textPosition: IVector2 = {
        x: offset.x - textWidth * 0.5 - 3,
        y: offset.y + fontSize * 0.3,
    };
    const descriptionPosition: IVector2 = {
        x: 0,
        y: fontSize,
    };
    return (
        <g>
            <filter id="shadow">
                <feDropShadow
                    dx="0"
                    dy="0"
                    stdDeviation="10"
                    floodColor="#000000"
                    floodOpacity="0.4"
                />
            </filter>
            <circle cx={offset.x} cy={offset.y} r={150} fill={'white'} filter={'url(#shadow)'} />
            <g>
                <text transform="scale(2)" x={textPosition.x} y={textPosition.y} name={text}>
                    <tspan
                        fontStyle="normal"
                        fontWeight={'400'}
                        fontSize={`${fontSize}px`}
                        fontFamily="Montserrat-Regular, Montserrat, sans-serif"
                        fill="#393f44"
                    >
                        {text}
                    </tspan>
                </text>
                {showDescription && (
                    <text
                        transform={`translate(${descriptionPosition.x} ${descriptionPosition.y})`}
                        fill="#999"
                        fontSize="13px"
                        fontFamily="Montserrat-SemiBold, Montserrat, sans-serif"
                        fontWeight="600"
                        letterSpacing="-0.031em"
                        textAnchor="middle"
                        className="center-title"
                    >
                        <tspan className="print">Your score</tspan>
                    </text>
                )}
            </g>
        </g>
    );
};

const WheelText: FC<ISegmentTextProps> = ({
    transformVector,
    onClickDimension,
    name,
    dimension,
    offset,
}) => {
    const scaleOut = 0.65;
    const position = subtractVectors(transformVector, offset);
    const { x, y }: IVector2 = addVectors(multVector(position, scaleOut), offset);

    const textSize = 20;
    const score = dimension.description ? Math.round(+dimension.description).toString() : '';
    const scoreSize = score !== '' ? 40 : 0;
    const scoreSizeMiddle = scoreSize * 0.5;
    const textMetric = getTextWidth(
        name,
        `normal 400 ${textSize}px Montserrat-Regular, Montserrat, sans-serif`,
    );
    const textMetricScore = getTextWidth(
        score,
        `normal 500 ${scoreSize}px 'Montserrat-Regular, Montserrat, sans-serif'`,
    );

    const midTextWidth = (textMetric?.width ?? 0) * 0.5;
    const midTextScoreWidth = (textMetricScore?.width ?? 0) * 0.5;
    const scoreXPosition = midTextWidth - midTextScoreWidth;
    const transformPosition = `translate(${x} ${y - scoreSizeMiddle + 10})`;
    const centerTextTransform = `translate(${-midTextWidth} ${textSize * 0.25})`;
    const transformScoreText = `translate(${scoreXPosition}, ${scoreSize})`;
    return (
        <g z={100}>
            <text
                id={`${dimension.name}_${dimension.id}`}
                transform={transformPosition + centerTextTransform}
                fontSize={textSize}
                fontFamily="Montserrat-Medium, Montserrat, sans-serif"
                fill="black"
                fontStyle={'normal'}
                fontWeight="400"
                z={-1}
                onClick={() => {
                    if (onClickDimension) {
                        onClickDimension(dimension);
                    }
                }}
            >
                <tspan>{name}</tspan>
            </text>
            <text
                transform={transformPosition + centerTextTransform + transformScoreText}
                fontSize={scoreSize}
                fontFamily="Montserrat-Regular, Montserrat, sans-serif"
                fill={'#393f44'}
                fontStyle={'normal'}
                fontWeight="500"
                z={-1}
                onClick={() => {
                    if (onClickDimension) {
                        onClickDimension(dimension);
                    }
                }}
            >
                <tspan>{score}</tspan>
            </text>
        </g>
    );
};
