import React from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../../../config';

import { CouldImproveMessageType } from '../../../../types/shared/QuestionnaireResult';

const { S3_URL } = config;

const CouldImproveMessage = ({ couldImproveMessage }: Props) => {
    const { t } = useTranslation(['pages']);
    const { message, supportServiceLogoS3Key, supportLink } = couldImproveMessage;
    return (
        <div className="improvement-wrapper flex-row justify-center align-center">
            <section className="body container flex-row justify-center align-center">
                {!!supportServiceLogoS3Key && (
                    <div className="image-wrapper flex-4 flex-row justify-center">
                        <img src={`${S3_URL}/${supportServiceLogoS3Key}`} alt="Your company" />
                    </div>
                )}
                <div className="flex-column flex-8 justify-between message-wrapper">
                    <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: message }} />
                    {!!supportLink && (
                        <a
                            href={supportLink}
                            className="button blue flat form-button"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span className="text">{t('results.couldImproveMessage.visit')}</span>
                        </a>
                    )}
                </div>
            </section>
        </div>
    );
};

interface Props {
    couldImproveMessage: CouldImproveMessageType;
}

export default CouldImproveMessage;
