import { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimension, QuestionnairePages } from '../../../../types/shared';
import Title from '@components/title/Title';
import SingleDimension from './single/SingleDimension';
import Wheel from './Wheel';
import useCompleted from './hooks/useCompleted';
import ActionButton from 'lib/src/components/button/ActionButton';
import ActionBar from '@components/actionBar/ActionBar';
import AccessCodeModal from '@components/modals/AccessCodeModal';
import useGetResults from './hooks/useGetResults';
import WheelComplete from './WheelComplete';
import usePageLeaveWarn from '../../../../hooks/usePageLeaveWarn';
import QuestionData from 'src/types/shared/QuestionData';
import SevenDimensionWheelComplete from '@components/wheel/SevenDimensionWheelComplete';

interface Props {
    currentDimension?: number;
    questionData: QuestionData;
    onComplete: () => void;
    setPage: (val: number) => void;
    updateDimension?: (dimension: Dimension | null) => void;
    children?: React.ReactChild;
}

export default function Dimensions({
    currentDimension = 0,
    questionData,
    onComplete,
    setPage,
    updateDimension,
    children,
}: Props) {
    const { t } = useTranslation(['pages', 'common']);
    const { dimensions, dimensionAnswers, hideDimensionWheel, isScoring } = questionData;

    const [dimension, setDimension] = useState<Dimension | null>(null);
    const [modalState, setModalState] = useState<boolean>(false);
    // Handle setting dimension on first render as we move away from the wheel
    const hasSetFirstDimension = useRef(false);

    usePageLeaveWarn();

    const completed = useCompleted(dimensions, dimensionAnswers);

    const { isFetching, fetchSuccess, fetchError, getResults } = useGetResults();

    const handleDimensionChange = useCallback(
        (dimension: Dimension | null) => {
            setDimension(dimension);
            if (updateDimension) {
                updateDimension(dimension);
            }
        },
        [updateDimension],
    );

    // useEffect(() => {
    //     console.log({ action: 'ue dimension change', dimension, hideDimensionWheel });
    //     if (hideDimensionWheel && !dimension) {
    //         // setDimension(dimensions[0]);
    //         handleDimensionChange(dimensions[0]);
    //     }
    // }, [dimension, dimensions, handleDimensionChange, hideDimensionWheel]);

    useEffect(() => {
        if (fetchSuccess) onComplete();
    }, [fetchSuccess, onComplete]);

    useEffect(() => {
        // console.log({ action: 'On MOUNT DIMENSION CHANGE', dimension });
        // handleDimensionChange(dimension);
        window.scroll(0, 0);
        // }, [dimension, handleDimensionChange]);
    }, [dimension]);

    useEffect(() => {
        if (!hasSetFirstDimension.current) {
            handleDimensionChange(dimensions[currentDimension]);
            // setDimension(dimensions[0]);
            hasSetFirstDimension.current = true;
        }
    }, [currentDimension, dimensions, handleDimensionChange]);

    // todo override setDimension to setDimension and call optional updateDimension

    // const dimensionCountString = useMemo(
    //     () =>
    //         numberToWords(
    //             dimensions.length,
    //             i18n.t('numbers', '', { ns: 'common', returnObjects: true }),
    //         ),
    //     [dimensions.length, i18n],
    // );

    const isComplete = Object.values(completed).every(isComplete => isComplete);

    if (dimensions.length < 1 || (!isScoring && isComplete)) {
        setPage(QuestionnairePages.thanks);
    }

    if (!!dimension && !!questionData && !isComplete) {
        return children ? (
            <SingleDimension
                dimension={dimension}
                questionData={questionData}
                setDimension={handleDimensionChange}
                modalState={modalState}
                setModalState={setModalState}
            >
                {children}
            </SingleDimension>
        ) : (
            <SingleDimension
                dimension={dimension}
                questionData={questionData}
                setDimension={handleDimensionChange}
                modalState={modalState}
                setModalState={setModalState}
            />
        );
    }

    if (!isComplete && hideDimensionWheel) return <></>;
    // const titleString = {
    //     dimensionWordCount: hideDimensionWheel
    //         ? ''
    //         : ' ' + capitalizeFirstLetter(dimensionCountString),
    //     numberCount: dimensions.length,
    // };
    return (
        <div id="dimensions-page">
            {/* Change to "Our Dimensions" "Našich Oblastí" */}
            <Title title={t('sevenDimensions.dimensions.title')}>
                <h2 className="sub-title">{t('sevenDimensions.dimensions.subTitleViewResults')}</h2>
            </Title>
            <div className="flex-column align-center dimension-wrapper">
                {isComplete ? (
                    hideDimensionWheel ? (
                        <SevenDimensionWheelComplete />
                    ) : (
                        <WheelComplete dimensions={dimensions} />
                    )
                ) : (
                    <Wheel
                        dimensions={[...dimensions].sort((a, b) => a.sort - b.sort)}
                        completed={completed}
                        setDimension={handleDimensionChange}
                    />
                )}

                {isComplete ? (
                    <>
                        <p className="text complete">
                            {t('sevenDimensions.dimensions.congratulations')}
                        </p>
                    </>
                ) : (
                    <p className="text">{t('sevenDimensions.dimensions.selectADimension')}</p>
                )}
                {!!fetchError && (
                    <span className="button-error">{t('sevenDimensions.dimensions.error')}</span>
                )}
            </div>
            <ActionBar>
                {isComplete ? (
                    isScoring ? (
                        <ActionButton onClick={() => getResults()} isPosting={isFetching}>
                            {t('button.viewResults', { ns: 'common' })}
                        </ActionButton>
                    ) : (
                        <ActionButton
                            onClick={() => setPage(QuestionnairePages.thanks)}
                            isPosting={isFetching}
                        >
                            {t('button.finish', { ns: 'common' })}
                        </ActionButton>
                    )
                ) : (
                    <ActionButton onClick={() => setModalState(true)}>
                        {t('button.saveAndFinishLater', { ns: 'common' })}
                    </ActionButton>
                )}
            </ActionBar>
            <AccessCodeModal state={modalState} onClose={() => setModalState(false)} />
        </div>
    );
}
