import ActionBar from '@components/actionBar/ActionBar';
import Title from '@components/title/Title';
import { useTranslation } from 'react-i18next';
import deskImg from '../../../../_content/images/second-page-02.png';
import ActionButton from 'lib/src/components/button/ActionButton';
import usePageLeaveWarn from '../../../../../src/hooks/usePageLeaveWarn';
import AssessmentSlider from './AssessmentSlider';

const YourAssessment = ({ onComplete }: YourAssessmentProps) => {
    usePageLeaveWarn();
    const { t } = useTranslation(['pages', 'common']);

    return (
        <main id="your-assessment">
            <div className="align-center header-container">
                <div className="flex-row headers">
                    <div className="left-header">
                        <Title title={t('yourAssessment.yourAssessment.title')} />
                    </div>
                    <div className="right-header">
                        <img src={deskImg} className="right-header-img" alt="" />
                    </div>
                </div>
            </div>
            <AssessmentSlider />
            <ActionBar>
                <ActionButton onClick={onComplete}>
                    {t('button.continue', { ns: 'common' })}
                </ActionButton>
            </ActionBar>
        </main>
    );
};

interface YourAssessmentProps {
    onComplete: () => void;
}

export default YourAssessment;
