import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getResultsData, getResultsIsFetching } from '@selectors/results';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { fetchResults } from '@actions/results/getResults';
import Loading from '@components/loader/Loading';
import ResultsWheel from '@pages/home/questionnaire/results/ResultsWheel';
import ScoreRings from './ScoreRings';
import { dimensionColours } from '../../constants/dimensions';
import config from '../../config';
import StrengthIcon from '../../_content/images/reports/StrengthIcon.svg';
import DifficultyIcon from '../../_content/images/reports/DifficultyIcon.svg';
import QuestionnaireResult from '../../types/shared/QuestionnaireResult';
import SevenDimensionWheel from '../../components/wheel/SevenDimensionWheel';

const { S3_URL } = config;

interface IPageProps {
    resultsData: QuestionnaireResult;
}

export default function ResultsForPDFresults() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation(['pages']);
    const { resumeCode } = useParams<{ resumeCode: string | undefined }>();
    const { languageCode } = useParams<{ languageCode: string | undefined }>();

    const resultsData = useSelector(getResultsData);
    const isFetching = useSelector(getResultsIsFetching);

    useEffect(() => {
        if (resumeCode) {
            if (languageCode) {
                i18n.changeLanguage(languageCode);
            }
            dispatch(fetchResults(resumeCode));
        }
    }, [dispatch, resumeCode, i18n, languageCode]);

    if (!resultsData) {
        return isFetching ? <Loading /> : <div>{t('resultsForPDF.noResults')}</div>;
    }

    return (
        <>
            <PageMain resultsData={resultsData} />
            <PageDimensionBreakdown resultsData={resultsData} />
            <PageStrengthsWeaknesses resultsData={resultsData} />
            <PageDimensionsRecommendations resultsData={resultsData} />
            <PageCompanySupport resultsData={resultsData} />
            {/* <PageRecommendations resultsData={resultsData} /> */}
            <div id="results-page" />
        </>
    );
}

const PageMain: FC<IPageProps> = ({ resultsData }) => {
    const { t } = useTranslation(['pages']);
    const { dimensions, score, weaknesses, strengths, isScoring } = resultsData;

    const showSimplifiedWheel = dimensions.length > 10;
    const count = +(weaknesses.length > 0) + +(strengths.length > 0);
    const displayBoth = count >= 2;

    return (
        <main className="results-pdf page-1">
            <div className="breakdown-columns" style={{ marginTop: '-3em' }}>
                <div className="breakdown-column">
                    <div className="company-logo" />
                    <div>
                        <h1 className="h1-report">
                            <Trans
                                i18nKey="resultsForPDF.report"
                                ns="pages"
                                components={{ break: <br /> }}
                            />
                        </h1>
                    </div>
                </div>
                <div className="breakdown-column">
                    <div className="results-viewer">
                        <div className="wheel-wrapper">
                            {showSimplifiedWheel ? (
                                <SevenDimensionWheel
                                    score={isScoring ? score : null}
                                    showYourScore={isScoring}
                                />
                            ) : (
                                <ResultsWheel
                                    dimensions={dimensions}
                                    setDimension={() => {}}
                                    selected={null}
                                    score={score}
                                    hideCenterText
                                    shouldDrawBackground
                                    showDescription
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="breakdown-columns">
                <div className="breakdown-column">
                    {score && (
                        <div className="flex-column justify-center" style={{ height: '100%' }}>
                            <h1 className="h1-report big-score">{Math.round(+score)}</h1>
                            <h4 className="score-subtitle">{t('resultsForPDF.yourScore')}</h4>
                        </div>
                    )}
                </div>
                <div className="breakdown-column">
                    <div>
                        <h4>{t('resultsForPDF.mentalFitness')}</h4>
                    </div>
                    <div className="divider" style={{ marginLeft: '0' }} />
                    <p>{t('resultsForPDF.description')}</p>
                    <p>
                        <em>
                            <br />
                            {t('resultsForPDF.access')}
                        </em>
                    </p>
                </div>
            </div>
            {showSimplifiedWheel || !score ? (
                <div className="dimensions-too-many"></div>
            ) : (
                <ScoreRings score={score} dimensions={dimensions} />
            )}

            <div
                className="equal-columns"
                style={{ gridTemplateColumns: `repeat(${count}, 1fr 1px)` }}
            >
                {strengths.length > 0 && (
                    <div className="equal-column">
                        <img src={StrengthIcon} alt="strengths icon" className="report-icon" />
                        <h2 className="strength-heading">{t('resultsForPDF.areasOfStrength')}</h2>

                        <div className="flex-column">
                            {strengths.map((item, i) => (
                                <h4 key={i} className="summary-item">
                                    {item.subcategoryName}
                                </h4>
                            ))}
                        </div>
                    </div>
                )}
                {displayBoth && <div className="equal-column-divider" />}
                {weaknesses.length > 0 && (
                    <div className="equal-column">
                        <img src={DifficultyIcon} alt="strengths icon" className="report-icon" />
                        <h2 className="strength-heading flex-row">
                            {t('resultsForPDF.areasOfDifficulty')}
                        </h2>
                        <div className="flex-column">
                            {weaknesses.map((item, i) => (
                                <h4 key={i} className="summary-item">
                                    {item.subcategoryName}
                                </h4>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </main>
    );
};

const PageDimensionBreakdown: FC<IPageProps> = ({ resultsData }) => {
    const maxDimensionsToFitOnPage = 4;
    const recPagesToGenerate = Math.ceil(resultsData.dimensions.length / maxDimensionsToFitOnPage);
    return (
        <>
            {[...Array(recPagesToGenerate)].map((_, i) => (
                <React.Fragment key={i}>
                    <main className={`results-pdf page-${i === 0 ? '2' : '3'}`}>
                        {(i === 0 && (
                            <div className="breakdown-columns">
                                <div className="breakdown-column">
                                    <h2>
                                        <Trans
                                            i18nKey="resultsForPDF.dimensionBreakdown"
                                            ns="pages"
                                            components={{ break: <br /> }}
                                        />
                                    </h2>
                                </div>
                            </div>
                        )) || <div style={{ minHeight: '10em' }}></div>}

                        {resultsData.dimensions
                            .slice(
                                i * maxDimensionsToFitOnPage,
                                i * maxDimensionsToFitOnPage + maxDimensionsToFitOnPage,
                            )
                            .map((d, j) => (
                                <React.Fragment key={j}>
                                    <div className="breakdown-columns" key={j}>
                                        <div className="breakdown-column">
                                            <div className="flex-row justify-between">
                                                <h4
                                                    className={`dimension-${
                                                        i * maxDimensionsToFitOnPage + j
                                                    }`}
                                                >
                                                    {d.dimensionName}
                                                </h4>
                                                <h4>
                                                    {d.dimensionScore
                                                        ? Math.round(d.dimensionScore)
                                                        : ''}
                                                </h4>
                                            </div>
                                            {d.subcategories.map(
                                                ({ subcategoryName, subcategoryScore }, k) => (
                                                    <div
                                                        className="flex-row justify-between"
                                                        key={k}
                                                    >
                                                        <p>{subcategoryName}</p>
                                                        <p>
                                                            {subcategoryScore &&
                                                                Math.round(subcategoryScore)}
                                                        </p>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                        <div className="breakdown-column">
                                            <p>{d.dimensionScoreContent}</p>
                                        </div>
                                    </div>
                                    {j < 3 && <div className="divider" />}
                                </React.Fragment>
                            ))}
                    </main>
                </React.Fragment>
            ))}
        </>
    );
};

const PageStrengthsWeaknesses: FC<IPageProps> = ({ resultsData }) => {
    const { t } = useTranslation(['pages']);
    return (
        <main className="results-pdf page-4">
            <div className="breakdown-columns">
                <div className="breakdown-column">
                    <h2>
                        <Trans
                            i18nKey="resultsForPDF.areasOfStrengthAndDifficulty"
                            ns="pages"
                            components={{ break: <br /> }}
                        />
                    </h2>
                </div>
            </div>
            <div className="breakdown-columns" style={{ marginBottom: '3em' }}>
                {resultsData.strengths.length > 0 && (
                    <>
                        <div className="breakdown-column flex-row justify-center">
                            <img src={StrengthIcon} alt="strengths icon" className="report-icon" />
                        </div>
                        <div className="breakdown-column">
                            <h2 className="strength-heading flex-row align-end">
                                {t('resultsForPDF.areasOfStrength')}
                            </h2>
                        </div>
                    </>
                )}
            </div>

            {resultsData.strengths.map((s, i) => (
                <div className="breakdown-columns" key={i}>
                    <div className="breakdown-column flex-row justify-center">
                        <h3 className="strength-heading">{s.subcategoryName}</h3>
                    </div>
                    <div className="breakdown-column">
                        <ul>
                            <li>
                                <i className="tick" />
                                {s.content}
                            </li>
                        </ul>
                    </div>
                </div>
            ))}

            <div className="breakdown-columns" style={{ marginBottom: '3em' }}>
                {resultsData.weaknesses.length > 0 && (
                    <>
                        <div className="breakdown-column flex-row justify-center">
                            <img
                                src={DifficultyIcon}
                                alt="strengths icon"
                                className="report-icon"
                            />
                        </div>
                        <div className="breakdown-column">
                            <h2 className="strength-heading flex-row align-end">
                                {t('resultsForPDF.areasOfDifficulty')}
                            </h2>
                        </div>
                    </>
                )}
            </div>

            {resultsData.weaknesses.map((w, i) => (
                <div className="breakdown-columns" key={i}>
                    <div className="breakdown-column flex-row justify-center">
                        <h3 className="strength-heading">{w.subcategoryName}</h3>
                    </div>
                    <div className="breakdown-column">
                        <ul>
                            <li>
                                {w.content}
                                <i className="cross" />
                            </li>
                        </ul>
                    </div>
                </div>
            ))}
        </main>
    );
};

const fixHtml = (html: string) => new DOMParser().parseFromString(html, 'text/html').body.innerHTML;

const PageDimensionsRecommendations: FC<IPageProps> = ({ resultsData }) => {
    const { t } = useTranslation(['pages']);
    const maxDimensionsToFitOnPage = 4;

    const recPagesToGenerate = Math.ceil(resultsData.dimensions.length / maxDimensionsToFitOnPage);
    const pagesToDraw = recPagesToGenerate;

    const shouldDrawFooter = (pageID: number) => pageID + 1 >= recPagesToGenerate;
    let prevPageNum = '';

    return (
        <>
            {[...Array(pagesToDraw)].map((_, i) => {
                const pageNumber = shouldDrawFooter(i) ? '6' : '5';
                return (
                    <React.Fragment key={i}>
                        {prevPageNum !== '6' && (
                            <main className={`results-pdf page-${pageNumber}`}>
                                {(i === 0 && (
                                    <div className="breakdown-columns">
                                        <div className="breakdown-column">
                                            <h2>
                                                <Trans
                                                    i18nKey="resultsForPDF.recommendationsForYou"
                                                    ns="pages"
                                                    components={{ break: <br /> }}
                                                />
                                            </h2>
                                        </div>
                                    </div>
                                )) || <div style={{ minHeight: '5em' }} />}
                                {resultsData.recommendations
                                    .slice(
                                        i * maxDimensionsToFitOnPage,
                                        i * maxDimensionsToFitOnPage + maxDimensionsToFitOnPage,
                                    )
                                    .map((r, j) => {
                                        return (
                                            <React.Fragment key={j}>
                                                <div className="breakdown-columns">
                                                    <div className="breakdown-column">
                                                        <div>
                                                            <h4
                                                                className={`dimension-${
                                                                    i * maxDimensionsToFitOnPage + j
                                                                }`}
                                                            >
                                                                {r.title}
                                                            </h4>
                                                        </div>
                                                        <p>{r.summary}</p>
                                                    </div>
                                                    <div className="breakdown-column">
                                                        <div>
                                                            <h4 className="action-heading">
                                                                {t('resultsForPDF.action')}
                                                            </h4>
                                                        </div>
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: r.content,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {i < 2 && <div style={{ minHeight: '1em' }} />}
                                            </React.Fragment>
                                        );
                                    })}
                            </main>
                        )}
                        {(() => {
                            prevPageNum = pageNumber;
                        })()}
                    </React.Fragment>
                );
            })}
        </>
    );
};

const PageCompanySupport: FC<IPageProps> = ({ resultsData }) => {
    const { t } = useTranslation(['pages']);
    let drawnParagraphs = 0;
    const msg = resultsData.couldImproveMessage;
    const text = msg?.message ?? '';
    const paragraph = useMemo(
        () => text.match(/<(p|h1|h2)>([^]*?)<\/(p|h1|h2)>/g)?.map(x => x) ?? [],
        [text],
    );

    const getCompanyMessageExtraPages = () =>
        paragraph.length < 2
            ? 0
            : Math.ceil((paragraph.length - firstPageProperties.properties) / 6) + 1; // 10

    const ref = useRef(null);

    const pageHeight = 1122.52;
    const workingPageHeight = 900;
    const [firstPageProperties, setFirstPageProperties] = useState({ properties: 1, found: false });

    useEffect(() => {
        if (ref && ref?.current && paragraph.length > 0 && !firstPageProperties.found) {
            //@ts-ignore
            const obj: DOMRectReadOnly = ref.current.getBoundingClientRect();
            const endPosition = (obj.y % pageHeight) + obj.height;
            if (
                endPosition >= workingPageHeight ||
                firstPageProperties.properties > paragraph.length
            ) {
                setFirstPageProperties({
                    properties: firstPageProperties.properties - 1,
                    found: true,
                });
                return;
            }
            setFirstPageProperties({
                properties: firstPageProperties.properties + 1,
                found: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, firstPageProperties, paragraph]);

    const pagesToDraw =
        firstPageProperties.found === true && firstPageProperties.properties >= paragraph.length
            ? 1
            : getCompanyMessageExtraPages();

    const shouldDrawFooter = (pageID: number) => pageID + 1 >= pagesToDraw;
    let prevPageNum = '';

    return (
        <>
            {[...Array(pagesToDraw)].map((_, i) => {
                const pageNumber = shouldDrawFooter(i) ? '6' : (i + 1).toString();
                return (
                    <React.Fragment key={i}>
                        {prevPageNum !== '6' && (
                            <main className={`results-pdf support-page support-page-${pageNumber}`}>
                                {!!resultsData.couldImproveMessage && (
                                    <div className="breakdown-columns">
                                        <div className="breakdown-column">
                                            {drawnParagraphs === 0 && (
                                                <>
                                                    <div>
                                                        <h4
                                                            className={`dimension-${
                                                                dimensionColours[
                                                                    resultsData.recommendations
                                                                        .length + 1
                                                                ]
                                                            }`}
                                                        >
                                                            {t('resultsForPDF.support')}
                                                        </h4>
                                                    </div>
                                                    <div>
                                                        {!!resultsData.couldImproveMessage
                                                            .supportServiceLogoS3Key && (
                                                            <img
                                                                className="support-service-logo"
                                                                src={`${S3_URL}/${resultsData.couldImproveMessage.supportServiceLogoS3Key}`}
                                                                alt="Your company"
                                                            />
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="breakdown-column">
                                            {drawnParagraphs === 0 && pagesToDraw > 1 && (
                                                <div>
                                                    <h4 className="action-heading">
                                                        {t('resultsForPDF.companySupport')}
                                                    </h4>
                                                </div>
                                            )}
                                            {drawnParagraphs === 0 && pagesToDraw < 2 && (
                                                <div style={{ marginTop: '6em' }}>
                                                    <h4 className="action-heading">
                                                        {t('resultsForPDF.companySupport')}
                                                    </h4>
                                                </div>
                                            )}
                                            <div
                                                id="couldImproveMessage"
                                                className="wysiwyg"
                                                style={{ width: '373px' }}
                                            >
                                                {(drawnParagraphs < 1 && pagesToDraw >= 1 && (
                                                    <p
                                                        ref={ref}
                                                        dangerouslySetInnerHTML={{
                                                            __html: fixHtml(
                                                                paragraph
                                                                    .slice(
                                                                        drawnParagraphs,
                                                                        firstPageProperties.properties,
                                                                    )
                                                                    .map(x => {
                                                                        drawnParagraphs++;
                                                                        return x;
                                                                    })
                                                                    .join(''),
                                                            ),
                                                        }}
                                                    />
                                                )) || (
                                                    <div style={{ marginTop: '8em' }}>
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: fixHtml(
                                                                    paragraph
                                                                        .slice(
                                                                            drawnParagraphs,
                                                                            drawnParagraphs + 6,
                                                                        )
                                                                        .map(x => {
                                                                            drawnParagraphs++;
                                                                            return x;
                                                                        })
                                                                        .join(''),
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {pageNumber === '6' && <DrawFooter />}
                            </main>
                        )}
                        {(() => {
                            prevPageNum = pageNumber;
                        })()}
                    </React.Fragment>
                );
            })}
        </>
    );
};

const DrawFooter = () => {
    const { t } = useTranslation(['common']);
    return (
        <div className="contact-section">
            <div className="equal-columns">
                <div className="equal-column">
                    <h1 className="h1-report contact-title">{t('footer.contact')}</h1>
                    <h4 className="contact-subtitle white">{t('footer.subLeftTitle')}</h4>
                    <a
                        className="contact-item"
                        href="mailto:support@87percent.me"
                        target="_blank"
                        rel="noreferrer"
                    >
                        support@87percent.me
                        <i className="icon email" />
                    </a>
                </div>
                <div />
                <div className="equal-column">
                    <h4 className="contact-subtitle white">{t('footer.findUs')}</h4>
                    <a
                        className="contact-item"
                        href="https://87percent.co.uk"
                        target="_blank"
                        rel="noreferrer"
                    >
                        87percent.co.uk
                        <i className="icon web" />
                    </a>
                    <a
                        className="contact-item"
                        href="https://www.youtube.com/channel/UCy3_epM9brXJny3Hngc8PHA"
                        target="_blank"
                        rel="noreferrer"
                    >
                        87%
                        <i className="icon youtube" />
                    </a>
                    <a
                        className="contact-item"
                        href="https://www.instagram.com/87percentltd"
                        target="_blank"
                        rel="noreferrer"
                    >
                        87percentltd
                        <i className="icon instagram" />
                    </a>
                </div>
            </div>
        </div>
    );
};
