import {
    fetchResultsError,
    fetchResultsRequest,
    fetchResultsSuccess,
} from '@actions/results/getResults';

import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import QuestionnaireResult from 'src/types/shared/QuestionnaireResult';

interface ResultsState {
    isFetching: boolean;
    fetchSuccess: boolean;
    error: string | null;
    isPosting: boolean;
    postSuccess: boolean;
    data: QuestionnaireResult | null;
}

const initialState: ResultsState = {
    isFetching: false,
    fetchSuccess: false,
    error: null,
    isPosting: false,
    postSuccess: false,
    data: null,
};

export default createReducer(initialState, {
    [fetchResultsRequest.type]: handleFetchRequest,
    [fetchResultsSuccess.type]: handleFetchSuccess,
    [fetchResultsError.type]: handleFetchError,
});

function handleFetchRequest(state: ResultsState) {
    state.isFetching = true;
    state.fetchSuccess = false;
    state.error = null;
}

function handleFetchSuccess(state: ResultsState, action: PayloadAction<QuestionnaireResult>) {
    state.isFetching = false;
    state.fetchSuccess = true;
    state.data = action.payload;
}

function handleFetchError(state: ResultsState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
}
