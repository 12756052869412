import { useTranslation } from 'react-i18next';
import QuestionData from '../../../../types/shared/QuestionData';
import ActionButton from 'lib/src/components/button/ActionButton';
import Form from 'lib/src/components/form/Form';
import ActionBar from '@components/actionBar/ActionBar';
// import AccessCodeModal from '@components/modals/AccessCodeModal';
import useSubmitQuestionnaire from '../hooks/useSubmitQuestionnaire';
import { useSelector } from 'react-redux';
import { getQuestionsPostSuccess } from '@selectors/questions';
import usePageLeaveWarn from '../../../../../src/hooks/usePageLeaveWarn';
import CustomSectionComponent from './CustomSection';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useEffect, useState } from 'react';

interface Props {
    questionData: QuestionData;
    currentSection: number;
    setCurrentSection: (section: number) => void;
    isLastSection: boolean;
    isFirstSection: boolean;
    onComplete: () => void;
    children?: React.ReactChild;
}

export default function Onboarding({
    questionData,
    onComplete,
    currentSection,
    setCurrentSection,
    isLastSection,
    isFirstSection,
    children,
}: Props) {
    const { t } = useTranslation(['pages', 'common']);
    const [navValue, setNavValue] = useState<1 | -1 | null>(null);
    // const [modalState, setModalState] = useState<boolean>(true);
    const { selectedCustomAnswers, handleChange, selectCustomAnswer, handleSubmit, isPosting } =
        useSubmitQuestionnaire(questionData);
    const postSuccess = useSelector(getQuestionsPostSuccess);
    const prevSuccess = usePrevious(postSuccess);
    usePageLeaveWarn();

    const { onboardingSections } = questionData;

    useEffect(() => {
        if (postSuccess && !prevSuccess) {
            onComplete();
        }
    }, [onComplete, postSuccess, prevSuccess]);

    if (!onboardingSections || !onboardingSections.length) return null;

    const thisSection = onboardingSections[currentSection];
    const { questions, name, description, imageS3Key } = thisSection;

    const _handleSubmit = (action: string = '') => {
        if (isLastSection && navValue === 1) {
            handleSubmit();
        } else {
            setCurrentSection(currentSection + (navValue ? navValue : 1));
        }
    };

    const _goBack = () => {
        if (navValue === 1) {
            setCurrentSection(currentSection - navValue);
        }
    };

    return (
        <div id="onboarding-page">
            <div className="container top-bar">
                <h3 className="about-title">{t('onboarding.title')}</h3>
                <h2 className="section-title">{name}</h2>
                <p className="description">{t('onboarding.subTitle')}</p>
            </div>
            <Form onSubmit={_handleSubmit} omitButtons>
                <CustomSectionComponent
                    description={description}
                    img={imageS3Key}
                    questions={questions.slice().sort((a, b) => a.sort - b.sort)}
                    answers={selectedCustomAnswers}
                    handleChange={handleChange}
                    selectAnswer={selectCustomAnswer}
                    length={onboardingSections.length}
                    sectionNumber={currentSection + 1}
                    setNavValue={setNavValue}
                />
                <ActionBar>
                    {!isFirstSection && (
                        <ActionButton
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                _goBack();
                            }}
                        >
                            {t('button.goBack', { ns: 'common' })}
                        </ActionButton>
                    )}
                    <ActionButton disabled={isPosting} onClick={() => setNavValue(1)}>
                        {t('button.next', { ns: 'common' })}
                    </ActionButton>
                    {/* No resume code at this point TODO <ActionButton
                        color="white"
                        onClick={() => {
                            console.log('Save Button!!');
                            setModalState(true);
                        }}
                    >
                        SAVE
                    </ActionButton> */}
                </ActionBar>
                {/* <AccessCodeModal state={modalState} onClose={() => setModalState(false)} /> */}
                {children ? children : null}
            </Form>
        </div>
    );
}
