interface Props {
    name: string;
    img: string;
    color: 'mind-dark-blue' | 'samaritans-green' | 'nhs-blue';
    href: string;
}

export default function Badge({ name, img, color, href }: Props) {
    return (
        <div className="badge">
            <a href={href} target="_blank" rel="noreferrer">
                <div className={`flex-column justify-center square ${color}`}>
                    <img src={img} alt={`${name} logo`} />
                </div>
            </a>
            <a className="label-name" href={href} target="_blank" rel="noreferrer">
                <p className={`name ${color}`}>{name}</p>
            </a>
        </div>
    );
}
