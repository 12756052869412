import { ResultDimension } from '../../../../types/shared/QuestionData';

interface Props {
    dimensionIndex: number;
    selected: ResultDimension;
}

export default function ResultsBreakdownTable({ dimensionIndex, selected }: Props) {
    const { dimensionScore, subcategories, dimensionName } = selected;

    return (
        <table>
            <tbody>
                <tr className="flex-row justify-between">
                    <th className={`dimension-${dimensionIndex}`}>{dimensionName ?? ''}</th>
                    <th>{dimensionScore ? Math.round(dimensionScore) : ''}</th>
                </tr>
                {subcategories.map(subcategory => (
                    <tr key={subcategory.subcategoryID} className="flex-row justify-between">
                        <td>{subcategory.subcategoryName}</td>
                        <td>
                            {subcategory.subcategoryScore &&
                                Math.round(subcategory.subcategoryScore)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
